import { clsx } from 'clsx'
import { ComponentProps } from 'react'
import { RadioGroup } from '@headlessui/react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  canContinue,
  isBipolarDisabled,
  Question as QuestionType,
} from 'utils/bipolar'
import { useConcept } from 'hooks/concepts'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import Question from './Question'

interface BipolarForm {
  response: Record<string, number>
}

const BipolarQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onViewedConcept,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(opts: Pick<QuestionType, 'response'>): void
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
}) => {
  const { handleSubmit, setValue, watch } = useForm<BipolarForm>({
    defaultValues: {
      response: question.response,
    },
  })

  const curResponse = watch('response')

  /**
   * Our displayed values for bipolar options aren't really form fields - we currently use buttons. I want to
   * display them as radio groups eventually, but that will be part of a larger usability refresh.
   * For now, we manually update the response value when an option is clicked.
   */
  function onSelectValueForScale(optionID: string, value: number) {
    setValue('response', { ...curResponse, [optionID]: value })
  }

  const onSubmit: SubmitHandler<BipolarForm> = (data) => {
    onCompletedQuestion({ response: data.response })
  }

  const disabled = isBipolarDisabled({ question })
  const minWidth = 'min-w-[800px]'

  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        <div className="space-y-4 overflow-auto">
          {/* px-40 is for the width of the left and right labels below (w-36) plus the space between them (space-x-4) */}
          <div className={clsx('flex space-x-2 px-40', minWidth)}>
            {question.labels.map((label) => {
              return (
                <p key={label} className="flex-1 text-center text-sm">
                  {label}
                </p>
              )
            })}
          </div>

          <div className={clsx('divide-y', minWidth)}>
            {question.options.map(({ id, labels, values }, i) => {
              const [leftLabel, rightLabel] = labels

              return (
                <div
                  key={id}
                  className={clsx('flex w-full items-center space-x-4 py-8', {
                    'pt-0': i === 0,
                  })}
                >
                  <p className="w-36 text-right text-sm">{leftLabel}</p>

                  <RadioGroup
                    className="flex grow space-x-1"
                    disabled={disabled}
                    onChange={(value) => {
                      onSelectValueForScale(id, value)
                    }}
                    value={curResponse[id] ?? ''}
                  >
                    {values.map((value) => {
                      return (
                        <RadioGroup.Option
                          key={value}
                          className="h-full w-full min-w-16 flex-1 cursor-default rounded border border-gray-4 bg-white py-4 text-center text-sm ui-checked:border-green-2 ui-checked:bg-green-2 ui-checked:font-medium ui-checked:text-white ui-disabled:text-gray-300 md:min-w-5 md:text-xs"
                          value={value}
                        >
                          {`${value}`}
                        </RadioGroup.Option>
                      )
                    })}
                  </RadioGroup>

                  <p className="w-36 text-sm">{rightLabel}</p>
                </div>
              )
            })}
          </div>
        </div>
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ curResponse, question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default BipolarQuestion
