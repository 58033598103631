import { clsx } from 'clsx'

import { getCurrentSum } from 'utils/sums'
import { useTranslations } from 'contexts/i18n'

/**
 * A floating display indicating to the user if they've met the required sum constraint
 * for a question. For example, multiple choice free text responses and scales can be required
 * to sum to a certain amount.
 */
const RequiredSumFloatingDisplay = ({
  curResponse,
  sum,
}: {
  curResponse: Record<string, number | string>
  sum: number | null
}) => {
  const translations = useTranslations()

  if (sum === null) {
    return null
  }

  const curSum = getCurrentSum({ curResponse })
  const hasGreaterThanSum = curSum > sum
  const hasEqualToSum = curSum === sum
  const hasLessThanSum = curSum < sum

  return (
    <p
      className={clsx(
        'sticky top-0 z-20 flex items-center space-x-3 rounded-lg border px-3.5 py-2 text-sm font-medium text-gray-700 transition-colors',
        {
          'border-error-200 bg-error-50': hasGreaterThanSum,
          'border-success-200 bg-success-50': hasEqualToSum,
          'border-gray-200 bg-gray-50': hasLessThanSum,
        },
      )}
    >
      <span>{translations.UI_ELEMENTS.REQUIRED_SUM_TOTAL}</span>
      <span
        className={clsx('rounded-lg border px-2.5 py-1', {
          'border-error-200 bg-white text-error-700': hasGreaterThanSum,
          'border-success-200 bg-success-50 text-success-700': hasEqualToSum,
          'border-gray-200 bg-gray-50': hasLessThanSum,
        })}
      >
        {curSum} / {sum}
      </span>
    </p>
  )
}

export default RequiredSumFloatingDisplay
