import { forwardRef, InputHTMLAttributes } from 'react'

const InputRank = forwardRef<
  HTMLInputElement,
  Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'checked' | 'className' | 'type'
  > & {
    rank: number | undefined
  }
>(function InputRank({ rank, ...rest }, ref) {
  return (
    <input
      {...rest}
      ref={ref}
      checked={!!rank}
      className="m-0 grid h-6 w-6 appearance-none place-content-center rounded border border-gray-4 bg-white before:text-sm before:font-medium before:text-white checked:border-primary-600 checked:bg-primary-600 checked:before:content-[attr(data-content)] disabled:cursor-not-allowed disabled:bg-gray-100"
      data-content={rank}
      type="checkbox"
    />
  )
})

export default InputRank
