import { clsx } from 'clsx'
import { Decimal } from 'decimal.js-light'
import { SubmitHandler, useForm } from 'react-hook-form'
import { ComponentProps, useEffect, useRef, useState } from 'react'

import {
  canContinue,
  getFormattedValue,
  getNextValuePrompt,
  getValueTowardsTargetByIncrement,
  isOptionDisabled,
  Question as QuestionType,
} from 'utils/gaborGranger'
import { useConcept } from 'hooks/concepts'
import { useTranslations } from 'contexts/i18n'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import InputLabeled from './InputLabeled'
import InputRadio from './InputRadio'
import Question from './Question'

interface GaborGrangerForm {
  response: { value: Decimal; willingToAccept: 'no' | 'yes' }[]
}

const GaborGrangerQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onViewedConcept,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion: SubmitHandler<GaborGrangerForm>
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
}) => {
  const { handleSubmit, setValue, watch } = useForm<GaborGrangerForm>({
    defaultValues: {
      response: question.response,
    },
  })

  const curResponse = watch('response')
  const [curValuePrompt, setCurValuePrompt] = useState(
    getNextValuePrompt({ curResponse, question }),
  )
  const [delayedChangeValue, setDelayedChangeValue] = useState(false)

  /**
   * Our displayed options aren't really form fields - we currently use buttons. I want to
   * display them as radio groups / checkboxes eventually, but that will be part of a larger
   * usability refresh. For now, we manually update the response value when an option is clicked.
   */
  function onClickOption({
    selection,
    value,
  }: {
    selection: 'no' | 'yes'
    value: Decimal
  }) {
    const newResponse = [...curResponse, { value, willingToAccept: selection }]

    setValue('response', newResponse)
    setDelayedChangeValue(true)
  }

  // We delay setting the value prompt to the next one to give the page a chance to re-render
  // so the respondent gets some kind of feedback that they selected an option.
  useEffect(() => {
    let setCurValuePromptTimeout: number | undefined

    if (delayedChangeValue) {
      setCurValuePromptTimeout = window.setTimeout(() => {
        setCurValuePrompt(getNextValuePrompt({ curResponse, question }))
        setDelayedChangeValue(false)
      }, 200)
    }

    return () => {
      window.clearTimeout(setCurValuePromptTimeout)
    }
  }, [delayedChangeValue, curResponse, question])

  const lastSelection = curResponse[curResponse.length - 1]
  let finalSelection: 'no' | 'yes' | undefined
  let value: Decimal
  if (curValuePrompt === undefined) {
    finalSelection = lastSelection.willingToAccept
    value = lastSelection.value
  } else {
    value = curValuePrompt
  }

  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onCompletedQuestion)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        <div className="flex flex-col items-center space-y-2">
          <ValuePrompt
            disabled={isOptionDisabled({
              hasFinalSelection: !!finalSelection,
              question,
            })}
            display={question.display}
            increment={question.range.increment}
            lastSelection={lastSelection}
            onClick={(selection) => {
              onClickOption({ value, selection })
            }}
            step={
              finalSelection
                ? 'final'
                : curResponse.length === 0
                  ? 'initial'
                  : 'subsequent'
            }
            value={value}
          />
        </div>
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={
            !canContinue({ hasFinalSelection: !!finalSelection, question })
          }
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default GaborGrangerQuestion

const ValuePrompt = ({
  disabled,
  display,
  increment,
  lastSelection,
  onClick,
  step = 'initial',
  value,
}: {
  disabled: boolean
  display: QuestionType['display']
  increment: Decimal
  lastSelection?: GaborGrangerForm['response'][number]
  onClick(selection: 'no' | 'yes'): void
  step?: 'initial' | 'subsequent' | 'final'
  value: Decimal
}) => {
  const translations = useTranslations()

  const promptOptions = [
    { label: translations.UI_ELEMENTS.GABOR_GRANGER_BUTTON_YES, value: 'yes' },
    { label: translations.UI_ELEMENTS.GABOR_GRANGER_BUTTON_NO, value: 'no' },
  ] as const

  const currentValue = useDelayedIncrementChange({
    increment,
    targetValue: value,
  })

  const formattedValue = (
    <p
      className={clsx('text-center text-3xl font-semibold text-green-2', {
        'animate-[bulge_0.3s_0s_infinite_linear]': currentValue !== value,
      })}
    >
      {getFormattedValue({
        customText: display.formatCustomText,
        format: display.format,
        unitDecimals: display.unitDecimals,
        value: currentValue,
      })}
    </p>
  )

  return (
    <div className="relative w-full space-y-6">
      {step === 'initial' ? (
        formattedValue
      ) : step === 'subsequent' ? (
        <>
          <TextPrompt>
            {translations.UI_ELEMENTS.GABOR_GRANGER_HOW_ABOUT}
          </TextPrompt>
          {formattedValue}
        </>
      ) : step === 'final' ? (
        <>
          <TextPrompt>
            {translations.UI_ELEMENTS.GABOR_GRANGER_THANKS}
          </TextPrompt>
          {formattedValue}
        </>
      ) : null}

      <div className="space-y-3">
        {promptOptions.map((option) => {
          const checked =
            lastSelection?.value === value &&
            lastSelection.willingToAccept === option.value
          const id = `gabor-granger-${option.value}`

          return (
            <InputLabeled
              key={option.value}
              disabled={disabled}
              hasValue={checked}
              id={id}
              input={
                <InputRadio
                  checked={checked}
                  disabled={disabled}
                  id={id}
                  name="willingToAccept"
                  onChange={(event) => {
                    if (event.target.checked) {
                      onClick(option.value)
                    }
                  }}
                  value={option.value}
                />
              }
            >
              {option.label}
            </InputLabeled>
          )
        })}
      </div>
    </div>
  )
}

const TextPrompt = ({ children }: { children: string }) => {
  return (
    // We add some left padding here so it's not aligned with the question title and directions.
    // This hopefully connects the prompt a little more to the value displayed.
    <p className="pl-8 text-sm font-semibold uppercase text-green-2">
      {children}
    </p>
  )
}

/**
 * Returns a value that is updated every 50ms to get closer to the target value.
 */
function useDelayedIncrementChange({
  increment,
  targetValue,
}: {
  increment: Decimal
  targetValue: Decimal
}) {
  const lastValue = useRef(targetValue)
  const [currentValue, setCurrentValue] = useState(targetValue)

  // Updates the currentValue every 50ms to get closer to the target value.
  // Stops once the currentValue is equal to the target value.
  useEffect(() => {
    let timeout: number | undefined

    if (currentValue !== targetValue) {
      lastValue.current = currentValue

      timeout = window.setTimeout(() => {
        setCurrentValue(
          getValueTowardsTargetByIncrement({
            currentValue,
            increment,
            targetValue,
          }),
        )
      }, 50)
    }

    return () => {
      window.clearTimeout(timeout)
    }
  }, [currentValue, targetValue, increment])

  return currentValue
}
