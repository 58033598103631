import { AdvancedImage } from '@cloudinary/react'
import { clsx } from 'clsx'
import { ReactNode } from 'react'

import { getImageFromPublicID } from 'utils/media'

import ImageZoomControls from './ImageZoomControls'

/**
 * Displays an image option. The image can be zoomed via a button that opens a full-screen dialog.
 *
 * @param zoomRequired If true, the image must be zoomed before other interactions can occur.
 */
const ImageOption = ({
  alt,
  disabled = false,
  freeTextField,
  input,
  inputId,
  isSelected,
  onClickZoom,
  publicID,
  title,
  zoomRequired = false,
}: {
  alt: string
  disabled?: boolean
  freeTextField?: ReactNode
  input: ReactNode
  inputId: string
  isSelected: boolean
  onClickZoom?(): void
  publicID: string
  title: string | null
  zoomRequired?: boolean
}) => {
  const image = getImageFromPublicID({ publicID })

  return (
    // This outside containing div with padding offsets the 2px border of a selected
    // image. Without this, a row of images would shift slightly as you select / unselect them.
    <div className={clsx({ 'p-px': !isSelected })}>
      <div
        className={clsx('relative h-full w-full overflow-hidden rounded-lg', {
          'border-2 border-green-2': isSelected,
          'border border-gray-300': !isSelected,
        })}
      >
        <label className="flex h-full flex-col" htmlFor={inputId}>
          <div className="relative">
            <div className="flex h-28 w-full items-center justify-center bg-white">
              <AdvancedImage alt={alt} className="max-h-full" cldImg={image} />
            </div>

            <ImageZoomControls
              alt={alt}
              image={image}
              onClickZoom={onClickZoom}
              zoomRequired={zoomRequired}
            />
          </div>

          <div
            className={clsx('grow space-y-2 border-t-2 p-2 text-sm', {
              'text-gray-3': disabled,
              'border-green-2 bg-green-5': isSelected,
              'border-transparent bg-white': !isSelected,
            })}
          >
            <div className="flex items-start space-x-2">
              <div className="shrink-0">{input}</div>

              {title && <span>{title}</span>}
            </div>

            {freeTextField}
          </div>
        </label>
      </div>
    </div>
  )
}

export default ImageOption
