import { useSearchParams } from 'react-router-dom'

import { Question } from 'utils/questions'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const QuestionInfo = ({ question }: { question: Question }) => {
  const [searchParams] = useSearchParams()

  return (
    <FeatureDisplay title="Information">
      <ListDiscIndented>
        <li>ID: {question.id}</li>
        <li>
          <a
            className="cursor-pointer text-primary-600 underline"
            onClick={async () => {
              const newParams = new URLSearchParams(searchParams)
              newParams.set('startingQuestionID', question.id)

              await window.navigator.clipboard.writeText(
                `${window.location.origin}${window.location.pathname}?${newParams}`,
              )
            }}
          >
            Copy Link
          </a>
        </li>
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default QuestionInfo
