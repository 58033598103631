import { OptionQuotasConstraint } from 'utils/quotas'
import { Question } from 'utils/questions'
import { titlesFromSet } from 'utils/general'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented, { NestedListItem } from './ListDiscIndented'

const QuotasOptions = ({ question }: { question: Question }) => {
  if (
    (question.type !== 'multipleChoice' && question.type !== 'ranking') ||
    !question.constraints.optionQuotas?.length
  ) {
    return null
  }

  return (
    <FeatureDisplay title="Quotas">
      {question.constraints.optionQuotas.map((quota, i) => {
        if (!quota.cantPickOptionIDs?.size && !quota.mustPickOptionIDs?.size) {
          return null
        }

        return (
          <ListDiscIndented key={i}>
            {quota.cantPickOptionIDs?.size && (
              <QuotaOptionList
                disqualificationTactic={quota.disqualificationTactic}
                ids={quota.cantPickOptionIDs}
                options={question.options}
                title="Can't Pick"
              />
            )}
            {quota.mustPickOptionIDs?.size && (
              <QuotaOptionList
                disqualificationTactic={quota.disqualificationTactic}
                ids={quota.mustPickOptionIDs}
                options={question.options}
                title="Must Pick"
              />
            )}
          </ListDiscIndented>
        )
      })}
    </FeatureDisplay>
  )
}

export default QuotasOptions

const QuotaOptionList = ({
  disqualificationTactic,
  ids,
  options,
  title,
}: {
  disqualificationTactic: OptionQuotasConstraint[number]['disqualificationTactic']
  ids: Set<string>
  options: { id: string; title: string }[]
  title: string
}) => {
  let adjustedTitle = title
  if (disqualificationTactic === 'CUSTOM_QUALITY_SCREEN') {
    adjustedTitle = `${title} (Quality)`
  }

  return (
    <li>
      <NestedListItem title={adjustedTitle}>
        {titlesFromSet({ ids, resources: options }).map((title) => (
          <li key={title}>{title}</li>
        ))}
      </NestedListItem>
    </li>
  )
}
