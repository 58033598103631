import { BlockSetMonadic } from 'utils/questionBlocks'
import { ConditionGroups } from 'utils/conditions'
import { Question } from 'utils/questions'

import ConditionsTable from './ConditionsTable'
import LogicGroupWithLine, { LogicSeparatedGroup } from './LogicGroupWithLine'

const ConditionGroupsTable = ({
  conditionGroups,
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  conditionGroups: ConditionGroups
  monadicBlocks: Record<string, BlockSetMonadic>
  question?: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) => {
  return (
    <div className="space-y-2">
      {conditionGroups.groups.map((conditions, groupIdx) => {
        return (
          <LogicGroupWithLine
            key={groupIdx}
            isLast={groupIdx === conditionGroups.groups.length - 1}
            logic={conditionGroups.groupLogic}
          >
            {conditions.map((condition, conditionIdx) => {
              return (
                <LogicSeparatedGroup
                  key={conditionIdx}
                  isLast={conditionIdx === conditions.length - 1}
                  logic={conditionGroups.groupLogic === 'and' ? 'or' : 'and'}
                >
                  <ConditionsTable
                    condition={condition}
                    monadicBlocks={monadicBlocks}
                    question={question}
                    questionsCurrent={questionsCurrent}
                    questionsInitial={questionsInitial}
                  />
                </LogicSeparatedGroup>
              )
            })}
          </LogicGroupWithLine>
        )
      })}
    </div>
  )
}

export default ConditionGroupsTable
