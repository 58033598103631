import { SearchRespondentFailureReason } from 'types/research-defender/domainModels'

export type DisqualificationReasonGlass = {
  source: 'GLASS'
  tactic:
    | 'AUDIENCE_QUOTA'
    | 'COPY_PASTE'
    | 'CUSTOM_QUALITY_SCREEN'
    | 'PRESURVEY_QUESTIONNAIRE'
    | 'QUESTION_QUOTA'
    | 'VARIABLE_QUOTA'
    | 'WAVE_QUOTA'
}

export type DisqualificationReasonResearchDefender = {
  metadata: {
    country_code: string
    country_mismatch: 0 | 1
    duplicate_score: 0 | 100
    failure_reason: SearchRespondentFailureReason | ''
    respondent_ud: string
    threat_potential_score: number
  }
  source: 'RESEARCH_DEFENDER'
  tactic: 'BAD_RESPONDENT'
}

export type DisqualificationReason =
  | DisqualificationReasonGlass
  | DisqualificationReasonResearchDefender

export const DISQUALIFICATION_TACTIC_READABLE = {
  AUDIENCE_QUOTA: 'Audience Quota',
  BAD_RESPONDENT: 'Bad Respondent (as determined by Research Defender)',
  COPY_PASTE: 'Copy-Paste',
  CUSTOM_QUALITY_SCREEN: 'Custom Quality Screen',
  PRESURVEY_QUESTIONNAIRE:
    'Presurvey Questionnaire (e.g. "Clock" screener question)',
  QUESTION_QUOTA: 'Question Quota',
  VARIABLE_QUOTA: 'Survey Variable Quota',
  WAVE_QUOTA: 'Already have needed number of respondents for wave.',
} as const satisfies Record<DisqualificationReason['tactic'], string>

export function getReadableDisqualificationReason(
  disqualificationReason: DisqualificationReason | undefined,
) {
  if (!disqualificationReason) {
    return
  }

  const readableReason =
    DISQUALIFICATION_TACTIC_READABLE[disqualificationReason.tactic]
  if ('freeText' in disqualificationReason && disqualificationReason.freeText) {
    return `${readableReason}. Entered text: ${disqualificationReason.freeText}`
  }

  return readableReason
}
