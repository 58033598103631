import { Question as QuestionMultipleChoice } from './multipleChoice'
import { Question as QuestionScale } from './scale'

export function getCurrentSum({
  curResponse,
}: {
  curResponse: Record<string, number | string>
}) {
  return Object.values(curResponse)
    .map((value) => Number(value))
    .filter((value) => !Number.isNaN(value))
    .reduce((sum, value) => {
      return sum + value
    }, 0)
}

/**
 * Returns an error message if the user has not responded in a way that satisfies
 * a sum requirement.
 */
export function getRequireSumError({
  curResponse,
  question,
}: {
  curResponse: Record<string, number | string>
  question: QuestionMultipleChoice | QuestionScale
}) {
  if (question.constraints.sum === null) {
    return false
  }

  const curSum = getCurrentSum({ curResponse })

  return curSum !== question.constraints.sum
}
