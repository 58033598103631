import { clsx } from 'clsx'
import { ReactNode } from 'react'

const InputLabeled = ({
  children,
  disabled,
  hasValue,
  id,
  input,
}: {
  children: string
  disabled: boolean
  hasValue: boolean
  id: string
  input: ReactNode
}) => {
  return (
    <div className="relative w-full">
      <div className="absolute left-4 top-6 -translate-y-1/2">{input}</div>

      <label
        className={clsx('flex w-full rounded-lg px-4 py-3 pl-12', {
          // We add a negative margin class because the selected border is one pixel
          // more than the unselected border. Without this, there is a shift in the
          // layout when the selection changes.
          '-m-px border-2 border-green-2 bg-primary-50': !disabled && hasValue,
          'border border-gray-4 bg-white': !hasValue,
          'border border-gray-4 bg-primary-50 text-gray-3': disabled,
        })}
        htmlFor={id}
      >
        {children}
      </label>
    </div>
  )
}

export default InputLabeled
