import { AdvancedImage } from '@cloudinary/react'
import { clsx } from 'clsx'
import {
  ComponentProps,
  KeyboardEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { ErrorMessage } from '@hookform/error-message'
import {
  FieldErrors,
  SubmitHandler,
  useForm,
  UseFormRegister,
} from 'react-hook-form'
import { throttle } from 'lodash-es'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

import { adjustOptionsResponse } from 'utils/responses'
import {
  canContinue,
  isOptionDisabled,
  Option,
  Statement,
  Question as QuestionType,
} from 'utils/matrix'
import { getStepForInputType } from 'utils/forms'
import { Image as ImageType, getImageFromPublicID } from 'utils/media'
import {
  MatrixFreeTextStatementError,
  useTranslations,
  useZodErrorMap,
} from 'contexts/i18n'
import { titlesFromSet } from 'utils/general'
import { useConcept } from 'hooks/concepts'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import AnimateChangeInHeight from './AnimateChangeInHeight'
import FieldError from './FieldError'
import Icon from './Icon'
import ImageZoomControls from './ImageZoomControls'
import Input from './Input'
import InputCheckbox from './InputCheckbox'
import InputRadio from './InputRadio'
import Question from './Question'

const schema = z
  .object({
    // A mapping of statement ID to a set of option IDs.
    response: z.record(z.set(z.string())),
    responseFreeText: z.array(
      z.object({
        options: z.array(
          z.object({
            freeText: z.string(),
            freeTextRequired: z.boolean(),
            id: z.string(),
            max: z
              .object({ message: z.string(), value: z.number() })
              .optional(),
            min: z
              .object({ message: z.string(), value: z.number() })
              .optional(),
            title: z.string(),
          }),
        ),
        statementID: z.string(),
      }),
    ),
  })
  .superRefine((data, ctx) => {
    // Validates that all free-text fields that have been selected have valid input.
    data.responseFreeText.forEach(({ options, statementID }, statementIdx) => {
      options.forEach(
        ({ freeText, freeTextRequired, id, max, min, title }, optionIdx) => {
          const freeTextFieldName =
            `responseFreeText.${statementIdx}.options.${optionIdx}.freeText` as const

          if (data.response[statementID]?.has(id) && freeTextRequired) {
            if (freeText.length === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                params: {
                  option: title,
                  type: 'matrixFreeTextStatementError',
                } satisfies MatrixFreeTextStatementError,
                path: [freeTextFieldName],
              })
            } else {
              if (max && Number(freeText) > max.value) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: max.message,
                  path: [freeTextFieldName],
                })
              }

              if (min && Number(freeText) < min.value) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: min.message,
                  path: [freeTextFieldName],
                })
              }
            }
          }
        },
      )
    })
  })

type MatrixForm = z.infer<typeof schema>

const MatrixQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onPaste,
  onViewedConcept,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(
    opts: Pick<QuestionType, 'response' | 'responseFreeText'>,
  ): void
  onPaste(freeText: string): void
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
}) => {
  const { zodErrorMap } = useZodErrorMap()

  const {
    formState: { errors, isSubmitted },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<MatrixForm>({
    defaultValues: {
      response: question.response,
      responseFreeText: question.statements.map((statement) => {
        const freeTextForStatement =
          question.responseFreeText[statement.id] ?? {}

        return {
          options: question.options.map((option) => {
            return {
              freeText: freeTextForStatement[option.id] ?? '',
              freeTextRequired: !!option.freeTextInputType,
              id: option.id,
              max: option.constraints.max,
              min: option.constraints.min,
              title: option.title,
            }
          }),
          statementID: statement.id,
        }
      }),
    } satisfies MatrixForm,
    resolver: zodResolver(schema, { errorMap: zodErrorMap }),
  })

  const curResponse = watch('response')
  const curResponseFreeText = watch('responseFreeText')

  /**
   * Our displayed options aren't really form fields - we currently use buttons. I want to
   * display them as radio groups / checkboxes eventually, but that will be part of a larger
   * usability refresh. For now, we manually update the response value when an option is clicked.
   */
  function onClickOption(statementID: string, optionID: string) {
    const currentOptions = curResponse[statementID] ?? new Set()

    setValue('response', {
      ...curResponse,
      [statementID]: adjustOptionsResponse({
        curResponse: currentOptions,
        optionID,
        question,
      }),
    })

    // Free-text may or may not be required depending on which option(s) the respondent selects.
    // We only want to trigger validation if the form has been submitted though - otherwise, the
    // error message would annoyingly show up prematurely.
    if (isSubmitted) {
      trigger('responseFreeText')
    }
  }

  const onSubmit: SubmitHandler<MatrixForm> = (data) => {
    onCompletedQuestion({
      response: data.response,
      responseFreeText: freeTextArrayToObject(data.responseFreeText),
    })
  }

  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        {question.displayType === 'disclosure' ? (
          <DisclosureMatrix
            curResponse={curResponse}
            curResponseFreeText={curResponseFreeText}
            errors={errors}
            onClickOption={onClickOption}
            onPaste={onPaste}
            question={question}
            register={register}
          />
        ) : (
          <TableMatrix
            curResponse={curResponse}
            onClickOption={onClickOption}
            question={question}
          />
        )}
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ curResponse, question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default MatrixQuestion

/**
 * A display of a disclosure matrix. Each statement is displayed as a disclosure
 * that can be expanded to show the options for that statement.
 */
const DisclosureMatrix = ({
  curResponse,
  curResponseFreeText,
  errors,
  onClickOption,
  onPaste,
  question,
  register,
}: {
  curResponse: MatrixForm['response']
  curResponseFreeText: MatrixForm['responseFreeText']
  errors: FieldErrors<MatrixForm>
  onClickOption(statementID: string, optionID: string): void
  onPaste(freeText: string): void
  question: QuestionType
  register: UseFormRegister<MatrixForm>
}) => {
  const translations = useTranslations()

  const { singleChoice } = question.constraints

  const hasFreeTextOption = question.options.some(
    (option) => option.freeTextInputType,
  )
  const autoOpenStatements = singleChoice && !hasFreeTextOption

  const nextUnansweredStatementID = question.statements.find(
    (statement) => !curResponse[statement.id],
  )?.id

  const { onPanelTriggerKeyDown, onTogglePanel, openStatementIDs } =
    useOpenStatementIDs({
      autoOpenStatements,
      nextUnansweredStatementID,
    })

  const statementsWithFreeTextErrors = getStatementsWithFreeTextErrors({
    errors,
    question,
  })

  return (
    <>
      <div className="space-y-3">
        {question.statements.map(
          ({ features, id, image, title }, statementIdx) => {
            const selectedOptions = curResponse[id] ?? new Set<string>()
            const optionsWithFreeText = getOptionsWithFreeText({
              curResponseFreeText,
              question,
              statementIdx,
            })
            const disabledOptions = question.options.map((option) => {
              return isOptionDisabled({ option, question, selectedOptions })
            })
            const allOptionsDisabled = disabledOptions.every(Boolean)

            const statementHasError = !!statementsWithFreeTextErrors.find(
              (statement) => statement.id === id,
            )

            const selectedTitles = titlesFromSet({
              ids: selectedOptions,
              resources: optionsWithFreeText,
            }).join(', ')
            let ariaLabel = `${title}`
            if (selectedTitles) {
              ariaLabel = `${ariaLabel} - ${selectedTitles}`
            }

            const open = !allOptionsDisabled && openStatementIDs.has(id)
            const highlightedHeader = open || selectedOptions.size > 0

            let borderClasses = 'border border-gray-4'
            if (selectedOptions.size > 0) {
              borderClasses = 'border-2 border-green-2'
            } else if (!allOptionsDisabled && open) {
              borderClasses = 'border border-green-5'
            }

            let headerClasses = 'bg-white'
            if (allOptionsDisabled) {
              headerClasses = 'text-gray-4'
            } else if (statementHasError) {
              headerClasses = 'bg-red-1'
            } else if (highlightedHeader) {
              headerClasses = 'bg-green-5'
            }

            const panelId = `disclosure-panel-${id}`

            return (
              <div
                key={id}
                className={clsx(
                  'overflow-hidden rounded-lg bg-white',
                  borderClasses,
                )}
              >
                {/*
                 * This is a "div" with a "button" role because we could have buttons nested in the
                 * header (e.g. for images) and it's invalid to have a button inside a button.
                 */}
                <div
                  aria-controls={panelId}
                  aria-expanded={open}
                  aria-label={ariaLabel}
                  className={clsx(
                    'w-full p-4 text-left transition-colors',
                    headerClasses,
                  )}
                  onClick={() => {
                    if (!allOptionsDisabled) {
                      onTogglePanel(id)
                    }
                  }}
                  onKeyDown={(event) => {
                    onPanelTriggerKeyDown({ event, id })
                  }}
                  role="button"
                  tabIndex={0}
                >
                  <StatementHeader
                    image={image}
                    open={open}
                    selectedTitles={selectedTitles}
                    title={features.hideTitle ? '' : title}
                  />
                </div>

                <AnimateChangeInHeight open={open}>
                  <div className="divide-y divide-gray-4" id={panelId}>
                    {image && (
                      <div className="py-2">
                        <Image image={image} variant="disclosure-panel" />
                      </div>
                    )}

                    {question.options.map((option, optionIdx) => {
                      const inputId = `${id}-${option.id}`
                      const isDisabled = disabledOptions[optionIdx]
                      const isSelected = selectedOptions.has(option.id)
                      const freeTextFieldName =
                        `responseFreeText.${statementIdx}.options.${optionIdx}.freeText` as const

                      const InputComponent = singleChoice
                        ? InputRadio
                        : InputCheckbox

                      return (
                        <div key={option.id} className="space-y-2">
                          <div className="relative w-full">
                            <div className="absolute left-4 top-6 -translate-y-1/2">
                              <InputComponent
                                checked={selectedOptions.has(option.id)}
                                disabled={isDisabled}
                                id={inputId}
                                onChange={() => {
                                  onClickOption(id, option.id)

                                  // For non free-text options, we close the panel when a selection
                                  // is made so the next panel is automatically opened (to reduce the
                                  // number of clicks for the respondent). We don't close the panel
                                  // if there's free-text the respondent has to enter or if they can select
                                  // multiple options.
                                  if (
                                    singleChoice &&
                                    !option.freeTextInputType
                                  ) {
                                    onTogglePanel(id)
                                  }
                                }}
                              />
                            </div>

                            <label
                              aria-label={`${title} - ${option.title}`}
                              className={clsx('flex w-full p-4 py-3 pl-12', {
                                'text-gray-3': isDisabled,
                              })}
                              htmlFor={inputId}
                            >
                              {option.title}
                            </label>
                          </div>

                          {isSelected && option.freeTextInputType && (
                            <div className="space-y-1 px-4 pb-4">
                              <Input
                                {...register(freeTextFieldName)}
                                aria-label={translations.ACCESSIBILITY.MATRIX_FREE_TEXT_LABEL(
                                  { option: option.title, statement: title },
                                )}
                                onPaste={(event) => {
                                  onPaste(event.clipboardData.getData('text'))
                                }}
                                step={getStepForInputType(
                                  option.freeTextInputType,
                                )}
                                type={option.freeTextInputType}
                              />

                              <ErrorMessage
                                as={FieldError}
                                errors={errors}
                                name={freeTextFieldName}
                              />
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </AnimateChangeInHeight>
              </div>
            )
          },
        )}
      </div>

      {statementsWithFreeTextErrors.length > 0 && (
        <div className="rounded-lg bg-red-1 p-4 text-sm" role="alert">
          {translations.ERRORS.MATRIX_FREE_TEXT_MISSING_HEADER}
          <ul className="list-disc pl-4">
            {statementsWithFreeTextErrors.map((statement) => {
              return <li key={statement.id}>{statement.title}</li>
            })}
          </ul>
        </div>
      )}
    </>
  )
}

/**
 * The header for a disclosure matrix statement. Displays an optional image,
 * the title of the statement, the selected option, and a chevron icon to
 * indicate the user can expand and contract the disclosure.
 */
const StatementHeader = ({
  image,
  open,
  selectedTitles,
  title,
}: {
  image: Statement['image']
  open: boolean
  selectedTitles: string | undefined
  title: string
}) => {
  return (
    <div className="flex items-start justify-between">
      <div className="flex">
        {image && (
          <div
            className={clsx('mr-4', { hidden: open })}
            onClick={(event) => {
              // We stop propagation here so a click on the zoom controls
              // for an image doesn't cause a closed matrix statement to be opened.
              event.stopPropagation()
            }}
          >
            <Image image={image} variant="disclosure-header" />
          </div>
        )}

        <div>
          {title && <div className="font-medium">{title}</div>}

          {selectedTitles && <div className="text-sm">{selectedTitles}</div>}
        </div>
      </div>

      <div className={clsx('h-6 w-6', { 'rotate-180': open })}>
        <Icon id="chevron-down" />
      </div>
    </div>
  )
}

/**
 * A traditional table display for a matrix. The table will scroll horizontally
 * on smaller screen sizes.
 */
const TableMatrix = ({
  curResponse,
  onClickOption,
  question,
}: {
  curResponse: MatrixForm['response']
  onClickOption(statementID: string, optionID: string): void
  question: QuestionType
}) => {
  // We use state for the container because we calculate widths based on the container's
  // size and so we need to re-render when the container changes (i.e. on the first render
  // when it's initially null and then gets set).
  const [container, setContainer] = useState<HTMLDivElement | null>(null)
  const [hasVisibleLastCell, setHasVisibleLastCell] = useState(true)

  let columns: Option[] | Statement[] = question.options
  let rows: Option[] | Statement[] = question.statements
  let selectionCells = question.statements.map((statement) => {
    return question.options.map((option) => {
      return { option, statement }
    })
  })

  if (question.displayType === 'table-inverted') {
    columns = question.statements
    rows = question.options
    selectionCells = question.options.map((option) => {
      return question.statements.map((statement) => {
        return { option, statement }
      })
    })
  }

  const containerWidth = container?.getBoundingClientRect().width
  const { columnWidth, tableWidth } = useTableWidths({
    containerWidth,
    // We add one because we have a column for the row headers.
    numColumns: columns.length + 1,
    scroll: question.features.scroll,
  })

  const checkLastCellVisibility = useCallback(() => {
    if (!container) {
      return
    }

    const { scrollLeft, scrollWidth } = container

    const scrollRight = scrollLeft + container.getBoundingClientRect().width
    const halfColumnWidth = columnWidth / 2
    const hasScrolledToHalfwayOfLastColumn =
      scrollWidth - scrollRight < halfColumnWidth

    setHasVisibleLastCell(hasScrolledToHalfwayOfLastColumn)
  }, [container, columnWidth])

  // Want to check the last column's visibility when we first render in case it's
  // hidden since we assume it's visible.
  useEffect(() => {
    checkLastCellVisibility()
  }, [checkLastCellVisibility])

  return (
    <div
      ref={setContainer}
      className={clsx('overflow-x-scroll md:text-xs', {
        'text-sm': columns.length <= 7,
        'text-xs': columns.length > 7,
      })}
      onScroll={() => {
        checkLastCellVisibility()
      }}
    >
      <table
        // h-full is important so that we can set the cell height to the full height of the td element
        // below. See https://stackoverflow.com/questions/3542090/how-to-make-div-fill-td-height.
        className="h-full w-full border-separate border-spacing-0 border-b border-gray-200"
        style={{ width: tableWidth }}
      >
        <thead>
          <tr>
            <th className="min-w-[110px] md:min-w-0" />
            {columns.map((column, colIdx) => {
              return (
                <th
                  key={column.id}
                  className={clsx('px-1 pb-2 font-normal', {
                    'bg-gray-4': hasCellBgColor({ colIdx, question }),
                  })}
                  style={{ width: columnWidth }}
                >
                  <RowColumnHeader item={column} variant="table-column" />
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIdx) => {
            return (
              <tr
                key={row.id}
                className={clsx(
                  // "after" styles are inspired by TypeForm, which displays a right border
                  // element to indicate there's more columns to scroll to.
                  "after:sticky after:right-0 after:table-cell after:border-2 after:border-primary-600/60 after:transition-opacity after:content-['']",
                  {
                    'after:opacity-100': !hasVisibleLastCell,
                    'after:opacity-0': hasVisibleLastCell,
                  },
                )}
              >
                <td
                  className={clsx('border-t border-gray-200 py-2', {
                    'bg-gray-4': hasCellBgColor({ question, rowIdx }),
                  })}
                  style={{ width: columnWidth }}
                >
                  <RowColumnHeader item={row} variant="table-row" />
                </td>

                {selectionCells[rowIdx].map(({ option, statement }, colIdx) => {
                  const selectedOptions =
                    curResponse[statement.id] ?? new Set<string>()
                  const isDisabled = isOptionDisabled({
                    option,
                    question,
                    selectedOptions,
                  })
                  const Component = question.constraints.singleChoice
                    ? InputRadio
                    : InputCheckbox
                  const componentID = `${statement.id}-${option.id}`

                  return (
                    <td
                      key={`${statement.id}-${option.id}`}
                      // h-full is important so that we can set the cell height to the full height of the td element
                      // below. See https://stackoverflow.com/questions/3542090/how-to-make-div-fill-td-height.
                      className={clsx('h-full border-t border-gray-200', {
                        'bg-gray-4': hasCellBgColor({
                          colIdx,
                          question,
                          rowIdx,
                        }),
                      })}
                      style={{ width: columnWidth }}
                    >
                      <label
                        className="flex h-full items-center justify-center py-2"
                        htmlFor={componentID}
                      >
                        <Component
                          aria-label={`${statement.title} - ${option.title}`}
                          checked={selectedOptions.has(option.id)}
                          disabled={isDisabled}
                          id={componentID}
                          name={`group-${statement.id}`}
                          onChange={() => {
                            onClickOption(statement.id, option.id)
                          }}
                        />
                      </label>
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const RowColumnHeader = ({
  item,
  variant,
}: {
  item: Option | Statement
  variant: 'table-column' | 'table-row'
}) => {
  const { title } = item
  const image = 'image' in item ? item.image : undefined
  const hideTitle = 'features' in item && item.features.hideTitle
  const imageContent = image ? <Image image={image} variant={variant} /> : null

  return (
    <div
      aria-label={title}
      className={clsx('space-y-2', {
        'flex h-full flex-col justify-end text-center': image,
        'pl-2': variant === 'table-row' && !image,
        'pt-2': variant === 'table-column' && !image,
      })}
    >
      {variant === 'table-row' && imageContent}

      {!hideTitle && title && <div>{title}</div>}

      {variant === 'table-column' && imageContent}
    </div>
  )
}

type ImageVariant =
  | 'disclosure-header'
  | 'disclosure-panel'
  | 'table-column'
  | 'table-row'
const IMAGE_SIZES: Record<ImageVariant, string> = {
  'disclosure-header': 'w-20 h-20',
  'disclosure-panel': 'h-32',
  'table-column': 'w-20 h-20',
  'table-row': 'w-20 h-20',
}

const Image = ({
  image,
  variant,
}: {
  image: ImageType
  variant: ImageVariant
}) => {
  const cldImg = getImageFromPublicID({ publicID: image.publicID })

  return (
    <div className="flex flex-col items-center justify-end">
      <div className={clsx('relative', IMAGE_SIZES[variant])}>
        <AdvancedImage
          alt={image.alt}
          className="h-full w-full object-contain"
          cldImg={cldImg}
        />

        <ImageZoomControls alt={image.alt} image={cldImg} />
      </div>
    </div>
  )
}

/**
 * Converts the free-text array from the form to an object we store in our state.
 * We need an array in the form since objects aren't supported.
 */
function freeTextArrayToObject(freeTextArray: MatrixForm['responseFreeText']) {
  const freeTextObject: QuestionType['responseFreeText'] = {}
  freeTextArray.forEach(({ options, statementID }) => {
    const optionsFreeText: Record<string, string> = {}

    options.forEach(({ freeText, id }) => {
      optionsFreeText[id] = freeText
    })

    freeTextObject[statementID] = optionsFreeText
  })

  return freeTextObject
}

function getOptionsWithFreeText({
  curResponseFreeText,
  question,
  statementIdx,
}: {
  curResponseFreeText: MatrixForm['responseFreeText']
  question: QuestionType
  statementIdx: number
}) {
  return question.options.map((option, optionIdx) => {
    const freeText =
      curResponseFreeText[statementIdx]?.options[optionIdx]?.freeText ?? ''

    return {
      id: option.id,
      title: freeText ? `${option.title} (${freeText})` : option.title,
    }
  })
}

/**
 * Returns the statement IDs where one of the statement options has an error with
 * a free-text field.
 */
function getStatementsWithFreeTextErrors({
  errors,
  question,
}: {
  errors: FieldErrors<MatrixForm>
  question: QuestionType
}) {
  // This is so ugly but it seems like nothing in react-hook-form is non-optional?
  // I'm sure there's a better way to do this, but not 100% sure.
  const statementIndexesWithError =
    errors.responseFreeText
      ?.map?.((_, index) => index)
      ?.filter?.((index) => index !== undefined) ?? []

  return question.statements.filter((_, index) =>
    statementIndexesWithError.includes(index),
  )
}

/**
 * We add a background color to cells to create visual separation between rows in
 * a non-inverted table and columns in an inverted table.
 */
function hasCellBgColor({
  colIdx,
  question,
  rowIdx,
}: {
  colIdx?: number
  question: QuestionType
  rowIdx?: number
}) {
  if (question.displayType === 'table') {
    return rowIdx !== undefined && rowIdx % 2 === 1
  }

  return colIdx !== undefined && colIdx % 2 === 0
}

/**
 * Manages the state of open statement IDs for a disclosure matrix. The next unanswered statement
 * is opened if the autoOpenStatements flag is passed.
 *
 * The returned onDisclosureOpenChanged function is intended to sync with HeadlessUI's Disclosure.
 * See HeadlessUIDisclosureOpenChangeObserver for more details.
 */
function useOpenStatementIDs({
  autoOpenStatements,
  nextUnansweredStatementID,
}: {
  autoOpenStatements: boolean
  nextUnansweredStatementID: string | undefined
}) {
  const [openStatementIDs, setOpenStatementIDs] = useState(
    // We default to opening the first statement of the matrix for the respondent to answer.
    new Set(nextUnansweredStatementID ? [nextUnansweredStatementID] : []),
  )

  // When the next unanswered statement changes (i.e. a respondent answers a statement), we
  // open the next one for them. But we only do this if the statement is single choice.
  // Otherwise, we want the disclosure to remain open so the respondent can decide to select
  // more options if they wish.
  useEffect(() => {
    if (!autoOpenStatements || !nextUnansweredStatementID) {
      return
    }

    setOpenStatementIDs((ids) => {
      const newIDs = new Set(ids)
      newIDs.add(nextUnansweredStatementID)

      return newIDs
    })
  }, [autoOpenStatements, nextUnansweredStatementID])

  const onTogglePanel = useCallback((statementID: string) => {
    setOpenStatementIDs((ids) => {
      const newIDs = new Set(ids)
      if (newIDs.has(statementID)) {
        newIDs.delete(statementID)
      } else {
        newIDs.add(statementID)
      }

      return newIDs
    })
  }, [])

  const onPanelTriggerKeyDown = useCallback(
    ({ event, id }: { event: KeyboardEvent<HTMLDivElement>; id: string }) => {
      // See https://www.w3.org/WAI/ARIA/apg/patterns/disclosure/ for accessible guidelines
      // for disclosures.
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault()
        event.stopPropagation()

        onTogglePanel(id)
      }
    },
    [onTogglePanel],
  )

  return {
    onPanelTriggerKeyDown,
    onTogglePanel,
    openStatementIDs,
  }
}

const SMALLEST_SCREEN_SIZE = 320
const COLUMNS_ON_ONE_SCREEN = { MOBILE: 6, DESKTOP: 11 }

function useTableWidths({
  containerWidth,
  numColumns,
  scroll,
}: {
  containerWidth: number | undefined
  numColumns: number
  scroll: boolean
}) {
  const getColumnWidth = useCallback(() => {
    if (!containerWidth || scroll) {
      // 120px is an arbitrary size when the "scroll" feature is enabled.
      return 120
    }

    let columnsOnOneScreen = COLUMNS_ON_ONE_SCREEN.MOBILE
    if (window.innerWidth > 767) {
      columnsOnOneScreen = COLUMNS_ON_ONE_SCREEN.DESKTOP
    }

    // If there's no "scroll" feature and we're on a mobile screen, we want to try to fit 6 columns
    // onto the screen. But we don't want to go below what would be possible on a 320px screen (i.e.
    // the smallest mobile screen).
    return Math.max(
      containerWidth / columnsOnOneScreen,
      SMALLEST_SCREEN_SIZE / columnsOnOneScreen,
    )
  }, [containerWidth, scroll])

  const [columnWidth, setColumnWidth] = useState(() => getColumnWidth())

  // The getColumnWidth function will change if the container or scroll props change.
  // In both those cases we may have a new column width to set.
  useEffect(() => {
    setColumnWidth(getColumnWidth())
  }, [getColumnWidth])

  useEffect(() => {
    const onResize = throttle(() => {
      setColumnWidth(getColumnWidth())
    }, 500)

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [getColumnWidth])

  let tableWidth: number | undefined = columnWidth * numColumns
  let resolvedColumnWidth = columnWidth

  // We always want to make use of the available space. So if the table width is less than
  // the passed in container width, we'll make sure to distribute the space evenly for the
  // number of columns we have.
  if (containerWidth && tableWidth < containerWidth) {
    // We set this undefined to fallback to a full-width table.
    tableWidth = undefined
    resolvedColumnWidth = containerWidth / numColumns
  }

  return { columnWidth: resolvedColumnWidth, tableWidth }
}
