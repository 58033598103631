import { some } from 'lodash-es'

import {
  OptionImage as OptionImageRanking,
  Question as QuestionRanking,
} from 'utils/ranking'
import { Question } from 'utils/questions'
import { Question as QuestionMultipleChoice } from 'utils/multipleChoice'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented, { NestedListItem } from './ListDiscIndented'

const OptionsConstraints = ({ question }: { question: Question }) => {
  if (question.type === 'multipleChoice') {
    return <OptionsConstraintsMultipleChoice question={question} />
  } else if (question.type === 'ranking') {
    return <OptionsConstraintsRanking question={question} />
  }

  return null
}

export default OptionsConstraints

const OptionsConstraintsMultipleChoice = ({
  question,
}: {
  question: QuestionMultipleChoice
}) => {
  const optionsWithConstraints = question.options.filter((option) => {
    return option.freeTextInputType || some(option.constraints)
  })

  if (optionsWithConstraints.length === 0) {
    return null
  }

  return (
    <FeatureDisplay title="Options Constraints">
      <ListDiscIndented>
        {optionsWithConstraints.map((option) => {
          const constraints: string[] = []

          const { max, min } = option.constraints

          if (option.freeTextInputType) {
            constraints.push(`Free Text: ${option.freeTextInputType}`)

            if (max || min) {
              constraints.push(
                `Min-Max: [${min?.value ?? ''}, ${max?.value ?? ''}]`,
              )
            }
          }

          if (option.type === 'image' && option.constraints.requireView) {
            constraints.push('Require Image View')
          }

          if (constraints.length === 0) {
            return null
          }

          return (
            <li key={option.id}>
              <NestedListItem title={option.title}>
                {constraints.map((constraint) => (
                  <li key={constraint}>{constraint}</li>
                ))}
              </NestedListItem>
            </li>
          )
        })}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

const OptionsConstraintsRanking = ({
  question,
}: {
  question: QuestionRanking
}) => {
  const optionsWithConstraints = question.options.filter(
    (option): option is OptionImageRanking => {
      return option.type === 'image' && some(option.constraints)
    },
  )

  if (optionsWithConstraints.length === 0) {
    return null
  }

  return (
    <FeatureDisplay title="Options Constraints">
      <ListDiscIndented>
        {optionsWithConstraints.map((option) => {
          const constraints: string[] = []

          if (option.constraints.requireView) {
            constraints.push('Require Image View')
          }

          if (constraints.length === 0) {
            return null
          }

          return (
            <li key={option.id}>
              <NestedListItem title={option.title}>
                {constraints.map((constraint) => (
                  <li key={constraint}>{constraint}</li>
                ))}
              </NestedListItem>
            </li>
          )
        })}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}
