import { compact } from 'lodash-es'
import mixpanel from 'mixpanel-browser'

import { DisqualificationReason } from './disqualifications'
import { getEnvVar } from './env'
import { Question } from './questions'
import { Question as QuestionGaborGranger } from './gaborGranger'
import { titlesFromSet } from './general'

type Events = {
  'Completed Question': AnalyticsQuestion
  'Completed Survey': { timeToComplete: number | null }
  'Displayed Survey Closed': never
  Disqualified: Partial<DisqualificationReason> & {
    questionID: string
    questionTitle: string
  }
  'Failed Redirect': never
  'Skipped Question': {
    questionID: string
    questionTitle: string
    reason: 'unresolvedPipes'
  }
  'Started Question': { questionID: string; questionTitle: string }
  'Started Survey': never
  'Track Disqualification Failed': {
    tactic: DisqualificationReason['tactic'] | undefined
  }
}

// Super properties are properties that are sent with every event.
// See https://docs.mixpanel.com/docs/tracking/reference/javascript#super-properties
type SuperProperties = {
  mode: 'live' | 'testing'
  respondentID: string
  surveyID: string
}

// This is an interval in milliseconds to batch send events.
export const BATCH_FLUSH_INTERVAL = 250

// eslint-disable-next-line import/no-named-as-default-member -- Not using the default export doesn't work.
mixpanel.init(getEnvVar('MIXPANEL_PROJECT_TOKEN'), {
  batch_flush_interval_ms: BATCH_FLUSH_INTERVAL,
  debug: getEnvVar('APP_ENV') === 'local',
  persistence: 'localStorage',
  track_pageview: true,
})

export function disableTracking() {
  // eslint-disable-next-line import/no-named-as-default-member -- Not using the default export doesn't work.
  mixpanel.disable()
}

export function setProperty<Property extends keyof SuperProperties>(
  property: Property,
  value: SuperProperties[Property],
) {
  // eslint-disable-next-line import/no-named-as-default-member -- Not using the default export doesn't work.
  mixpanel.register({ [property]: value })
}

export function track<Event extends keyof Events>(
  event: Event,
  opts?: Events[Event],
) {
  // We make a copy of "opts" below because Mixpanel mutates the object and adds a token
  // property (:angry-face:).
  // eslint-disable-next-line import/no-named-as-default-member -- Not using the default export doesn't work.
  mixpanel.track(event, { ...opts })
}

interface AnalyticsQuestion {
  concept?: string
  directions: string
  questionID: string
  questionTitle: string
}

interface AnalyticsQuestionBipolar extends AnalyticsQuestion {
  type: 'bipolar'
  values: Record<string, number>
}

interface AnalyticsQuestionGaborGranger extends AnalyticsQuestion {
  type: 'gaborGranger'
  valueOptionResponses: QuestionGaborGranger['response']
}

interface AnalyticsQuestionIdeaPresenter extends AnalyticsQuestion {
  type: 'ideaPresenter'
}

interface AnalyticsQuestionMatrix extends AnalyticsQuestion {
  statementSelections: Record<string, string[]>
  statementsDisplayed: string[]
  type: 'matrix'
}

interface AnalyticsQuestionMultipleChoice extends AnalyticsQuestion {
  optionsDisplayed: string[]
  optionsSelected: string[]
  type: 'multipleChoice'
}

interface AnalyticsQuestionOpenEnded extends AnalyticsQuestion {
  type: 'openEnded'
}

interface AnalyticsQuestionRanking extends AnalyticsQuestion {
  optionsDisplayed: string[]
  optionsRanked: string[]
  type: 'ranking'
}

interface AnalyticsQuestionScale extends AnalyticsQuestion {
  scalesDisplayed: string[]
  scalesValues: Record<string, number | undefined>
  type: 'scale'
}

interface AnalyticsQuestionUnaidedAwareness extends AnalyticsQuestion {
  type: 'unaidedAwareness'
}

/**
 * Accepts a question and returns a modified version with a subset of properties that are
 * potentially relevant for identifying the question in our third-party analytics.
 *
 * For instance, many of the configuration options of a question (such as carry forward, display
 * logic, display X of Y, etc.) are not relevant in our analytics events (or can be inferred),
 * whereas things like the question title, displayed resources, and the response are.
 */
export function questionToAnalyticsQuestion(question: Question) {
  const commonProps = {
    concept: question.concepts[0]?.alt ?? question.concepts[0]?.publicID,
    directions: question.directions,
    questionID: question.id,
    questionTitle: question.title,
  } satisfies AnalyticsQuestion

  if (question.type === 'bipolar') {
    const values: AnalyticsQuestionBipolar['values'] = {}

    question.options.forEach(({ id, title }) => {
      values[title] = question.response[id]
    })

    return {
      ...commonProps,
      type: question.type,
      values,
    } satisfies AnalyticsQuestionBipolar
  } else if (question.type === 'gaborGranger') {
    return {
      ...commonProps,
      type: question.type,
      valueOptionResponses: question.response,
    } satisfies AnalyticsQuestionGaborGranger
  } else if (question.type === 'ideaPresenter') {
    return {
      ...commonProps,
      type: question.type,
    } satisfies AnalyticsQuestionIdeaPresenter
  } else if (question.type === 'matrix') {
    const statementSelections: AnalyticsQuestionMatrix['statementSelections'] =
      {}
    const statementsDisplayed: AnalyticsQuestionMatrix['statementsDisplayed'] =
      []

    question.statements.forEach(({ id, title }) => {
      statementsDisplayed.push(title)
      statementSelections[title] = titlesFromSet({
        ids: question.response[id] ?? new Set(),
        resources: question.options,
      })
    })

    return {
      ...commonProps,
      statementSelections,
      statementsDisplayed,
      type: question.type,
    } satisfies AnalyticsQuestionMatrix
  } else if (question.type === 'multipleChoice') {
    return {
      ...commonProps,
      optionsDisplayed: question.options.map(({ title }) => {
        return title
      }),
      optionsSelected: titlesFromSet({
        ids: question.response,
        resources: question.options,
      }),
      type: question.type,
    } satisfies AnalyticsQuestionMultipleChoice
  } else if (question.type === 'openEnded') {
    return {
      ...commonProps,
      type: question.type,
    } satisfies AnalyticsQuestionOpenEnded
  } else if (question.type === 'ranking') {
    return {
      ...commonProps,
      optionsDisplayed: question.options.map(({ title }) => {
        return title
      }),
      optionsRanked: compact(
        question.response.map((optionID) => {
          const option = question.options.find(({ id }) => id === optionID)

          return option?.title
        }),
      ),
      type: question.type,
    } satisfies AnalyticsQuestionRanking
  } else if (question.type === 'scale') {
    const scalesDisplayed: AnalyticsQuestionScale['scalesDisplayed'] = []
    const scalesValues: AnalyticsQuestionScale['scalesValues'] = {}

    question.scales.forEach(({ id, title }) => {
      scalesDisplayed.push(title)
      scalesValues[title] = question.response[id]
    })

    return {
      ...commonProps,
      scalesDisplayed,
      scalesValues,
      type: question.type,
    } satisfies AnalyticsQuestionScale
  } else if (question.type === 'unaidedAwareness') {
    return {
      ...commonProps,
      type: question.type,
    } satisfies AnalyticsQuestionUnaidedAwareness
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The thrown error is to protect against when we add a new question choice type and forget to add it here.
  // @ts-expect-error
  throw new Error(`Unsupported question type: ${question.type}`)
}
