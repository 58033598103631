import { AdvancedImage } from '@cloudinary/react'
import { CloudinaryImage } from '@cloudinary/url-gen'
import { Dialog } from '@headlessui/react'
import { MouseEventHandler, ReactNode, useRef, useState } from 'react'
import PrismaZoom from 'react-prismazoom'

import { useTranslations } from 'contexts/i18n'

import Icon from './Icon'

/**
 * Renders a zoom button in the bottom-right hand corner or an overlay with a zoom icon
 * centered. The rendering parent is expected to provide a relative container.
 *
 * @param zoomRequired If true, the image must be zoomed before other interactions can
 *  occur. A provided overlay will not be rendered until this is false.
 */
const ImageZoomControls = ({
  alt,
  image,
  onClickZoom,
  overlay,
  zoomRequired = false,
}: {
  alt: string
  image: CloudinaryImage
  onClickZoom?(): void
  overlay?: ReactNode
  zoomRequired?: boolean
}) => {
  const translations = useTranslations()

  const [showZoomedImage, setShowZoomedImage] = useState(false)
  const zoomButton = (
    <ZoomButton
      alt={alt}
      onClick={(event) => {
        // We prevent the default action to prevent the click event from bubbling up
        // and potentially selecting an option.
        event.preventDefault()

        setShowZoomedImage(true)
        onClickZoom?.()
      }}
    />
  )

  return (
    <>
      {zoomRequired ? (
        <div className="absolute inset-0 z-10 bg-black/60">
          {zoomButton}

          {/* This has a negative z-index so clicking it still causes a click on the zoomButton above. */}
          <p className="absolute bottom-2 left-0 -z-10 w-full text-center text-xs text-white">
            {translations.UI_ELEMENTS.IMAGE_OVERLAY_CLICK}
          </p>
        </div>
      ) : (
        <>
          <div className="absolute bottom-0 right-0 z-10 h-6 w-6 rounded-tl bg-black/60 md:h-8 md:w-8">
            {zoomButton}
          </div>

          {overlay && <div className="absolute inset-0">{overlay}</div>}
        </>
      )}

      <ZoomedImageDialog
        alt={alt}
        image={image}
        isOpen={showZoomedImage}
        onClose={() => {
          setShowZoomedImage(false)
        }}
      />
    </>
  )
}

export default ImageZoomControls

const ZoomButton = ({
  alt,
  onClick,
}: {
  alt: string
  onClick: MouseEventHandler<HTMLButtonElement>
}) => {
  const translations = useTranslations()

  return (
    <button
      aria-label={translations.ACCESSIBILITY.ZOOM_OPTION(alt)}
      className="flex h-full w-full items-center justify-center p-1"
      onClick={onClick}
      type="button"
    >
      <div aria-hidden="true" className="h-4 w-4 text-white md:h-5 md:w-5">
        <Icon id="zoom-in" />
      </div>
    </button>
  )
}

const ZoomedImageDialog = ({
  alt,
  image,
  isOpen,
  onClose,
}: {
  alt: string
  image: CloudinaryImage
  isOpen: boolean
  onClose(): void
}) => {
  const translations = useTranslations()

  const isMultiTouchZoom = useRef(false)

  return (
    <Dialog
      className="relative z-50"
      onClose={() => {
        // A user may be doing a multi-touch zoom where one of the touch-points is
        // outside of the dialog panel contents. Without this check, the dialog would
        // close when the user lifts their fingers after zooming in.
        if (!isMultiTouchZoom.current) {
          onClose()
        }
      }}
      onTouchEnd={() => {
        isMultiTouchZoom.current = false
      }}
      onTouchMove={() => {
        isMultiTouchZoom.current = true
      }}
      open={isOpen}
    >
      <div aria-hidden="true" className="fixed inset-0 bg-black/80" />

      <div className="fixed inset-0 flex flex-col items-center justify-center">
        <Dialog.Panel className="max-w-[75%]">
          <PrismaZoom maxZoom={7} scrollVelocity={0.75}>
            <AdvancedImage
              alt={alt}
              className="max-h-[calc(100vh-200px)] bg-gray-4 object-contain"
              cldImg={image}
            />
          </PrismaZoom>
        </Dialog.Panel>

        {/*
         * We don't display this inside the Dialog.Panel because we want clicks on
         * this content to close the dialog.
         */}
        <div className="mt-8 space-y-4 text-center text-white">
          <p>{translations.UI_ELEMENTS.IMAGE_OVERLAY_SCROLL}</p>
          <p>{translations.UI_ELEMENTS.IMAGE_OVERLAY_CLOSE}</p>
        </div>
      </div>
    </Dialog>
  )
}
