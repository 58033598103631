import { useEffect, useState } from 'react'

import Icon from 'components/Icon'
import SpinningLoader from 'components/SpinningLoader'

const PHRASES = [
  'Hang tight! Your survey is loading.',
  'Almost there! Preparing your survey.',
  "Just a moment, we're getting your survey ready.",
  'Your survey is on the way! Thanks for your patience.',
]

const LoadingPage = () => {
  const [phraseIndex, setPhraseIndex] = useState(0)

  // Chooses a new phrase for the loading page every 4 seconds.
  useEffect(() => {
    const interval = window.setInterval(() => {
      setPhraseIndex((phraseIndex) => phraseIndex + 1)
    }, 4000)

    return () => {
      window.clearInterval(interval)
    }
  }, [])

  return (
    <div className="flex h-full flex-col items-center justify-center space-y-6 text-center text-gray-700 md:px-8">
      <div className="w-36">
        <Icon id="survey-loading" />
      </div>

      {/* Note: The animation duration here matches the interval for changing the phrase. */}
      <p className="animate-[4s_ease-in_infinite_text-in] text-2xl font-semibold">
        {PHRASES[phraseIndex % PHRASES.length]}
      </p>

      <SpinningLoader />
    </div>
  )
}

export default LoadingPage
