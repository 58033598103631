import axios from 'axios'

import {
  AnswerGaborGranger,
  AnswerIdeaPresenter,
  AnswerMatrix,
  AnswerMultipleChoice,
  AnswerOpenEnded,
  AnswerRanking,
  AnswerScale,
  Survey,
  User,
} from 'types/glass-api/domainModels'
import { getAPIUrl } from './baseAPI'

export interface GetSurvey {
  surveyHash: string
}

export interface SubmitAnswers {
  data: {
    answers: (
      | AnswerGaborGranger
      | AnswerIdeaPresenter
      | AnswerMatrix
      | AnswerMultipleChoice
      | AnswerOpenEnded
      | AnswerRanking
      | AnswerScale
    )[]
    timeToComplete: number | null
  }
  surveyHash: string
}

export type SubmitAnswersResponse =
  | { points: number; redirect: boolean; totalPoints: number; user: User }
  | {
      qualify: boolean
      redirect: boolean
      success: boolean
      user: User
    }

export const GET_SURVEY = ({ surveyHash }: Pick<GetSurvey, 'surveyHash'>) => ({
  path: `/surveys/${surveyHash}`,
  version: 'v1' as const,
})

export const SUBMIT_ANSWERS = ({
  surveyHash,
}: Pick<SubmitAnswers, 'surveyHash'>) => ({
  path: `/answers/${surveyHash}`,
  version: 'v1' as const,
})

export async function getSurvey({ surveyHash }: GetSurvey) {
  return (await axios.get<Survey>(getAPIUrl(GET_SURVEY({ surveyHash })))).data
}

export async function submitAnswers({ data, surveyHash }: SubmitAnswers) {
  return (
    await axios.post<SubmitAnswersResponse>(
      getAPIUrl(SUBMIT_ANSWERS({ surveyHash })),
      data,
    )
  ).data
}
