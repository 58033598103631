import { ButtonHTMLAttributes, ReactNode } from 'react'

type Props = Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'> & {
  icon?: ReactNode
}

const Button = ({ icon = undefined, ...rest }: Props) => {
  const iconToRender = icon ? (
    <div className="flex h-5 w-5 items-center justify-center">{icon}</div>
  ) : null

  return (
    <button
      type="button"
      {...rest}
      className="flex items-center justify-center gap-2 rounded-lg bg-primary-600 px-4 py-3 font-semibold text-white shadow-sm disabled:bg-gray-3"
    >
      {rest.children}
      {iconToRender}
    </button>
  )
}

export default Button
