import ExceptionPage from 'components/ExceptionPage'

const NotFoundPage = () => {
  return (
    <ExceptionPage title="Oops, Survey Not Found">
      We're sorry, but the survey you're looking for doesn't seem to exist. Our
      team is on it.
    </ExceptionPage>
  )
}

export default NotFoundPage
