import {
  getIterationFromID,
  getPreviousQuestionForIteration,
  Question,
} from 'utils/questions'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const Concepts = ({
  question,
  questions,
}: {
  question: Question
  questions: Record<string, Question>
}) => {
  const pipeConcept =
    'pipeConcept' in question.features && question.features.pipeConcept
  if (!question.constraints.requireConceptView && !pipeConcept) {
    return null
  }

  let pipeFromQuestion: Question | undefined
  if (pipeConcept) {
    pipeFromQuestion = getPreviousQuestionForIteration({
      iteration: getIterationFromID(question.id),
      previousQuestionID: pipeConcept.questionID,
      questions,
    })
  }

  return (
    <FeatureDisplay title="Concepts">
      <ListDiscIndented>
        {question.constraints.requireConceptView && (
          <li> Require Concept View</li>
        )}
        {pipeFromQuestion && (
          <li>Pipe Concept: {pipeFromQuestion.testing.label}</li>
        )}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default Concepts
