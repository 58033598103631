import { forwardRef, ReactNode, useEffect, useRef } from 'react'

import Icon from './Icon'

/**
 * A header meant to be displayed when testing a survey with next and previous question buttons.
 */
const TestingHeader = ({
  disabledNext,
  disabledPrevious,
  onClickNext,
  onClickPrevious,
}: {
  disabledNext: boolean
  disabledPrevious: boolean
  onClickNext(): void
  onClickPrevious(): void
}) => {
  const { nextButton, previousButton } = useArrowNavigation()

  return (
    <div className="flex items-start justify-end space-x-2 text-orange-400">
      <ArrowButton
        ref={previousButton}
        aria-label="Previous question"
        disabled={disabledPrevious}
        icon={<Icon id="arrow-narrow-left" />}
        onClick={onClickPrevious}
      />
      <ArrowButton
        ref={nextButton}
        aria-label="Next question"
        disabled={disabledNext}
        icon={<Icon id="arrow-narrow-right" />}
        onClick={onClickNext}
      />
    </div>
  )
}

export default TestingHeader

type ArrowButtonProps = {
  'aria-label': string
  disabled: boolean
  icon: ReactNode
  onClick?(): void
}

const ArrowButton = forwardRef<HTMLButtonElement, ArrowButtonProps>(
  function ArrowButton({ disabled, icon, onClick, ...rest }, ref) {
    return (
      <button
        ref={ref}
        aria-label={rest['aria-label']}
        className="disabled:text-gray-300"
        disabled={disabled}
        onClick={onClick}
        type="button"
      >
        <div aria-hidden="true" className="h-6 w-6">
          {icon}
        </div>
      </button>
    )
  },
)

function useArrowNavigation() {
  const previousButton = useRef<HTMLButtonElement | null>(null)
  const nextButton = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        previousButton.current?.click()
      } else if (event.key === 'ArrowRight') {
        nextButton.current?.click()
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  return { nextButton, previousButton }
}
