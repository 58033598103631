import { BlockSetMonadic } from 'utils/questionBlocks'
import { Question } from 'utils/questions'
import { Survey } from 'utils/surveys'

import ConditionGroupsTable from './ConditionGroupsTable'
import Dialog from '../Dialog'
import LogicGroupWithLine from './LogicGroupWithLine'

const SurveyVariableQuotasDialog = ({
  isOpen,
  monadicBlocks,
  onClose,
  questionsCurrent,
  questionsInitial,
  survey,
}: {
  isOpen: boolean
  monadicBlocks: Record<string, BlockSetMonadic>
  onClose(): void
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  survey: Survey
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title="Survey Variables">
      {survey.quotas.variables.map(
        ({ segments, title: variableTitle }, variableIdx) => {
          return (
            <LogicGroupWithLine
              key={variableIdx}
              isLast={variableIdx === survey.quotas.variables.length - 1}
              logic="and"
            >
              <p>{variableTitle}</p>

              {segments.map(
                ({ conditionGroups, title: segmentTitle }, segmentIdx) => {
                  return (
                    <LogicGroupWithLine
                      key={segmentIdx}
                      isLast={segmentIdx === segments.length - 1}
                      logic="or"
                    >
                      <p>{segmentTitle}</p>

                      <ConditionGroupsTable
                        conditionGroups={conditionGroups}
                        monadicBlocks={monadicBlocks}
                        questionsCurrent={questionsCurrent}
                        questionsInitial={questionsInitial}
                      />
                    </LogicGroupWithLine>
                  )
                },
              )}
            </LogicGroupWithLine>
          )
        },
      )}
    </Dialog>
  )
}

export default SurveyVariableQuotasDialog
