import { compact, map } from 'lodash-es'
import { ComponentProps } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  canContinue,
  hasSelectedFreeTextOption,
  isOptionDisabled,
  Question as QuestionType,
} from 'utils/multipleChoice'
import { getStepForInputType } from 'utils/forms'
import { useConcept } from 'hooks/concepts'
import { useTranslations } from 'contexts/i18n'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import FieldError from './FieldError'
import ImageOption from './ImageOption'
import ImageOptionsContainer from './ImageOptionsContainer'
import Input from './Input'
import InputCheckbox from './InputCheckbox'
import InputLabeled from './InputLabeled'
import InputRadio from './InputRadio'
import Question from './Question'
import RequiredSumFloatingDisplay from './RequiredSumFloatingDisplay'

// Our single response form control is a radio input which could be a string defined value (option ID)
// or null.
type SingleResponse = string | null
type MultipleResponse = Record<string, boolean>
interface MultipleChoiceForm {
  response: SingleResponse | MultipleResponse
  // This doesn't match the type of the responseFreeText property on the saved
  // question because React Hook Form doesn't allow object form fields. Instead,
  // we use an array here and convert to an object once all validation has passed.
  responseFreeText: { optionID: string; value: string }[]
}

const MultipleChoiceQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onPaste,
  onViewedConcept,
  onViewedImage,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(
    opts: Pick<QuestionType, 'response' | 'responseFreeText'>,
  ): void
  onPaste(freeText: string): void
  onViewedConcept(questionID: string, conceptID: string): void
  onViewedImage(optionID: string): void
  question: QuestionType
}) => {
  const translations = useTranslations()

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<MultipleChoiceForm>({
    defaultValues: {
      response: getInitialFormResponse(question),
      responseFreeText: question.options.map((option) => {
        return {
          optionID: option.id,
          value: question.responseFreeText[option.id] ?? '',
        }
      }),
    },
  })

  const formResponse = watch('response')
  const singleChoice = typeof formResponse === 'string' || formResponse === null
  const curResponse = singleChoice
    ? new Set(formResponse ? [formResponse] : [])
    : new Set(
        compact(map(formResponse, (value, key) => (value ? key : undefined))),
      )
  const curResponseFreeText = freeTextArrayToObject(watch('responseFreeText'))

  const onSubmit: SubmitHandler<MultipleChoiceForm> = (data) => {
    onCompletedQuestion({
      response: curResponse,
      responseFreeText: freeTextArrayToObject(data.responseFreeText),
    })
  }

  const options = question.options.map((option, i) => {
    const disabled = isOptionDisabled({ curResponse, option, question })
    const isSelected = singleChoice
      ? formResponse === option.id
      : formResponse[option.id]
    const freeTextError = errors.responseFreeText?.[i]?.value

    const optionFormProps = singleChoice
      ? register('response')
      : register(`response.${option.id}`)

    let ariaLabel = option.title
    if (!ariaLabel && option.type === 'image') {
      ariaLabel = option.image.alt
    }

    const inputId = option.id
    const input = singleChoice ? (
      <InputRadio
        {...optionFormProps}
        aria-label={ariaLabel}
        checked={isSelected}
        disabled={disabled}
        id={inputId}
        value={option.id}
      />
    ) : (
      <InputCheckbox
        {...optionFormProps}
        aria-label={ariaLabel}
        checked={isSelected}
        disabled={disabled}
        id={inputId}
        onChange={(event) => {
          if (question.constraints.exclusiveOptionIDs.has(option.id)) {
            question.options.forEach((o) => {
              setValue(
                `response.${o.id}`,
                // If a respondent clicks an exclusive option, we toggle its value
                // and set any other option to unselected.
                o.id === option.id ? !isSelected : false,
              )
            })
          } else {
            optionFormProps.onChange(event)
          }
        }}
      />
    )

    const freeTextField =
      isSelected && option.freeTextInputType ? (
        <>
          <Input
            {...register(`responseFreeText.${i}.value`, {
              max: option.constraints.max,
              min: option.constraints.min,
              required: {
                message: translations.ERRORS.MC_FREE_TEXT_MISSING(option.title),
                value: true,
              },
            })}
            aria-label={translations.ACCESSIBILITY.MC_FREE_TEXT_LABEL(
              ariaLabel,
            )}
            onPaste={(event) => {
              onPaste(event.clipboardData.getData('text'))
            }}
            step={getStepForInputType(option.freeTextInputType)}
            type={option.freeTextInputType}
          />

          {freeTextError?.message && (
            <div className="w-full">
              <FieldError>{freeTextError.message}</FieldError>
            </div>
          )}
        </>
      ) : null

    return option.type === 'image' ? (
      <ImageOption
        key={option.id}
        alt={option.image.alt}
        disabled={disabled}
        freeTextField={freeTextField}
        input={input}
        inputId={inputId}
        isSelected={isSelected}
        onClickZoom={() => {
          onViewedImage(option.id)
        }}
        publicID={option.image.publicID}
        title={option.features.hideTitle ? null : option.title}
        zoomRequired={
          question.constraints.requireImageViews &&
          option.constraints.requireView &&
          !option.image.viewed
        }
      />
    ) : (
      <div key={option.id} className="space-y-2">
        <InputLabeled
          disabled={disabled}
          hasValue={isSelected}
          id={option.id}
          input={input}
        >
          {option.title}
        </InputLabeled>

        {freeTextField}
      </div>
    )
  })

  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        {/*
         * A multiple choice question could have a mix of free-text and not free-text
         * options. We only want to enforce a sum if at least one free-text option was selected.
         */}
        {hasSelectedFreeTextOption({ curResponse, question }) && (
          <RequiredSumFloatingDisplay
            curResponse={curResponseFreeText}
            sum={question.constraints.sum}
          />
        )}

        {/*
         * We don't currently allow both image and text options for a single question,
         * but there's nothing about this code that couldn't support it. We'd just have
         * to figure out how to display them both in a way that makes sense.
         */}
        {question.options[0]?.type === 'image' ? (
          <ImageOptionsContainer>{options}</ImageOptionsContainer>
        ) : (
          <div className="space-y-3">{options}</div>
        )}
      </Question>
      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={
            !canContinue({
              curResponse,
              curResponseFreeText,
              question,
            })
          }
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default MultipleChoiceQuestion

function freeTextArrayToObject(
  freeTextArray: MultipleChoiceForm['responseFreeText'],
) {
  const freeTextObject: QuestionType['responseFreeText'] = {}
  freeTextArray.forEach(({ optionID, value }) => {
    freeTextObject[optionID] = value
  })

  return freeTextObject
}

/**
 * Transforms the stored response for the multiple choice question, which is a Set, into
 * a string or Record<string, boolean> to be used in the form state, depending on whether
 * or not the question is configured to be single or multiple response.
 */
function getInitialFormResponse(
  question: QuestionType,
): MultipleChoiceForm['response'] {
  if (question.constraints.singleChoice) {
    return Array.from(question.response)[0] ?? ''
  }

  const initialResponse: Record<string, boolean> = {}
  question.options.forEach((option) => {
    initialResponse[option.id] = question.response.has(option.id)
  })

  return initialResponse
}
