import { BlockSetMonadic } from 'utils/questionBlocks'
import { Question } from 'utils/questions'
import { SurveyItemBlockSet, SurveyItemBlockSetMonadic } from 'utils/surveys'

import QuestionSummary from './QuestionSummary'

const BlockSetSummary = ({
  blockSetItem,
  curQuestionID,
  monadicBlocks,
  onClickClearResponse,
  onClickQuestion,
  questionsCurrent,
  storeQuestionRef,
}: {
  blockSetItem: SurveyItemBlockSet | SurveyItemBlockSetMonadic
  curQuestionID: string
  monadicBlocks: Record<string, BlockSetMonadic>
  onClickClearResponse(opts: {
    clearType: 'current' | 'current-subsequent'
    questionID: string
  }): void
  onClickQuestion(questionID: string): void
  questionsCurrent: Record<string, Question>
  storeQuestionRef(questionID: string, ref: HTMLElement | null): void
}) => {
  return blockSetItem.data.blocks.flatMap(({ id, questionIDs, title }) => {
    return (
      <div key={id} className="relative">
        {/* An indicator for the questions that are within the block. */}
        <div className="absolute bottom-1 left-4 top-6 w-px bg-gray-300" />

        <div className="my-1 pl-2 text-xs font-bold uppercase text-gray-400">
          {title}
        </div>

        <ul className="ml-4">
          {questionIDs.map((questionID) => {
            return (
              <li
                key={questionID}
                ref={(ref) => {
                  storeQuestionRef(questionID, ref)
                }}
              >
                <QuestionSummary
                  isCurrent={questionID === curQuestionID}
                  monadicBlocks={monadicBlocks}
                  onClick={() => {
                    onClickQuestion(questionID)
                  }}
                  onClickClearResponse={onClickClearResponse}
                  question={questionsCurrent[questionID]}
                  questionsCurrent={questionsCurrent}
                />
              </li>
            )
          })}
        </ul>
      </div>
    )
  })
}

export default BlockSetSummary
