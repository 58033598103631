import { clsx } from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'
import { Tab } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'

import { BlockSetMonadic } from 'utils/questionBlocks'
import { isBlockSet, Survey, SurveyItem } from 'utils/surveys'
import { Question } from 'utils/questions'

import BlockSetSummary from './testMode/BlockSetSummary'
import CarryForward from './testMode/CarryForward'
import Concepts from './testMode/Concepts'
import DisplayLogic from './testMode/DisplayLogic'
import DisplayXOfY from './testMode/DisplayXOfY'
import Icon from './Icon'
import MultipleResponse from './testMode/MultipleResponse'
import OpenEndedConstraints from './testMode/OpenEndedConstraints'
import OptionsConstraints from './testMode/OptionsConstraints'
import QuestionInfo from './testMode/QuestionInfo'
import QuestionSummary from './testMode/QuestionSummary'
import QuotasOptions from './testMode/QuotasOptions'
import Randomize from './testMode/Randomize'
import SurveyAudience from './testMode/SurveyAudience'
import SurveyInfo from './testMode/SurveyInfo'
import SurveyVariableQuotas from './testMode/SurveyVariableQuotas'

const SurveyWithTesting = ({
  children,
  curQuestionID,
  monadicBlocks,
  onClickClearResponse,
  onClickQuestion,
  questionsCurrent,
  questionsInitial,
  survey,
  surveyItems,
}: {
  children: ReactNode
  curQuestionID: string
  monadicBlocks: Record<string, BlockSetMonadic>
  onClickClearResponse(opts: {
    clearType: 'current' | 'current-subsequent'
    questionID: string
  }): void
  onClickQuestion(questionID: string): void
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  survey: Survey
  surveyItems: SurveyItem[]
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const testView = searchParams.get('testView') ?? 'expanded'
  const showSidebars = testView === 'expanded'

  const sidebarSharedClasses =
    'w-[300px] h-full bg-white border-gray-300 flex-shrink-0 lg:hidden transition-transform'

  return (
    <div className="flex h-full overflow-hidden">
      <div
        className={clsx(sidebarSharedClasses, 'relative z-20 border-r', {
          'translate-x-0': showSidebars,
          '-translate-x-full': !showSidebars,
        })}
      >
        <SurveyOverview
          curQuestionID={curQuestionID}
          monadicBlocks={monadicBlocks}
          onClickClearResponse={onClickClearResponse}
          onClickQuestion={onClickQuestion}
          questionsCurrent={questionsCurrent}
          questionsInitial={questionsInitial}
          survey={survey}
          surveyItems={surveyItems}
        />

        <button
          aria-label="Toggle Expanded Test Mode"
          className="absolute -right-4 bottom-4 flex translate-x-full items-center justify-center rounded-full bg-primary-600 p-3 text-white shadow-lg"
          onClick={() => {
            const newParams = new URLSearchParams(searchParams)
            newParams.set('testView', showSidebars ? 'collapsed' : 'expanded')

            setSearchParams(newParams)
          }}
          type="button"
        >
          <div aria-hidden="true" className="h-5 w-5">
            <Icon id="stars" />
          </div>
        </button>
      </div>

      {children}

      <div
        className={clsx(sidebarSharedClasses, 'border-l', {
          'translate-x-0': showSidebars,
          'translate-x-full': !showSidebars,
        })}
      >
        <SelectedQuestionDetails
          monadicBlocks={monadicBlocks}
          question={questionsCurrent[curQuestionID]}
          questionsCurrent={questionsCurrent}
          questionsInitial={questionsInitial}
        />
      </div>
    </div>
  )
}

export default SurveyWithTesting

const SurveyOverview = ({
  curQuestionID,
  monadicBlocks,
  onClickClearResponse,
  onClickQuestion,
  questionsCurrent,
  questionsInitial,
  survey,
  surveyItems,
}: {
  curQuestionID: string
  monadicBlocks: Record<string, BlockSetMonadic>
  onClickClearResponse(opts: {
    clearType: 'current' | 'current-subsequent'
    questionID: string
  }): void
  onClickQuestion(questionID: string): void
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  survey: Survey
  surveyItems: SurveyItem[]
}) => {
  const overflowContainer = useRef<HTMLElement | null>(null)

  const tabClasses =
    'flex-1 p-4 border-b border-gray-300 text-sm ui-selected:border-primary-600 ui-selected:font-bold'

  return (
    <div className="flex h-full flex-col">
      <Tab.Group>
        <Tab.List className="flex">
          <Tab className={tabClasses}>Questions</Tab>
          <Tab className={tabClasses}>Survey</Tab>
        </Tab.List>
        <Tab.Panels
          ref={overflowContainer}
          className="flex-1 overflow-auto pb-32"
        >
          <Tab.Panel className="outline-none">
            <QuestionsList
              curQuestionID={curQuestionID}
              monadicBlocks={monadicBlocks}
              onClickClearResponse={onClickClearResponse}
              onClickQuestion={onClickQuestion}
              overflowContainer={overflowContainer.current}
              questionsCurrent={questionsCurrent}
              surveyItems={surveyItems}
            />
          </Tab.Panel>
          <Tab.Panel>
            <SurveyInfo survey={survey} />
            <SurveyAudience
              monadicBlocks={monadicBlocks}
              questionsCurrent={questionsCurrent}
              questionsInitial={questionsInitial}
              survey={survey}
            />
            <SurveyVariableQuotas
              monadicBlocks={monadicBlocks}
              questionsCurrent={questionsCurrent}
              questionsInitial={questionsInitial}
              survey={survey}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

const QuestionsList = ({
  curQuestionID,
  monadicBlocks,
  onClickClearResponse,
  onClickQuestion,
  overflowContainer,
  questionsCurrent,
  surveyItems,
}: {
  curQuestionID: string
  monadicBlocks: Record<string, BlockSetMonadic>
  onClickClearResponse(opts: {
    clearType: 'current' | 'current-subsequent'
    questionID: string
  }): void
  onClickQuestion(questionID: string): void
  overflowContainer: HTMLElement | null
  questionsCurrent: Record<string, Question>
  surveyItems: SurveyItem[]
}) => {
  const questionsRefs = useRef<Record<string, HTMLElement | null>>({})

  // Scrolls to the current question if it's not visible.
  useEffect(() => {
    const containerRect = overflowContainer?.getBoundingClientRect()
    const curQuestionRef = questionsRefs.current[curQuestionID]
    const curQuestionRect = curQuestionRef?.getBoundingClientRect()

    if (
      containerRect &&
      curQuestionRect &&
      (curQuestionRect.top < containerRect.top ||
        curQuestionRect.bottom > containerRect.bottom)
    ) {
      // Inspired by https://github.com/facebook/react/issues/23396#issuecomment-1545962611
      window.requestAnimationFrame(() => {
        curQuestionRef?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      })
    }
  }, [curQuestionID, overflowContainer])

  return (
    <ul>
      {surveyItems.flatMap((item) => {
        if (isBlockSet(item)) {
          return (
            <li key={item.data.id}>
              <BlockSetSummary
                blockSetItem={item}
                curQuestionID={curQuestionID}
                monadicBlocks={monadicBlocks}
                onClickClearResponse={onClickClearResponse}
                onClickQuestion={onClickQuestion}
                questionsCurrent={questionsCurrent}
                storeQuestionRef={(questionID, ref) => {
                  questionsRefs.current[questionID] = ref
                }}
              />
            </li>
          )
        }

        return (
          <li
            key={item.data.id}
            ref={(ref) => {
              questionsRefs.current[item.data.id] = ref
            }}
          >
            <QuestionSummary
              isCurrent={item.data.id === curQuestionID}
              monadicBlocks={monadicBlocks}
              onClick={() => {
                onClickQuestion(item.data.id)
              }}
              onClickClearResponse={onClickClearResponse}
              question={questionsCurrent[item.data.id]}
              questionsCurrent={questionsCurrent}
            />
          </li>
        )
      })}
    </ul>
  )
}

const SelectedQuestionDetails = ({
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  question: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) => {
  return (
    <div className="flex h-full flex-col">
      <div className="p-4 text-sm">Question Details</div>
      <div className="flex-1 overflow-auto pb-32">
        <QuestionInfo question={question} />
        <MultipleResponse question={question} />
        <DisplayXOfY question={question} />
        <Randomize question={question} />

        <OptionsConstraints question={question} />

        <OpenEndedConstraints question={question} />

        <Concepts question={question} questions={questionsCurrent} />
        <DisplayLogic
          monadicBlocks={monadicBlocks}
          question={questionsInitial[question.id]}
          questionsCurrent={questionsCurrent}
          questionsInitial={questionsInitial}
        />
        <QuotasOptions question={question} />
        <CarryForward question={question} questions={questionsCurrent} />
      </div>
    </div>
  )
}
