import { SVGAttributes } from 'react'

// This is a list of icons available in our public/sprite.svg file. Each string
// here corresponds to a symbol id in that file.
type IconID =
  | 'arrow-narrow-left'
  | 'arrow-narrow-right'
  | 'check'
  | 'chevron-down'
  | 'chevron-right'
  | 'dots-horizontal'
  | 'stars'
  | 'survey-closed'
  | 'survey-complete'
  | 'survey-disqualified'
  | 'survey-error'
  | 'survey-loading'
  | 'x-close'
  | 'zoom-in'

const Icon = ({
  id,
  ...rest
}: Omit<SVGAttributes<SVGElement>, 'className'> & { id: IconID }) => {
  return (
    <svg {...rest} className="h-full w-full">
      <use href={`/sprite.svg#${id}`} />
    </svg>
  )
}

export default Icon
