import { compact, size } from 'lodash-es'

import {
  buildConceptDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildTestLabel, hasFeature } from './questions'
import { Concept, getConcepts } from './concepts'
import { getEnvVar } from './env'
import { getRequireViewConceptError } from './requireViewing'
import { isScaleOption } from './scale'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'

export interface Option {
  id: string
  labels: string[]
  title: string
  values: number[]
}

export type Question = {
  concepts: Concept[]
  constraints: {
    requireConceptView: boolean
  }
  directions: string
  features: {
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    pipeConcept: PipeConceptFeature | undefined
  }
  id: string
  labels: string[]
  options: Option[]
  response: Record<string, number>
  testing: { label: string }
  title: string
  type: 'bipolar'
}

export function canContinue({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  return (
    size(curResponse) === question.options.length &&
    !getRequireViewConceptError({ question })
  )
}

export function getQuestion({
  id,
  question,
}: {
  id: string
  question: QuestionAPI
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      requireConceptView: hasFeature({ code: 'VAL02', question }),
    },
    directions: question.description,
    features: {
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
    },
    id,
    labels: [
      'Agree with A much more',
      'Agree with A somewhat more',
      'Agree with B somewhat more',
      'Agree with B much more',
    ],
    options: compact(
      question.options.map((option, i) => {
        // The currently configured bipolar questions are configured as scales.
        // Scales allowed the closest configuration to what a bipolar question should be.
        if (!isScaleOption(option) || option.step <= 0) {
          return
        }

        const { high, highLabel, low, lowLabel, step } = option
        const values: Question['options'][number]['values'] = []

        for (let i = low; i <= high; i += step) {
          values.push(i)
        }

        return {
          id: `${option.id}`,
          labels: [lowLabel, highLabel] as const,
          title: `Option ${i + 1}`,
          values,
        }
      }),
    ),
    response: {},
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'bipolar' as const,
  } satisfies Question
}

export function isBipolarDisabled({ question }: { question: Question }) {
  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  return false
}

/**
 * We currently hack specific question IDs (configured as scales) to be bipolar questions
 * since we don't yet have support for a "bipolar" question type in the API. These are configured
 * via the BIPOLAR_QUESTION_IDS env var. We're implementing that hack in this new codebase so we
 * can fully transition off of the old survey-response application.
 * See https://linear.app/glass-tech/issue/GLA-732/create-bipolar-question-type.
 */
export function isBipolarQuestion(question: QuestionAPI) {
  return getEnvVar('BIPOLAR_QUESTION_IDS').includes(question.id)
}
