import { clsx } from 'clsx'
import { ReactNode } from 'react'

import ConceptContainer from './ConceptContainer'
import QuestionDirections from './QuestionDirections'
import QuestionTitle from './QuestionTitle'

/**
 * The base container for a question. There are common elements of many of our questions such as:
 * a title, optional directions or concept, and the question content itself.
 */
const Question = ({
  children,
  concept,
  directions,
  title,
}: {
  children?: ReactNode
  concept?: ReactNode
  directions?: string
  title: string
}) => {
  return (
    <div className="space-y-8">
      <QuestionTitle>{title}</QuestionTitle>

      {concept || directions || children ? (
        <div className="space-y-6">
          {concept && (
            <div
              className={clsx({
                ' border-b-2 border-r-gray-700 pb-6': directions || children,
              })}
            >
              <ConceptContainer>{concept}</ConceptContainer>
            </div>
          )}

          {directions && <QuestionDirections>{directions}</QuestionDirections>}

          {children}
        </div>
      ) : null}
    </div>
  )
}

export default Question
