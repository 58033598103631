import { ReactNode } from 'react'

const LogicSeparator = ({ children }: { children: ReactNode }) => {
  return (
    <p className="flex items-center after:block after:h-px after:w-full after:bg-gray-300">
      <span className="mr-4 w-8 rounded border border-gray-400 px-1 py-0.5 text-center text-[8px] font-semibold uppercase text-gray-400">
        {children}
      </span>
    </p>
  )
}

export default LogicSeparator
