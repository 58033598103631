import { clsx } from 'clsx'
import ReactSlider from 'react-slider'
import { useRef } from 'react'

/**
 * A slider has a defined height so there's room for the display of the current value that
 * floats above the thumb after moved and allows the user not to have to click directly on
 * the track to move the handle.
 */
const Slider = ({
  curValue,
  disabled = false,
  formatCurrentValue,
  inverted = false,
  max,
  min,
  initialPosition = min,
  onHasSettled,
  step,
  title,
}: {
  curValue: number | undefined
  disabled?: boolean
  formatCurrentValue?(value: number): string
  inverted?: boolean
  max: number
  min: number
  initialPosition?: number
  onHasSettled(value: number): void
  step: number
  title: string
}) => {
  const hasMoved = useRef(curValue !== undefined)

  return (
    <ReactSlider
      ariaLabel={title}
      className="h-16 w-full md:h-20"
      defaultValue={curValue === undefined ? initialPosition : curValue}
      disabled={disabled}
      invert={inverted}
      max={max}
      min={min}
      onAfterChange={(value) => {
        onHasSettled(value)
      }}
      onChange={() => {
        hasMoved.current = true
      }}
      renderThumb={(opts, { valueNow }) => {
        return (
          <div
            {...opts}
            className={clsx(
              'relative top-1/2 h-6 w-6 -translate-y-1/2 rounded-full border bg-white md:h-7 md:w-7',
              {
                'border-gray-4': disabled,
                'border-primary-600 shadow-md': !disabled && hasMoved.current,
                'border-gray-300 shadow-sm': disabled && !hasMoved.current,
              },
            )}
          >
            {hasMoved.current ? (
              <div className="absolute -top-1 left-1/2 -translate-x-1/2 -translate-y-full rounded-lg bg-gray-900 px-3 py-1.5 text-xs font-semibold text-white">
                {formatCurrentValue ? formatCurrentValue(valueNow) : valueNow}
              </div>
            ) : null}
          </div>
        )
      }}
      renderTrack={(props, state) => {
        return (
          <div
            {...props}
            className={clsx('top-1/2 h-2 -translate-y-1/2 rounded-full', {
              'bg-gray-4': disabled,
              'bg-green-2': !disabled && state.index === 0 && hasMoved.current,
              'bg-gray-300':
                !disabled && (state.index > 0 || !hasMoved.current),
            })}
          />
        )
      }}
      step={step}
    />
  )
}

export default Slider
