import { lazy, Suspense, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import UnexpectedErrorPage from 'routes/UnexpectedErrorPage'

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
)

const App = () => {
  const [showDevtools, setShowDevtools] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old)
  }, [])

  return (
    // We need the Sentry error boundary here because otherwise React Router catches
    // errors and they never make it to Sentry.
    <Sentry.ErrorBoundary fallback={<UnexpectedErrorPage />}>
      <main className="h-full">
        <Outlet />
      </main>

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </Sentry.ErrorBoundary>
  )
}

export default App
