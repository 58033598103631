import { ReactNode } from 'react'

import LogicSeparator from './LogicSeparator'

const LogicGroupWithLine = ({
  children,
  isLast,
  logic,
}: {
  children: ReactNode
  isLast: boolean
  logic: 'and' | 'or'
}) => {
  return (
    <LogicSeparatedGroup isLast={isLast} logic={logic}>
      <div className="relative ml-4 space-y-2 pl-4">
        <div className="absolute left-0 top-0 h-full w-px bg-gray-300" />

        {children}
      </div>
    </LogicSeparatedGroup>
  )
}

export default LogicGroupWithLine

export const LogicSeparatedGroup = ({
  children,
  isLast,
  logic,
}: {
  children: ReactNode
  isLast?: boolean
  logic: 'and' | 'or'
}) => {
  return (
    <div className="space-y-2">
      {children}

      {!isLast && <LogicSeparator>{logic}</LogicSeparator>}
    </div>
  )
}
