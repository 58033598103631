import { compact, orderBy } from 'lodash-es'

import {
  AnswerGaborGranger,
  AnswerIdeaPresenter,
  AnswerMatrix,
  AnswerMultipleChoice,
  AnswerOpenEnded,
  AnswerRanking,
  AnswerScale,
} from 'types/glass-api/domainModels'
import { getIDParts, Question } from './questions'

/**
 * Maps the respondent's choices to the format expected by the API. The API endpoint for answer
 * submission is an older endpoint and has been expanded as new question types have been created.
 * When we rewrote the survey-response application, we didn't have bandwidth for also creating a
 * new API endpoint so we are mapping our client-side model back into a format expected by the API.
 */
export function getAnswersToSubmitToAPI({
  questions,
}: {
  includeOptionsSeenNew?: boolean
  questions: { monadicConceptID?: number; question: Question }[]
}) {
  return questions.map(({ monadicConceptID, question }) => {
    const { id, response, type } = question
    const [idStr] = getIDParts({ id })
    const apiID = Number(idStr)

    let answer:
      | AnswerGaborGranger
      | AnswerIdeaPresenter
      | AnswerMatrix
      | AnswerMultipleChoice
      | AnswerOpenEnded
      | AnswerRanking
      | AnswerScale

    if (type === 'gaborGranger') {
      const minSeeking = question.objective === 'MIN_SEEKING'

      const yesResponses = response.filter(
        ({ willingToAccept }) => willingToAccept === 'yes',
      )
      const highestYesResponse = orderBy(
        yesResponses,
        (r) => r.value.toNumber(),
        minSeeking ? 'asc' : 'desc',
      )[0]

      answer = {
        id: apiID,
        options: [
          {
            // The highest "yes" response is the important piece of information here since values
            // below this value can be inferred as acceptible to the respondent (i.e. if I'm willing
            // to pay $10, I'm also willing to pay $5).
            scaleVal: highestYesResponse
              ? highestYesResponse.value.toNumber()
              : 0,
          },
        ],
        type,
      } satisfies AnswerGaborGranger
    } else if (type === 'ideaPresenter') {
      const responseOption: AnswerIdeaPresenter['options'][number] = {
        optionId: question.legacyOptionID,
      }

      // This addition of concept ID for multiple choice questions is to support Idea Presenter
      // questions where we want to store the concept seen. This is really a hack due to our
      // API structure since Idea Presenter questions are not their own type - they're just
      // multiple choice questions with multiple concepts. Once we've moved to a v2 API,
      // this entire function will be deleted so not too worried about its quirks right now.
      if (question.concepts.length > 0) {
        responseOption.conceptId = Number(question.concepts[0].id)
      }

      answer = {
        id: apiID,
        options: [responseOption],
        type,
      } satisfies AnswerIdeaPresenter
    } else if (type === 'matrix') {
      answer = {
        id: apiID,
        options: Object.entries(response).map(
          ([statementID, selectedOptions]) => {
            const freeTextForStatement =
              question.responseFreeText[statementID] ?? {}

            return {
              matrixOptions: compact(
                question.options.map((option) => {
                  if (selectedOptions.has(option.id)) {
                    return {
                      freeText: freeTextForStatement[option.id] ?? '',
                      id: Number(option.id),
                    }
                  }
                }),
              ),
              optionId: Number(statementID),
            }
          },
        ),
        type,
      } satisfies AnswerMatrix

      const optionsSeen = question.statements.map(({ id }) => Number(id))
      if (question.features.displayXOfY) {
        answer.optionsSeen = optionsSeen
      }
      answer.optionsSeenNew = optionsSeen
    } else if (type === 'openEnded') {
      answer = {
        freeTextResponse: response,
        id: apiID,
        options: [],
        type,
      } satisfies AnswerOpenEnded
    } else if (type === 'multipleChoice') {
      const responseFreeText = question.responseFreeText
      const options: AnswerMultipleChoice['options'] = []

      response.forEach((optionID) => {
        const responseOption: AnswerMultipleChoice['options'][number] = {
          optionId: Number(optionID),
        }

        const freeTextResponse = responseFreeText[optionID]
        if (freeTextResponse) {
          responseOption.freeTextResponse = freeTextResponse
        }

        options.push(responseOption)
      })

      answer = {
        id: apiID,
        options,
        type,
      } satisfies AnswerMultipleChoice

      const optionsSeen = question.options.map(({ id }) => Number(id))
      if (question.features.displayXOfY) {
        answer.optionsSeen = optionsSeen
      }
      answer.optionsSeenNew = optionsSeen
    } else if (type === 'ranking') {
      answer = {
        id: apiID,
        options: response.map((optionID, i) => {
          return {
            optionId: Number(optionID),
            rank: i + 1,
          }
        }),
        type,
      } satisfies AnswerRanking

      const optionsSeen = question.options.map(({ id }) => Number(id))
      if (question.features.displayXOfY) {
        answer.optionsSeen = optionsSeen
      }
      answer.optionsSeenNew = optionsSeen
    }
    // Bipolar questions are currently hacked scale questions, so submission to the API is in
    // the same format.
    else if (type === 'bipolar' || type === 'scale') {
      answer = {
        id: apiID,
        options: Object.entries(response).map(([optionID, value]) => {
          return { optionId: Number(optionID), scaleVal: value }
        }),
        type: 'scale',
      } satisfies AnswerScale

      const optionsSeen =
        type === 'bipolar'
          ? question.options.map(({ id }) => Number(id))
          : question.scales.map(({ id }) => Number(id))

      if (type === 'scale') {
        if (question.features.displayXOfY) {
          answer.optionsSeen = optionsSeen
        }
      }

      answer.optionsSeenNew = optionsSeen
    } else if (type === 'unaidedAwareness') {
      const options: AnswerMultipleChoice['options'] = []

      response.forEach((freeTextResponse, i) => {
        if (freeTextResponse.trim()) {
          options.push({
            freeTextResponse,
            optionId: Number(question.legacyOptionIDs[i]),
          })
        }
      })

      answer = {
        id: apiID,
        options,
        type: 'multipleChoice',
      } satisfies AnswerMultipleChoice
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The thrown error is to protect against when we add a new question choice type and forget to add it here.
      // @ts-expect-error
      throw new Error(`Unsupported question choice type: ${question.type}`)
    }

    if (monadicConceptID) {
      answer.monadicConceptId = monadicConceptID
    }

    return answer
  })
}
