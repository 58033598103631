import {
  buildConceptDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildTestLabel, hasFeature } from './questions'
import { Concept, getConcepts } from './concepts'
import { getRequireViewConceptError } from './requireViewing'
import { isMultipleResponseQuestion } from './multipleChoice'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'

export type Question = {
  concepts: Concept[]
  constraints: {
    requireConceptView: boolean
  }
  directions: string
  features: {
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    pipeConcept: PipeConceptFeature | undefined
  }
  id: string
  // A list of option IDs to be used for submitting the response to the API. Under the hood,
  // this question type is currently a multiple choice question, and the API requires option
  // IDs to be submitted in the response. When we actually add an unaidedAwareness question
  // in the API, this can go away.
  legacyOptionIDs: string[]
  // This is a list of free-text responses from the respondent.
  response: string[]
  testing: { label: string }
  title: string
  type: 'unaidedAwareness'
}

export function canContinue({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  const textboxesWithResponses = curResponse.filter(
    (response) => !!response.trim(),
  )

  return (
    textboxesWithResponses.length > 0 &&
    !getRequireViewConceptError({ question })
  )
}

export function getQuestion({
  id,
  question,
}: {
  id: string
  question: QuestionAPI
}) {
  return {
    concepts: getConcepts({ question }),
    constraints: {
      requireConceptView: hasFeature({ code: 'VAL02', question }),
    },
    directions: question.description,
    features: {
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
    },
    id,
    legacyOptionIDs: question.options.map((option) => `${option.id}`),
    response: [],
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'unaidedAwareness' as const,
  } satisfies Question
}

export function isTextboxDisabled({ question }: { question: Question }) {
  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  return false
}

/**
 * We're currently hacking the multiple choice question to support unaided awareness questions
 * because we don't yet have API support for a new question type. At some point, this hack will
 * go away. See https://linear.app/glass-tech/issue/GLA-740/create-unaided-awareness-question-type
 * for our eventual back-end work to support a real unaided awareness question type.
 */
export function isUnaidedAwarenessQuestion(question: QuestionAPI) {
  const areAllOptionsFreeText =
    question.options.length > 0 &&
    question.options.every((option) => option.isFreeTextOption)

  // Not multiple response, all options are free text, and the question doesn't
  // have a "required sum" constraint, carry forward, or display X of Y features.
  return (
    isMultipleResponseQuestion(question) &&
    areAllOptionsFreeText &&
    !hasFeature({ code: 'SUM01', question }) &&
    !hasFeature({ code: 'COD01', question }) &&
    !hasFeature({ code: 'COS01', question }) &&
    !hasFeature({ code: 'CON01', question }) &&
    !hasFeature({ code: 'OPBLCK01', question })
  )
}
