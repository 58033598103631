import { AdvancedImage } from '@cloudinary/react'
import { clsx } from 'clsx'
import { ComponentProps } from 'react'
import { RadioGroup } from '@headlessui/react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  canContinue,
  getFormattedScaleValue,
  getInitialSliderPosition,
  isScaleDisabled,
  Question as QuestionType,
  Scale,
} from 'utils/scale'
import { getImageFromPublicID, Image } from 'utils/media'
import { useConcept } from 'hooks/concepts'
import { useVariantByScreenSize } from 'hooks/variantByScreenSize'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import ImageZoomControls from './ImageZoomControls'
import Question from './Question'
import RequiredSumFloatingDisplay from './RequiredSumFloatingDisplay'
import Slider from './Slider'

interface ScaleForm {
  response: Record<string, number>
}

const ScaleQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onViewedConcept,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(opts: Pick<QuestionType, 'response'>): void
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
}) => {
  const { handleSubmit, setValue, watch } = useForm<ScaleForm>({
    defaultValues: {
      response: question.response,
    },
  })

  const curResponse = watch('response')

  /**
   * Our displayed options for scales aren't really form fields - we currently use buttons. I want to
   * display them as radio groups eventually, but that will be part of a larger usability refresh.
   * For now, we manually update the response value when an option is clicked.
   */
  function onSelectValueForScale(optionID: string, value: number) {
    setValue('response', { ...curResponse, [optionID]: value })
  }

  const onSubmit: SubmitHandler<ScaleForm> = (data) => {
    onCompletedQuestion({ response: data.response })
  }

  const isDisabled = isScaleDisabled({ question })

  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        <RequiredSumFloatingDisplay
          curResponse={curResponse}
          sum={question.constraints.sum}
        />

        <div className="divide-y">
          {question.scales.map((scale, i) => {
            const ScaleComponent =
              scale.displayType === 'slider' ? ScaleSlider : ScaleRadioButtons

            return (
              <div key={scale.id} className={clsx('py-8', { 'pt-0': i === 0 })}>
                <div className="space-y-4">
                  {scale.image ? (
                    <ScaleImage
                      image={scale.image}
                      title={scale.features.hideTitle ? '' : scale.title}
                    />
                  ) : (
                    <h2>{scale.title}</h2>
                  )}

                  <ScaleComponent
                    curResponse={curResponse}
                    disabled={isDisabled}
                    inverted={question.features.inverted}
                    onSelectValueForScale={onSelectValueForScale}
                    scale={scale}
                  />
                </div>
              </div>
            )
          })}
        </div>
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ curResponse, question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default ScaleQuestion

const ScaleImage = ({ image, title }: { image: Image; title: string }) => {
  const cldImg = getImageFromPublicID({ publicID: image.publicID })

  return (
    <div className="flex flex-col items-center space-y-1">
      <div className="relative h-32 max-w-[50%] md:max-w-full">
        <AdvancedImage
          alt={image.alt}
          className="h-full object-contain"
          cldImg={cldImg}
        />

        <ImageZoomControls alt={image.alt} image={cldImg} />
      </div>

      {title && <p>{title}</p>}
    </div>
  )
}

const ScaleLabels = ({
  inverted,
  labels,
}: {
  inverted: boolean
  labels: [string, string, string]
}) => {
  const [left, center, right] = labels

  return (
    <div className="grid grid-cols-3 gap-x-12 text-xs">
      <span>{inverted ? right : left}</span>
      <span className="text-center">{center}</span>
      <span className="text-right">{inverted ? left : right}</span>
    </div>
  )
}

type ScaleProps = {
  curResponse: ScaleForm['response']
  disabled: boolean
  inverted: boolean
  onSelectValueForScale(optionID: string, value: number): void
  scale: Scale
}

const ScaleSlider = ({
  curResponse,
  disabled,
  inverted,
  onSelectValueForScale,
  scale,
}: ScaleProps) => {
  const { id, labels, range, title, unit } = scale
  const { max, min, step } = range

  return (
    <div>
      <div className="relative z-10">
        <Slider
          curValue={curResponse[id]}
          disabled={disabled}
          formatCurrentValue={(value) =>
            getFormattedScaleValue({ unit, value })
          }
          initialPosition={getInitialSliderPosition(range)}
          inverted={inverted}
          max={max}
          min={min}
          onHasSettled={(value) => {
            onSelectValueForScale(id, value)
          }}
          step={step}
          title={title}
        />
      </div>

      <div className="-mt-5">
        <ScaleLabels inverted={inverted} labels={labels} />
      </div>
    </div>
  )
}

const ScaleRadioButtons = ({
  curResponse,
  disabled,
  inverted,
  onSelectValueForScale,
  scale,
}: ScaleProps) => {
  const { id, labels, range, unit } = scale
  const { max, min, step } = range

  const buttons: number[] = []

  if (step > 0) {
    for (let i = min; i <= max; i += step) {
      buttons.push(i)
    }
  }

  const textSize = useVariantByScreenSize<'abbreviated' | 'full'>('full', {
    md: 'abbreviated',
  })
  const useAbbreviatedText = textSize === 'abbreviated' && buttons.length > 7

  return (
    <div className="overflow-auto">
      {/*
       * This inline-flex container ensures that the labels will grow to be the
       * full-width of the options. So on smaller screen sizes when you have to
       * scroll, the right-most label will be all the way at the right, including
       * the scroll.
       */}
      <div className="inline-flex min-w-full flex-col space-y-2">
        <RadioGroup
          className="flex space-x-1"
          disabled={disabled}
          onChange={(value) => {
            onSelectValueForScale(id, value)
          }}
          value={curResponse[id] ?? ''}
        >
          {(inverted ? buttons.slice().reverse() : buttons).map((value) => {
            return (
              <RadioGroup.Option
                key={value}
                className="h-full w-full min-w-16 flex-1 cursor-default rounded border border-gray-4 bg-white py-4 text-center text-sm ui-checked:border-green-2 ui-checked:bg-green-2 ui-checked:font-medium ui-checked:text-white ui-disabled:text-gray-300 md:min-w-5 md:text-xs"
                value={value}
              >
                {useAbbreviatedText
                  ? `${value}`
                  : getFormattedScaleValue({ unit, value })}
              </RadioGroup.Option>
            )
          })}
        </RadioGroup>

        <ScaleLabels inverted={inverted} labels={labels} />
      </div>
    </div>
  )
}
