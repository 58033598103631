import { Decimal } from 'decimal.js-light'

import {
  buildConceptDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildTestLabel, hasFeature } from './questions'
import { Concept, getConcepts } from './concepts'
import { formatDollars, formatPercentage } from './formatting'
import {
  GaborGrangerFormat,
  GaborGrangerObjective,
  Question as QuestionAPI,
} from 'types/glass-api/domainModels'
import { getRequireViewConceptError } from './requireViewing'
import { randomWithIncrement } from './general'

export type ValueOptionResponse = {
  value: Decimal
  willingToAccept: 'no' | 'yes'
}

export type Question = {
  concepts: Concept[]
  constraints: {
    requireConceptView: boolean
  }
  directions: string
  display: {
    format: GaborGrangerFormat
    formatCustomText: string | null
    unitDecimals: number
  }
  features: {
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    pipeConcept: PipeConceptFeature | undefined
  }
  id: string
  objective: GaborGrangerObjective
  range: { increment: Decimal; max: Decimal; min: Decimal }
  response: ValueOptionResponse[]
  testing: { label: string }
  title: string
  type: 'gaborGranger'
}

export function canContinue({
  hasFinalSelection,
  question,
}: {
  hasFinalSelection: boolean
  question: Question
}) {
  return hasFinalSelection && !getRequireViewConceptError({ question })
}

export function getFormattedValue({
  customText,
  format,
  unitDecimals,
  value,
}: {
  customText: string | null
  format: GaborGrangerFormat
  unitDecimals: number
  value: Decimal
}) {
  if (format === 'CUSTOM') {
    return `${value.toFixed(unitDecimals)} ${customText}`
  } else if (format === 'DOLLARS') {
    return `${formatDollars(value.toNumber(), { decimals: unitDecimals })}`
  } else if (format === 'PERCENT') {
    return `${formatPercentage(value, { decimals: unitDecimals })}`
  }

  throw new Error(`Unsupported Gabor-Granger format: ${format}`)
}

export function getNextValuePrompt({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  const objective = question.objective
  let max = question.range.max
  let min = question.range.min
  const increment = question.range.increment

  if (curResponse.length > 0) {
    curResponse.forEach(({ value, willingToAccept }) => {
      if (
        (objective === 'MIN_SEEKING'
          ? willingToAccept === 'yes'
          : willingToAccept === 'no') &&
        value.lte(max)
      ) {
        max = value.minus(increment)
      } else if (
        (objective === 'MIN_SEEKING'
          ? willingToAccept === 'no'
          : willingToAccept === 'yes') &&
        value.gte(min)
      ) {
        min = value.plus(increment)
      }
    })
  }

  return max.lt(min) || min.gt(max)
    ? undefined
    : randomWithIncrement({ increment, max, min })
}

export function getQuestion({
  id,
  question,
}: {
  id: string
  question: QuestionAPI
}) {
  if (!question.gaborGrangerSettings) {
    throw new Error('Gabor-Granger question has no settings')
  }

  return {
    concepts: getConcepts({ question }),
    constraints: {
      requireConceptView: hasFeature({ code: 'VAL02', question }),
    },
    directions: question.description,
    display: {
      format: question.gaborGrangerSettings.format,
      formatCustomText: question.gaborGrangerSettings.formatCustomText,
      unitDecimals: question.gaborGrangerSettings.unitDecimals,
    },
    features: {
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
    },
    id,
    objective: question.gaborGrangerSettings.objective,
    range: {
      increment: new Decimal(question.gaborGrangerSettings.increment),
      max: new Decimal(question.gaborGrangerSettings.max),
      min: new Decimal(question.gaborGrangerSettings.min),
    },
    response: [],
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'gaborGranger' as const,
  } satisfies Question
}

/**
 * Adds or subtracts the provided increment to the current value depending on whether
 * the current value is greater than or less than the target value.
 */
export function getValueTowardsTargetByIncrement({
  currentValue,
  increment,
  targetValue,
}: {
  currentValue: Decimal
  increment: Decimal
  targetValue: Decimal
}) {
  if (currentValue.gt(targetValue)) {
    const newValue = currentValue.minus(increment)

    return newValue.lt(targetValue) ? targetValue : newValue
  }

  const newValue = currentValue.plus(increment)

  return newValue.gt(targetValue) ? targetValue : newValue
}

export function isOptionDisabled({
  hasFinalSelection,
  question,
}: {
  hasFinalSelection: boolean
  question: Question
}) {
  if (hasFinalSelection) {
    return true
  }

  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  return false
}
