import { ReactNode } from 'react'

const FeatureDisplay = ({
  children,
  title,
}: {
  children: ReactNode
  title: string
}) => {
  return (
    <div>
      <div className="border-y border-gray-300 px-6 py-2 text-xs font-bold uppercase text-gray-400">
        {title}
      </div>
      <div className="px-6 py-2 text-sm">{children}</div>
    </div>
  )
}

export default FeatureDisplay
