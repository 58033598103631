import { Question as QuestionMatrix } from './matrix'
import { Question as QuestionMultipleChoice } from './multipleChoice'
import { Question as QuestionRanking } from './ranking'

export function adjustOptionsResponse({
  curResponse,
  optionID,
  question,
}: {
  curResponse: Set<string>
  optionID: string
  question: QuestionMultipleChoice | QuestionMatrix
}) {
  let newResponse = new Set(curResponse)

  if (question.constraints.singleChoice) {
    newResponse = new Set<string>()
    newResponse.add(optionID)
  } else {
    if (newResponse.has(optionID)) {
      newResponse.delete(optionID)
    } else {
      // If an exclusive option was selected, we then treat this question like a single-select
      // and clear all other newResponse.
      if (question.constraints.exclusiveOptionIDs.has(optionID)) {
        newResponse = new Set<string>()
      }

      newResponse.add(optionID)
    }
  }

  return newResponse
}

export function adjustRankResponse({
  curResponse,
  optionID,
  question,
}: {
  curResponse: string[]
  optionID: string
  question: QuestionRanking
}) {
  let newResponse = [...curResponse]
  const indexOfOption = newResponse.indexOf(optionID)

  if (indexOfOption === -1) {
    // If an exclusive option was ranked, we don't want any other options to be ranked
    // so we clear any current response.
    if (question.constraints.exclusiveOptionIDs.has(optionID)) {
      newResponse = []
    }

    newResponse.push(optionID)
  } else {
    newResponse = newResponse.slice(0, indexOfOption)
  }

  return newResponse
}
