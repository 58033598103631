import Icon from './Icon'
import OverlayFullScreen from './OverlayFullScreen'

const OverlayDisqualified = ({
  explanation,
  onClose,
  subtitle,
  title,
}: {
  explanation?: string
  onClose?(): void
  subtitle: string
  title: string
}) => {
  return (
    <OverlayFullScreen onClose={onClose}>
      <div className="flex flex-col items-center space-y-6 text-center">
        <div className="w-36">
          <Icon id="survey-disqualified" />
        </div>
        <p className="text-4xl font-semibold">{title}</p>
        {subtitle && <p>{subtitle}</p>}

        {explanation && <p>{explanation}</p>}
      </div>
    </OverlayFullScreen>
  )
}

export default OverlayDisqualified
