import {
  getIterationFromID,
  getPreviousQuestionForIteration,
  Question,
} from 'utils/questions'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const CarryForward = ({
  question,
  questions,
}: {
  question: Question
  questions: Record<string, Question>
}) => {
  if (
    !('carryForward' in question.features) ||
    !question.features.carryForward
  ) {
    return null
  }

  const { carryForward } = question.features
  const carryForwardQuestion = getPreviousQuestionForIteration({
    iteration: getIterationFromID(question.id),
    previousQuestionID: carryForward.questionID,
    questions,
  })

  let carryForwardTypeName = 'Displayed'
  if (
    carryForward.type.name === 'selected' ||
    carryForward.type.name === 'notSelected'
  ) {
    carryForwardTypeName =
      carryForward.type.name === 'selected' ? 'Selected' : 'Not Selected'

    const optionIDSelection =
      'optionID' in carryForward.type && carryForward.type.optionID
    if (optionIDSelection && 'options' in carryForwardQuestion) {
      const carryForwardOptionSelection = carryForwardQuestion.options.find(
        (option) => option.id === optionIDSelection,
      )

      carryForwardTypeName = `${carryForwardTypeName} "${carryForwardOptionSelection?.title}"`
    }
  }

  return (
    <FeatureDisplay title="Carry Forward">
      <ListDiscIndented>
        <li>
          {carryForwardTypeName} from {carryForwardQuestion.testing.label}
        </li>
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default CarryForward
