import { AnimatePresence, motion, useReducedMotion } from 'framer-motion'
import { ReactNode, useEffect, useRef, useState } from 'react'

/**
 * Inspired by https://github.com/framer/motion/discussions/1884.
 */
const AnimateChangeInHeight = ({
  children,
  open,
}: {
  children: ReactNode
  open: boolean
}) => {
  const shouldReduceMotion = useReducedMotion()

  const containerRef = useRef<HTMLDivElement | null>(null)
  const [openHeight, setOpenHeight] = useState<number | 'auto'>('auto')

  // Measures the open height of the container so we can animate to this value
  // when opened.
  useEffect(() => {
    if (!containerRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (open) {
        setOpenHeight(entries[0].contentRect.height)
      }
    })

    resizeObserver.observe(containerRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [open])

  if (shouldReduceMotion) {
    return open ? <div>{children}</div> : null
  }

  return (
    <AnimatePresence initial={false}>
      {open && (
        <motion.div
          animate={{ height: openHeight, opacity: 1 }}
          className="overflow-hidden"
          exit={{ height: 0, opacity: 0 }}
          initial={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div ref={containerRef}>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default AnimateChangeInHeight
