import axios from 'axios'

import { getAPIUrl } from './baseAPI'
import { User } from 'types/glass-api/domainModels'

export interface CreateUser {
  data: { surveyHash: string } & Partial<{
    lucidRid: string | null | undefined
    lucidSurveyId: string | null | undefined
    respondentIncentive: number | null
    Sid: string
  }>
}

export const CREATE_USER = () => ({
  path: '/users',
  version: 'v1' as const,
})

export async function createUser({ data }: CreateUser) {
  const response = await axios.post<User>(getAPIUrl(CREATE_USER()), {
    ...data,
    // See https://linear.app/glass-tech/issue/GLA-582/capture-and-store-the-source-of-a-respondent.
    // We're doing this as we transition to the new survey-response application so we can AB test and
    // ensure the new application is saving data in the same format (or better) than the old application.
    // This allows us to segment the users of the new app vs the old one.
    isNewSurveyResponseApplication: true,
  })

  // Creating a user returns an authorization header that is needed for future
  // authenticated requests for the user. I wish the backend just used the set-cookie
  // header.
  if ('authorization' in response.headers) {
    axios.defaults.headers.Authorization = response.headers.authorization
  }

  return response.data
}
