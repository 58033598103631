import { compact, shuffle } from 'lodash-es'

import {
  buildBlockDisplayLogic,
  DisplayLogicByResource,
  filterBlocksByDisplayLogic,
} from './displayLogic'
import {
  buildBlockDisplayXOfYFeature,
  displayXOfYBlocks,
  DisplayXOfYFeature,
} from './displayXOfY'
import {
  getDisplayError,
  getIDWithIteration,
  getIterationFromID,
  Question,
} from './questions'
import {
  QuestionBlock,
  QuestionBlockFeatureCode,
  QuestionBlockFeatureCodeWithNumberRange,
} from 'types/glass-api/domainModels'
import { randomizeBlocks } from './randomize'
import { selectRandomConcept } from './concepts'

export interface BlockSet {
  blocks: {
    id: string
    questionIDs: string[]
    title: string
  }[]
  features: {
    blockDisplayLogic: DisplayLogicByResource
    displayXOfY: DisplayXOfYFeature | undefined
    randomize: boolean
  }
  id: string
}

export interface BlockSetMonadic {
  blocks: {
    id: string
    questionIDs: string[]
    title: string
  }[]
  id: string
}

export function applyBlockFeatures({
  blockSet,
  monadicBlocks,
  questions,
}: {
  blockSet: BlockSet
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
}) {
  const blockSetWithFeatures = { ...blockSet }

  blockSetWithFeatures.blocks = filterBlocksByDisplayLogic({
    blockSet: blockSetWithFeatures,
    monadicBlocks,
    questions,
  })
  blockSetWithFeatures.blocks = displayXOfYBlocks({
    blockSet: blockSetWithFeatures,
  })
  blockSetWithFeatures.blocks = randomizeBlocks({
    blockSet: blockSetWithFeatures,
  })

  return blockSetWithFeatures
}

export function buildBlockSet({
  questionBlock,
}: {
  questionBlock: QuestionBlock
}) {
  return {
    blocks: questionBlock.children.map((child) => {
      return {
        id: `${child.id}`,
        questionIDs: child.questions.map(({ id }) => `${id}`),
        title: child.title,
      }
    }),
    features: {
      blockDisplayLogic: buildBlockDisplayLogic({ questionBlock }),
      displayXOfY: buildBlockDisplayXOfYFeature({ questionBlock }),
      randomize: questionBlock.isRandomized,
    },
    id: `${questionBlock.id}`,
  } satisfies BlockSet
}

export function buildBlockSetMonadic({
  monadicID,
  iterations,
  questionIDs,
}: {
  monadicID: number
  iterations: number
  questionIDs: number[]
}) {
  const blocks: BlockSetMonadic['blocks'] = []

  for (let i = 0; i < iterations; i++) {
    blocks.push({
      id: getIDWithIteration({ id: monadicID, iteration: `${i}` }),
      questionIDs: questionIDs.map((id) =>
        getIDWithIteration({ id, iteration: `${i}` }),
      ),
      title: `Monadic Block (${i + 1} of ${iterations})`,
    })
  }

  return {
    blocks,
    id: `${monadicID}`,
  } satisfies BlockSetMonadic
}

/**
 * Chooses and sets a concept for the provided next question in a monadic block set based
 * on concepts that have been previously viewed. A concept should be randomly chosen for
 * display but should only be viewed in one iteration. Some concepts can be configured to
 * be "preserved", which means they must be shown in an iteration (not all concepts have to
 * be shown to all respondents - you may have 4 concepts configured but only two iterations
 * for each respondent).
 */
export function chooseMonadicConceptForQuestion({
  blockSetMonadic,
  nextQuestion,
  questions,
}: {
  blockSetMonadic: BlockSetMonadic
  nextQuestion: Question
  questions: Record<string, Question>
}) {
  // We only select a concept based on previously viewed concepts for the
  // first question in an iteration of a monadic block set.
  const isFirstBlockQuestion = !!blockSetMonadic.blocks.find(
    ({ questionIDs }) => questionIDs[0] === nextQuestion.id,
  )
  if (!isFirstBlockQuestion) {
    // If this isn't the first question in a block, we still need to select a concept
    // in case the question has multiple concepts that could be shown (e.g. the question
    // is an idea presenter question nested in a monadic block set).
    return selectRandomConcept({ nextQuestion })
  }

  if (nextQuestion.concepts.length === 0) {
    return nextQuestion
  }

  const nextIteration = getIterationFromID(nextQuestion.id)
  const alreadyViewedConceptIDs = getMonadicViewedConceptIDs({
    blockSetMonadic,
    iteration: nextIteration ? { id: nextIteration, logic: 'lt' } : undefined,
    questions,
  })

  // For monadic blocks, we don't repeat a concept. So we filter out concepts
  // that have already been chosen.
  const notViewedConcepts =
    alreadyViewedConceptIDs.size > 0
      ? nextQuestion.concepts.filter(
          ({ id }) => !alreadyViewedConceptIDs.has(id),
        )
      : nextQuestion.concepts

  // It's possible all the available concepts have been viewed. Some might have been
  // filtered out by display logic and some may have been viewed in previous iterations.
  if (notViewedConcepts.length === 0) {
    return { ...nextQuestion, concepts: [] }
  }

  const randomizedConcepts = shuffle(notViewedConcepts)
  let chosenConcept = randomizedConcepts[0]

  // We need to check to see if we have the same number (or fewer) of preserved
  // concepts and iterations remaining. If so, we need to choose a preserved
  // concept from our randomized list instead of a non-preserved one so we ensure
  // all preserved concepts are shown (but still in a randomized order).
  const currentIteration = getIterationFromID(nextQuestion.id)
  if (currentIteration) {
    const iterationsRemaining =
      blockSetMonadic.blocks.length - Number(currentIteration)

    const numPreservedConcepts = nextQuestion.concepts.reduce(
      (numPreserved, concept) => {
        return concept.features.preserve ? numPreserved + 1 : numPreserved
      },
      0,
    )

    const firstPreservedConcept = randomizedConcepts.find((concept) => {
      return concept.features.preserve
    })

    if (iterationsRemaining <= numPreservedConcepts && firstPreservedConcept) {
      chosenConcept = firstPreservedConcept
    }
  }

  return {
    ...nextQuestion,
    concepts: [chosenConcept],
  } satisfies Question
}

export function getFeature({
  code,
  questionBlock,
}: {
  code: QuestionBlockFeatureCode
  questionBlock: QuestionBlock
}) {
  return questionBlock.questionBlockFeatures.find(({ feature }) => {
    return feature.code === code
  })
}

/**
 * Gets the value from a "number range" question block feature. For example,
 * the display X of Y feature uses the numberRange object but only the start value
 * is meaningful (the start and end should be the same value).
 */
export function getFeatureNumberRangeValue({
  code,
  questionBlock,
}: {
  code: QuestionBlockFeatureCodeWithNumberRange
  questionBlock: QuestionBlock
}) {
  const feature = getFeature({ code, questionBlock })

  return feature && 'numberRange' in feature ? feature.numberRange.start : null
}

/**
 * Returns the shown concept ID for the specified monadic block iteration.
 */
export function getMonadicBlockConceptID({
  block,
  questions,
}: {
  block: BlockSetMonadic['blocks'][number]
  questions: Record<string, Question>
}) {
  // Only the concept of the first question in the monadic block is relevant
  // because those are the monadic concepts.
  const firstQuestionOfBlock = block.questionIDs[0]

  // The first concept is the concept we show to the respondent.
  return questions[firstQuestionOfBlock].concepts[0]?.id
}

/**
 * Returns the monadic block set that contains the specified question.
 */
export function getMonadicBlockForQuestion({
  monadicBlocks,
  questionID,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  questionID: string
}) {
  for (const blockSet of Object.values(monadicBlocks)) {
    for (const block of blockSet.blocks) {
      if (block.questionIDs.includes(questionID)) {
        return blockSet
      }
    }
  }

  return null
}

/**
 * Returns the monadic block iteration when the specified concept was shown.
 */
export function getMonadicBlockIterationForConcept({
  conceptID,
  monadicBlocks,
  questions,
}: {
  conceptID: string
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
}) {
  for (const blockSet of Object.values(monadicBlocks)) {
    for (let i = 0; i < blockSet.blocks.length; i++) {
      const monadicBlockConceptID = getMonadicBlockConceptID({
        block: blockSet.blocks[i],
        questions,
      })

      if (monadicBlockConceptID === conceptID) {
        return `${i}`
      }
    }
  }

  return null
}

/**
 * Gets the question ID for the monadic prompt question for the specified iteration.
 */
export function getMonadicPromptIDForIteration({
  blockSetMonadic,
  iteration,
}: {
  blockSetMonadic: BlockSetMonadic
  iteration: string
}) {
  for (const block of blockSetMonadic.blocks) {
    if (getIterationFromID(block.questionIDs[0]) === iteration) {
      return block.questionIDs[0]
    }
  }

  return null
}

/**
 * Returns the IDs of concepts from a monadic block set that have been viewed.
 * We only return the concept IDs for the first question of each block because this
 * is the question that contains the prompt for the monadic block.
 */
export function getMonadicViewedConceptIDs({
  blockSetMonadic,
  iteration,
  questions,
}: {
  blockSetMonadic: BlockSetMonadic
  iteration?: { id: string; logic: 'eq' | 'lt' | 'lte' }
  questions: Record<string, Question>
}) {
  let blocks = blockSetMonadic.blocks

  if (iteration) {
    // We only want to look at blocks from previous iterations when considering
    // concepts that have been viewed.
    blocks = blocks.filter((_block, blockIteration) => {
      const iterationNum = Number(iteration.id)
      if (iteration.logic === 'eq') {
        return blockIteration === iterationNum
      } else if (iteration.logic === 'lt') {
        return blockIteration < iterationNum
      } else if (iteration.logic === 'lte') {
        return blockIteration <= iterationNum
      }
    })
  }

  return new Set(
    compact(
      blocks.map((block) => {
        return getMonadicBlockConceptID({ block, questions })
      }),
    ),
  )
}

/**
 * Returns a boolean indicating if the prompt for a monadic block has a display error.
 * This is helpful in determining if the monadic block can be shown - you can't ask follow-up
 * questions about a prompt if the prompt itself couldn't be shown.
 */
export function hasMonadicBlockPromptDisplayError({
  block,
  blockSetType,
  monadicBlocks,
  questions,
}: {
  block: BlockSetMonadic['blocks'][number] | undefined
  blockSetType: 'blockSet' | 'blockSetMonadic'
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
}) {
  if (blockSetType !== 'blockSetMonadic') {
    return false
  }

  const blockPromptQuestion = block
    ? questions[block.questionIDs[0]]
    : undefined

  return !!(
    blockPromptQuestion?.type === 'ideaPresenter' &&
    getDisplayError({ monadicBlocks, question: blockPromptQuestion, questions })
  )
}

/**
 * Returns a boolean indicating if the specified question is part of a monadic block set.
 */
export function isMonadicBlockQuestion({
  monadicBlocks,
  questionID,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  questionID: string
}) {
  return !!getMonadicBlockForQuestion({ monadicBlocks, questionID })
}
