import axios from 'axios'

import {
  Disqualification,
  DisqualificationSource,
  DisqualificationTactic,
} from 'types/glass-api/domainModels'
import { getAPIUrl } from './baseAPI'

export interface DisqualifyRespondent {
  data: {
    questionId: number
    source: DisqualificationSource
    surveyHash: string
    tactic: DisqualificationTactic
    waveId: number
  }
  respondentID: number
}

export const DISQUALIFY_RESPONDENT = ({
  respondentID,
}: Pick<DisqualifyRespondent, 'respondentID'>) => ({
  path: `/respondents/${respondentID}:disqualify`,
  version: 'v2' as const,
})

export async function disqualifyRespondent({
  data,
  respondentID,
}: DisqualifyRespondent) {
  return (
    await axios.post<Disqualification>(
      getAPIUrl(DISQUALIFY_RESPONDENT({ respondentID })),
      data,
    )
  ).data
}
