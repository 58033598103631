import invariant from 'tiny-invariant'
import { isArray } from 'lodash-es'

type EnvVars = {
  APP_ENV: 'local' | 'staging' | 'production' | 'test'
  BIPOLAR_QUESTION_IDS: number[]
  CLOUDINARY_CLOUD_NAME: string
  GA_MEASUREMENT_ID: string
  GLASS_API_HOST: string
  MIXPANEL_PROJECT_TOKEN: string
  RESEARCH_DEFENDER_HOST?: string
  SENTRY_DSN?: string
  SENTRY_ORG?: string
  SENTRY_PROJECT?: string
}

const ALLOWED_APP_ENVS = ['local', 'staging', 'production', 'test'] as const

function isAllowedAppEnv(appEnv: string): appEnv is EnvVars['APP_ENV'] {
  return ALLOWED_APP_ENVS.includes(appEnv as EnvVars['APP_ENV'])
}

let ENV_VARS: {
  [key in keyof EnvVars]: EnvVars[key]
}

if (process.env.NODE_ENV === 'test') {
  ENV_VARS = {
    APP_ENV: 'test',
    BIPOLAR_QUESTION_IDS: [123456789],
    CLOUDINARY_CLOUD_NAME: 'dup2wlvb8',
    GA_MEASUREMENT_ID: '',
    GLASS_API_HOST: 'https://glass-api-test',
    MIXPANEL_PROJECT_TOKEN: '',
    RESEARCH_DEFENDER_HOST: 'https://research-defender-test',
    SENTRY_DSN: '',
    SENTRY_ORG: '',
    SENTRY_PROJECT: '',
  }
} else {
  invariant(
    import.meta.env.VITE_APP_ENV &&
      isAllowedAppEnv(import.meta.env.VITE_APP_ENV),
    `APP_ENV must be one of: ${ALLOWED_APP_ENVS.join(', ')}`,
  )
  invariant(
    import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    `VITE_CLOUDINARY_CLOUD_NAME must be set`,
  )
  invariant(
    import.meta.env.VITE_GA_MEASUREMENT_ID,
    'VITE_GA_MEASUREMENT_ID must be set',
  )
  invariant(
    import.meta.env.VITE_GLASS_API_HOST,
    'VITE_GLASS_API_HOST must be set',
  )
  invariant(
    import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN,
    'VITE_MIXPANEL_PROJECT_TOKEN must be set',
  )

  if (import.meta.env.VITE_APP_ENV !== 'local') {
    invariant(import.meta.env.VITE_SENTRY_DSN, 'VITE_SENTRY_DSN must be set')
    invariant(import.meta.env.VITE_SENTRY_ORG, 'VITE_SENTRY_ORG must be set')
    invariant(
      import.meta.env.VITE_SENTRY_PROJECT,
      'VITE_SENTRY_PROJECT must be set',
    )

    // See https://linear.app/glass-tech/issue/GLA-723/make-async-research-defender-call-during-live-survey-taking#comment-041de86d.
    // We currently only require / enable Research Defender in production.
    if (import.meta.env.VITE_APP_ENV === 'production') {
      invariant(
        import.meta.env.VITE_RESEARCH_DEFENDER_HOST,
        'VITE_RESEARCH_DEFENDER_HOST must be set',
      )
    }
  }

  let bipolarQuestionIDs: number[] = import.meta.env.VITE_BIPOLAR_QUESTION_IDS
    ? JSON.parse(import.meta.env.VITE_BIPOLAR_QUESTION_IDS)
    : []
  if (
    !isArray(bipolarQuestionIDs) ||
    typeof bipolarQuestionIDs[0] !== 'number'
  ) {
    bipolarQuestionIDs = []
  }

  ENV_VARS = {
    APP_ENV: import.meta.env.VITE_APP_ENV,
    BIPOLAR_QUESTION_IDS: bipolarQuestionIDs,
    CLOUDINARY_CLOUD_NAME: import.meta.env.VITE_CLOUDINARY_CLOUD_NAME,
    GA_MEASUREMENT_ID: import.meta.env.VITE_GA_MEASUREMENT_ID,
    GLASS_API_HOST: import.meta.env.VITE_GLASS_API_HOST,
    MIXPANEL_PROJECT_TOKEN: import.meta.env.VITE_MIXPANEL_PROJECT_TOKEN,
    RESEARCH_DEFENDER_HOST: import.meta.env.VITE_RESEARCH_DEFENDER_HOST,
    SENTRY_DSN: import.meta.env.VITE_SENTRY_DSN,
    SENTRY_ORG: import.meta.env.VITE_SENTRY_ORG,
    SENTRY_PROJECT: import.meta.env.VITE_SENTRY_PROJECT,
  }
}

export function getEnvVar<EnvVar extends keyof EnvVars>(
  envVar: EnvVar,
): EnvVars[EnvVar] {
  return ENV_VARS[envVar]
}
