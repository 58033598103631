import { clsx } from 'clsx'
import { useState } from 'react'

import { BlockSetMonadic } from 'utils/questionBlocks'
import { getDisplayError, getResponsesArray, Question } from 'utils/questions'

import Dropdown, { DropdownButton, DropdownItem } from 'components/Dropdown'
import Icon from 'components/Icon'
import ListDiscIndented from './ListDiscIndented'

const QuestionSummary = ({
  isCurrent,
  onClick,
  onClickClearResponse,
  monadicBlocks,
  question,
  questionsCurrent,
}: {
  isCurrent: boolean
  monadicBlocks: Record<string, BlockSetMonadic>
  onClick(): void
  onClickClearResponse(opts: {
    clearType: 'current' | 'current-subsequent'
    questionID: string
  }): void
  question: Question
  questionsCurrent: Record<string, Question>
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const responses = getResponsesArray({ question })
  const displayError = getDisplayError({
    monadicBlocks,
    question,
    questions: questionsCurrent,
  })

  let borderColor = 'transparent'
  if (displayError) {
    borderColor = 'blue'
  } else if (isCurrent || responses.length > 0) {
    borderColor = 'green'
  }

  return (
    <div className="group relative">
      <a
        className={clsx('block cursor-pointer border-l-8 py-3.5', {
          'border-blue-200': borderColor === 'blue',
          'border-primary-600/80': borderColor === 'green',
          'bg-gray-100': isCurrent,
          'border-transparent': borderColor === 'transparent',
        })}
        onClick={onClick}
      >
        <p className="pl-2 pr-8 text-xs font-bold uppercase text-gray-400">
          {question.testing.label}
        </p>
        <div className="px-2">
          <p className="text-sm">{question.title}</p>
          {responses.length > 0 && (
            <div className="mt-1 text-sm">
              <ListDiscIndented>
                {responses.map((response, i) => (
                  <li key={i}>{response}</li>
                ))}
              </ListDiscIndented>
            </div>
          )}
          <p className="text-xs font-semibold uppercase text-blue-400">
            {displayError ? displayError : null}
          </p>
        </div>
      </a>

      <div
        className={clsx('absolute right-2 top-2 z-10', {
          flex: isDropdownOpen,
          'hidden group-hover:flex': !isDropdownOpen,
        })}
      >
        <ClearResponsesDropdown
          onClickClearResponse={(clearType) => {
            onClickClearResponse({ clearType, questionID: question.id })
          }}
          onOpenChanged={setIsDropdownOpen}
        />
      </div>
    </div>
  )
}

export default QuestionSummary

const ClearResponsesDropdown = ({
  onClickClearResponse,
  onOpenChanged,
}: {
  onClickClearResponse(clearType: 'current' | 'current-subsequent'): void
  onOpenChanged(isOpen: boolean): void
}) => {
  return (
    <Dropdown
      button={
        <DropdownButton aria-label="Question Options Dropdown">
          <div className="p-1">
            <div aria-hidden="true" className="h-4 w-4">
              <Icon id="dots-horizontal" />
            </div>
          </div>
        </DropdownButton>
      }
      onOpenChanged={onOpenChanged}
      placement="bottom-start"
    >
      <DropdownItem
        onClick={() => {
          onClickClearResponse('current')
        }}
      >
        Clear Response
      </DropdownItem>
      <DropdownItem
        onClick={() => {
          onClickClearResponse('current-subsequent')
        }}
      >
        Clear Response + Subsequent
      </DropdownItem>
    </Dropdown>
  )
}
