import { canContinue, Question as QuestionType } from 'utils/ideaPresenter'
import { ComponentProps } from 'react'
import { useConcept } from 'hooks/concepts'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import Question from './Question'

const IdeaPresenterQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onViewedConcept,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(): void
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
}) => {
  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form
      className="space-y-6"
      onSubmit={(event) => {
        event.preventDefault()

        onCompletedQuestion()
      }}
    >
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      />

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default IdeaPresenterQuestion
