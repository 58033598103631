import { orderBy, shuffle } from 'lodash-es'

import {
  BlockSet,
  getFeatureNumberRangeValue as getBlockFeatureNumberRangeValue,
} from './questionBlocks'
import {
  getFeatureNumberRangeValue as getQuestionFeatureNumberRangeValue,
  Question,
} from './questions'
import {
  Question as QuestionAPI,
  QuestionBlock,
} from 'types/glass-api/domainModels'

export type DisplayXOfYFeature = {
  numToDisplay: number
  preservedResourceIDs: Set<string>
}

/**
 * Converts the display X of Y question block API feature into a format that's more
 * readable and easier to work with.
 */
export function buildBlockDisplayXOfYFeature({
  questionBlock,
}: {
  questionBlock: QuestionBlock
}) {
  // Note: The backend uses the "numberRange" object to store the number of blocks to
  // display for this feature.
  const numToDisplay = getBlockFeatureNumberRangeValue({
    code: 'XOY02',
    questionBlock,
  })
  if (!numToDisplay) {
    return
  }

  return {
    numToDisplay,
    preservedResourceIDs: new Set(),
  } satisfies DisplayXOfYFeature
}

/**
 * Converts the display X of Y API feature into a format that's more readable
 * and easier to work with.
 */
export function buildOptionDisplayXOfYFeature({
  question,
}: {
  question: QuestionAPI
}) {
  // Note: The backend uses the "numberRange" object to store the number of options to
  // display for this feature.
  const numToDisplay = getQuestionFeatureNumberRangeValue({
    code: 'OPBLCK01',
    question,
  })
  if (!numToDisplay) {
    return
  }

  return {
    numToDisplay,
    preservedResourceIDs: new Set(
      question.options
        .filter((option) => option.preserved)
        .map(({ id }) => `${id}`),
    ),
  } satisfies DisplayXOfYFeature
}

/**
 * Returns a copy of the provided blockSet's modified blocks if the display X of Y
 * feature is present. "Display X of Y" will randomly choose X blocks from the set of
 * blocks for the block set.
 */
export function displayXOfYBlocks({ blockSet }: { blockSet: BlockSet }) {
  const { displayXOfY: feature } = blockSet.features
  if (!feature) {
    return blockSet.blocks
  }

  const blockIDsToRemove = getResourceIDsToRemove({
    feature,
    resources: blockSet.blocks,
  })

  return blockSet.blocks.filter((block) => !blockIDsToRemove.has(block.id))
}

/**
 * Returns a copy of the provided question with resources modified if the display X of Y
 * feature is present. "Display X of Y" will randomly choose X resources from the set of
 * resources for the question.
 */
export function displayXOfYQuestionResources<QuestionType extends Question>({
  nextQuestion,
}: {
  nextQuestion: QuestionType
}) {
  const { collection, feature, key } = getResourcesToChooseFrom({
    question: nextQuestion,
  })
  if (collection.length === 0 || !key || !feature) {
    return nextQuestion
  }

  const resourceIDsToRemove = getResourceIDsToRemove({
    feature,
    resources: collection,
  })

  return {
    ...nextQuestion,
    [key]: collection.filter(({ id }) => {
      return !resourceIDsToRemove.has(id)
    }),
  }
}

function getResourceIDsToRemove<ResourcesType extends { id: string }[]>({
  feature,
  resources,
}: {
  feature: DisplayXOfYFeature
  resources: ResourcesType
}) {
  const { numToDisplay, preservedResourceIDs } = feature
  const numOptionsToRemove = resources.length - numToDisplay

  const shuffledIDs = shuffle(resources.map(({ id }) => id))

  // "Preserved" resources are ones that we should attempt to keep
  // if the number of "preserved" is less than the number to display.
  const prioritizedIDsAtEnd = orderBy(
    shuffledIDs,
    (id) => preservedResourceIDs.has(id),
    'asc',
  )

  const resourceIDsToRemove = new Set<string>()
  for (let i = 0; i < numOptionsToRemove; i++) {
    resourceIDsToRemove.add(prioritizedIDsAtEnd[i])
  }

  return resourceIDsToRemove
}

/**
 * Different things will be chosen from a question depending on its type. For example,
 * multiple choice questions could have options chosen while scale questions could
 * have scales chosen. This function returns the collection to choose from, the configured
 * display X of Y feature, and a string key identifying the collection type (e.g. "statements",
 * "options", "scales", etc.).
 */
export function getResourcesToChooseFrom({ question }: { question: Question }) {
  const resourcesToChooseFrom: {
    collection: { id: string; title: string }[]
    feature: DisplayXOfYFeature | undefined
    key: 'options' | 'scales' | 'statements' | undefined
  } = { collection: [], feature: undefined, key: undefined }

  if ('displayXOfY' in question.features && question.features.displayXOfY) {
    resourcesToChooseFrom.feature = question.features.displayXOfY

    if (question.type === 'matrix') {
      resourcesToChooseFrom.collection = question.statements
      resourcesToChooseFrom.key = 'statements'
    } else if (
      question.type === 'multipleChoice' ||
      question.type === 'ranking'
    ) {
      resourcesToChooseFrom.collection = question.options
      resourcesToChooseFrom.key = 'options'
    } else if (question.type === 'scale') {
      resourcesToChooseFrom.collection = question.scales
      resourcesToChooseFrom.key = 'scales'
    }
  }

  return resourcesToChooseFrom
}
