import { useState } from 'react'

import { BlockSetMonadic } from 'utils/questionBlocks'
import { filterSurveyByDisplayLogic } from 'utils/displayLogic'
import { Question } from 'utils/questions'
import { Survey } from 'utils/surveys'

import ConditionsPassFail from './ConditionsPassFail'
import DisplayLogicDialog from './DisplayLogicDialog'
import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const SurveyAudience = ({
  monadicBlocks,
  questionsCurrent,
  questionsInitial,
  survey,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  survey: Survey
}) => {
  const [isOpen, setIsOpen] = useState(false)

  if (survey.features.displayLogic.groups.length === 0) {
    return null
  }

  const passedAudience = !!filterSurveyByDisplayLogic({
    monadicBlocks,
    questions: questionsCurrent,
    survey,
  })

  return (
    <FeatureDisplay title="Audience">
      <ListDiscIndented>
        <li>
          <ConditionsPassFail
            onClickDetails={() => {
              setIsOpen(true)
            }}
            pass={passedAudience}
            title="Audience"
          />
        </li>
      </ListDiscIndented>

      <DisplayLogicDialog
        conditionGroups={survey.features.displayLogic}
        isOpen={isOpen}
        monadicBlocks={monadicBlocks}
        onClose={() => {
          setIsOpen(false)
        }}
        questionsCurrent={questionsCurrent}
        questionsInitial={questionsInitial}
        title="Survey Audience"
      />
    </FeatureDisplay>
  )
}

export default SurveyAudience
