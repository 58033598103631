import { ComponentProps } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { adjustRankResponse } from 'utils/responses'
import {
  canContinue,
  isOptionDisabled,
  Question as QuestionType,
} from 'utils/ranking'
import { useConcept } from 'hooks/concepts'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import ImageOption from './ImageOption'
import ImageOptionsContainer from './ImageOptionsContainer'
import InputCheckbox from './InputCheckbox'
import InputRank from './InputRank'
import InputLabeled from './InputLabeled'
import Question from './Question'

interface RankingForm {
  response: string[]
}

const RankingQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onViewedConcept,
  onViewedImage,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(opts: Pick<QuestionType, 'response'>): void
  onViewedConcept(questionID: string, conceptID: string): void
  onViewedImage(optionID: string): void
  question: QuestionType
}) => {
  const { control, handleSubmit, setValue, watch } = useForm<RankingForm>({
    defaultValues: {
      response: question.response,
    },
  })

  const curResponse = watch('response')

  /**
   * Our displayed options aren't really form fields - we currently use buttons. I want to
   * display them as radio groups / checkboxes eventually, but that will be part of a larger
   * usability refresh. For now, we manually update the response value when an option is clicked.
   */
  function onClickOption(optionID: string) {
    setValue(
      'response',
      adjustRankResponse({ curResponse, optionID, question }),
    )
  }

  const onSubmit: SubmitHandler<RankingForm> = (data) => {
    onCompletedQuestion({ response: data.response })
  }

  const options = question.options.map((option) => {
    const disabled = isOptionDisabled({ curResponse, option, question })
    const indexOfOption = curResponse.indexOf(option.id)
    const rank = indexOfOption === -1 ? undefined : indexOfOption + 1

    const inputId = option.id
    const input = (
      <Controller
        control={control}
        name="response"
        render={({ field }) => {
          let ariaLabel = option.title
          if (!ariaLabel && option.type === 'image') {
            ariaLabel = option.image.alt
          }

          const commonProps = {
            ...field,
            'aria-label': ariaLabel,
            disabled,
            id: inputId,
            onChange: () => {
              onClickOption(option.id)
            },
          }

          return question.constraints.exclusiveOptionIDs.has(option.id) ? (
            <InputCheckbox {...commonProps} checked={!!rank} />
          ) : (
            <InputRank {...commonProps} rank={rank} />
          )
        }}
      />
    )

    return option.type === 'image' ? (
      <ImageOption
        key={option.id}
        alt={option.image.alt}
        disabled={disabled}
        input={input}
        inputId={inputId}
        isSelected={!!rank}
        onClickZoom={() => {
          onViewedImage(option.id)
        }}
        publicID={option.image.publicID}
        title={option.features.hideTitle || !option.title ? null : option.title}
        zoomRequired={
          question.constraints.requireImageViews &&
          option.constraints.requireView &&
          !option.image.viewed
        }
      />
    ) : (
      <InputLabeled
        key={option.id}
        disabled={disabled}
        hasValue={!!rank}
        id={option.id}
        input={input}
      >
        {option.title}
      </InputLabeled>
    )
  })

  const { concept } = useConcept({ onViewedConcept, question })

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Question
        concept={concept}
        directions={question.directions}
        title={question.title}
      >
        {/*
         * We don't currently allow both image and text options for a single question,
         * but there's nothing about this code that couldn't support it. We'd just have
         * to figure out how to display them both in a way that makes sense.
         */}
        {question.options[0]?.type === 'image' ? (
          <ImageOptionsContainer>{options}</ImageOptionsContainer>
        ) : (
          <div className="space-y-3">{options}</div>
        )}
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ curResponse, question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default RankingQuestion
