const ConditionsPassFail = ({
  onClickDetails,
  pass,
  title,
}: {
  onClickDetails(): void
  pass: boolean
  title: string
}) => {
  return (
    <div className="space-x-2">
      <span>
        {title}:{' '}
        {pass ? (
          'Pass'
        ) : (
          <span className="text-xs font-semibold uppercase text-red-400">
            Fail
          </span>
        )}
      </span>

      <button className="underline" onClick={onClickDetails} type="button">
        Details
      </button>
    </div>
  )
}

export default ConditionsPassFail
