import { Dialog } from '@headlessui/react'
import { ReactNode } from 'react'

import { useTranslations } from 'contexts/i18n'

import Icon from './Icon'

const OverlayFullScreen = ({
  children,
  onClose,
}: {
  children: ReactNode
  onClose?(): void
}) => {
  const translations = useTranslations()

  return (
    <Dialog
      as="div"
      className="relative z-50"
      onClose={() => {
        // When not testing, a respondent is not able to close the overlays.
        onClose?.()
      }}
      open
    >
      <div className="fixed inset-0 flex flex-col overflow-auto bg-gray-5 text-gray-700">
        <Dialog.Panel className="flex grow flex-col items-center justify-center p-4">
          <div className="max-w-[600px] sm:max-w-none">{children}</div>

          {onClose && (
            <button
              aria-label={translations.ACCESSIBILITY.CLOSE_OVERLAY}
              className="absolute right-4 top-4 p-4"
              onClick={onClose}
              type="button"
            >
              <div aria-hidden="true" className="h-6 w-6">
                <Icon id="x-close" />
              </div>
            </button>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default OverlayFullScreen
