import { useMutation, useQuery } from '@tanstack/react-query'

import { getSurvey, submitAnswers } from 'services/glass-api/surveys'
import { getSurveyExecution } from 'services/glass-api/surveyExecutions'
import { minutesToMs } from 'utils/general'

export function useSubmitAnswers({ retry }: { retry: number }) {
  return useMutation({ mutationFn: submitAnswers, retry })
}

export function useSurvey({
  surveyHash,
  useExecution = false,
}: {
  surveyHash: string | undefined
  useExecution?: boolean
}) {
  return useQuery({
    enabled: !!surveyHash,
    queryFn: () => {
      if (!surveyHash) {
        throw new Error('No survey hash provided')
      }

      return useExecution
        ? getSurveyExecution({ surveyHash })
        : getSurvey({ surveyHash })
    },
    queryKey: ['surveys', surveyHash, useExecution],
    staleTime: minutesToMs(5),
  })
}
