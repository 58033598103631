import { useState } from 'react'

import { BlockSetMonadic } from 'utils/questionBlocks'
import { didSatisfyConditionGroups } from 'utils/conditions'
import { Question } from 'utils/questions'
import { Survey } from 'utils/surveys'

import ConditionsPassFail from './ConditionsPassFail'
import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'
import SurveyVariableQuotasDialog from './SurveyVariableQuotasDialog'

const SurveyVariableQuotas = ({
  monadicBlocks,
  questionsCurrent,
  questionsInitial,
  survey,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  survey: Survey
}) => {
  const [isOpen, setIsOpen] = useState(false)

  if (survey.quotas.variables.length === 0) {
    return null
  }

  const satisfiesAllVariableQuotas = survey.quotas.variables.every(
    ({ segments }) => {
      return segments.some(({ conditionGroups }) =>
        didSatisfyConditionGroups({
          conditionGroups,
          monadicBlocks,
          questions: questionsCurrent,
        }),
      )
    },
  )

  return (
    <FeatureDisplay title="Variable Quotas">
      <ListDiscIndented>
        <li>
          <ConditionsPassFail
            onClickDetails={() => {
              setIsOpen(true)
            }}
            pass={satisfiesAllVariableQuotas}
            title="Variable Quotas"
          />
        </li>
      </ListDiscIndented>

      <SurveyVariableQuotasDialog
        isOpen={isOpen}
        monadicBlocks={monadicBlocks}
        onClose={() => {
          setIsOpen(false)
        }}
        questionsCurrent={questionsCurrent}
        questionsInitial={questionsInitial}
        survey={survey}
      />
    </FeatureDisplay>
  )
}

export default SurveyVariableQuotas
