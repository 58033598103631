import ExceptionPage from 'components/ExceptionPage'

const BadRequestPage = () => {
  return (
    <ExceptionPage title="Bad Survey Link">
      We're sorry, but something doesn't look right with the survey link. Our
      team is on it.
    </ExceptionPage>
  )
}

export default BadRequestPage
