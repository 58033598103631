import { Question } from 'types/glass-api/domainModels'

export function buildExclusiveMatrixOptionIDs({
  question,
}: {
  question: Question
}) {
  return new Set<string>(
    question.matrixOptions
      .filter((option) => option.isExclusive)
      .map(({ id }) => `${id}`),
  )
}

export function buildExclusiveOptionIDs({ question }: { question: Question }) {
  return new Set<string>(
    question.options
      .filter(({ exclusive }) => exclusive)
      .map(({ id }) => `${id}`),
  )
}
