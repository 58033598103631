import Icon from './Icon'
import OverlayFullScreen from './OverlayFullScreen'

const OverlayCompleted = ({
  onClose,
  savingPrompt,
  step,
  subtitle,
  title,
}: {
  onClose?(): void
  savingPrompt: string
  step: 'saving' | 'saved'
  subtitle: string
  title: string
}) => {
  return (
    <OverlayFullScreen onClose={onClose}>
      <div className="flex flex-col items-center space-y-6 text-center">
        {step === 'saving' ? (
          <p>{savingPrompt}</p>
        ) : step === 'saved' ? (
          <>
            <div className="w-36">
              <Icon id="survey-complete" />
            </div>

            <p className="text-4xl font-semibold">{title}</p>
            {subtitle && <p>{subtitle}</p>}
          </>
        ) : null}
      </div>
    </OverlayFullScreen>
  )
}

export default OverlayCompleted
