import axios from 'axios'

import { getAPIUrl } from './baseAPI'
import { Survey } from 'types/glass-api/domainModels'

export const GET_SURVEY_EXECUTION = ({
  surveyHash,
}: {
  surveyHash: string
}) => ({
  path: `/survey-executions/${surveyHash}`,
  version: 'v2' as const,
})

export async function getSurveyExecution({
  surveyHash,
}: {
  surveyHash: string
}) {
  return (
    await axios.get<Survey>(getAPIUrl(GET_SURVEY_EXECUTION({ surveyHash })))
  ).data
}
