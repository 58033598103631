import { forwardRef, InputHTMLAttributes } from 'react'

const InputCheckbox = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'type'>
>(function InputCheckbox(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      // The styling of the checkbox is a URL-encoded version of our sprite SVG check icon.
      // Using a background-image for this was inspired by the code in Linear's website.
      className="m-0 grid h-6 w-6 appearance-none place-content-center rounded border border-gray-4 bg-white bg-[2px_2px] bg-no-repeat checked:border-primary-600 checked:bg-primary-600 checked:[background-image:url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2024%2024%22%20id%3D%22check%22%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M20%206L9%2017L4%2012%22%20stroke%3D%22%23fffeff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20%2F%3E%3C%2Fsvg%3E)] disabled:cursor-not-allowed disabled:bg-gray-100"
      type="checkbox"
    />
  )
})

export default InputCheckbox
