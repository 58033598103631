import { Concept } from 'utils/concepts'

import ConceptImage from './ConceptImage'
import ConceptVideo from './ConceptVideo'

const ConceptMedia = ({
  concept,
  onViewed,
  viewRequired,
}: {
  concept: Concept
  onViewed?(): void
  viewRequired: boolean
}) => {
  return concept.type === 'image' ? (
    <ConceptImage
      alt={concept.alt}
      onClickZoom={onViewed}
      publicID={concept.publicID}
      zoomRequired={viewRequired && !concept.viewed}
    />
  ) : (
    <ConceptVideo onPlaybackEnded={onViewed} publicID={concept.publicID} />
  )
}

export default ConceptMedia
