import { ReactNode } from 'react'

import { useTranslations } from 'contexts/i18n'

import Button from './Button'
import Icon from './Icon'

const AdvanceButton = ({
  buttonText,
  disabled = false,
  isLastQuestion,
}: {
  buttonText?: { advance?: string; done?: string } | null
  disabled?: boolean
  isLastQuestion: boolean
}) => {
  const translations = useTranslations()

  return (
    <Button
      disabled={disabled}
      icon={isLastQuestion ? undefined : <Icon id="chevron-right" />}
      type="submit"
    >
      {isLastQuestion
        ? buttonText?.done || translations.UI_ELEMENTS.BUTTON_DONE
        : buttonText?.advance || translations.UI_ELEMENTS.BUTTON_NEXT}
    </Button>
  )
}

export default AdvanceButton

export const AdvanceButtonContainer = ({
  children,
}: {
  children: ReactNode
}) => {
  return <div className="mt-8 flex w-full justify-end">{children}</div>
}
