import { ComponentProps } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'

import {
  canContinue,
  isTextboxDisabled,
  Question as QuestionType,
} from 'utils/unaidedAwareness'
import { useTranslations } from 'contexts/i18n'

import AdvanceButton, { AdvanceButtonContainer } from './AdvanceButton'
import ConceptMedia from './ConceptMedia'
import Input from './Input'
import Question from './Question'

interface UnaidedAwarenessForm {
  response: string[]
}

const UnaidedAwarenessQuestion = ({
  buttonText,
  isLastQuestion,
  onCompletedQuestion,
  onPaste,
  onViewedConcept,
  question,
}: {
  buttonText?: ComponentProps<typeof AdvanceButton>['buttonText']
  isLastQuestion: boolean
  onCompletedQuestion(opts: Pick<QuestionType, 'response'>): void
  onPaste(freeText: string): void
  onViewedConcept(questionID: string, conceptID: string): void
  question: QuestionType
}) => {
  const translations = useTranslations()

  const { handleSubmit, register, watch } = useForm<UnaidedAwarenessForm>({
    defaultValues: {
      response: question.legacyOptionIDs.map(
        (_optionID, i) => question.response[i] ?? '',
      ),
    },
  })

  const curResponse = watch('response')

  const onSubmit: SubmitHandler<UnaidedAwarenessForm> = (data) => {
    onCompletedQuestion({ response: data.response })
  }

  return (
    <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
      <Question
        concept={
          question.concepts.length > 0 ? (
            <ConceptMedia
              concept={question.concepts[0]}
              onViewed={() => {
                onViewedConcept(question.id, question.concepts[0].id)
              }}
              viewRequired={question.constraints.requireConceptView}
            />
          ) : undefined
        }
        directions={question.directions}
        title={question.title}
      >
        {question.legacyOptionIDs.map((legacyOptionID, i) => {
          return (
            <div
              key={legacyOptionID}
              className="flex flex-col items-center space-y-2"
            >
              <Input
                {...register(`response.${i}`)}
                aria-label={translations.ACCESSIBILITY.RESPONSE_FOR_LINE(i + 1)}
                disabled={isTextboxDisabled({ question })}
                onPaste={(event) => {
                  onPaste(event.clipboardData.getData('text'))
                }}
                type="text"
              />
            </div>
          )
        })}
      </Question>

      <AdvanceButtonContainer>
        <AdvanceButton
          buttonText={buttonText}
          disabled={!canContinue({ curResponse, question })}
          isLastQuestion={isLastQuestion}
        />
      </AdvanceButtonContainer>
    </form>
  )
}

export default UnaidedAwarenessQuestion
