import axios from 'axios'

import {
  GetTokenResponse,
  SearchRespondentResponse,
} from 'types/research-defender/domainModels'
import { getAPIUrl } from './baseAPI'

export interface SearchRespondent {
  surveyID: string
  userID: number
}

export const GET_TOKEN = () => ({
  // This token in the path is a private key. It's a known exposure.
  // See https://useglass.slack.com/archives/C039JQHC6M8/p1696950474309129.
  // See https://linear.app/glass-tech/issue/GLA-583/check-for-respondent-quality-via-research-defender-during-answer.
  path: '/respondents/get_token/d9bfafa9-e8be-4d10-9d39-298c2bcc0765',
  version: 'v3' as const,
})

export const SEARCH_RESPONDENT = () => ({
  // This token in the path is a private key. It's a known exposure.
  // See https://useglass.slack.com/archives/C039JQHC6M8/p1696950474309129.
  // See https://linear.app/glass-tech/issue/GLA-583/check-for-respondent-quality-via-research-defender-during-answer.
  path: '/respondents/search/bd5587f1-dc60-49da-868d-d22d5f10aaf6',
  version: 'v3' as const,
})

export async function getToken() {
  return (await axios.get<GetTokenResponse>(getAPIUrl(GET_TOKEN()))).data
}

/**
 * Documentation for the /SEARCH endpoint can be found at:
 * https://docs.google.com/document/d/1o89wuXZVzhSelvZZuDTKK11SVHszOpppyipTQyeI2OQ/edit
 */
export async function searchRespondent({ surveyID, userID }: SearchRespondent) {
  const createTokenResponse = await getToken()

  return (
    await axios.get<SearchRespondentResponse>(getAPIUrl(SEARCH_RESPONDENT()), {
      headers: { token: createTokenResponse.results[0].token },
      params: {
        sn_ud: userID,
        sy_nr: surveyID,
        rt_cy_ce: 'US',
      },
    })
  ).data
}
