import { useEffect, useState } from 'react'

type MediaVariants<VariantType extends string> = {
  [key in Screens]?: VariantType
}

type Screens = '2xl' | 'lg' | 'md' | 'sm' | 'xl' | 'xs'

// Screens order is important so that the screen styles override each other as expected
const orderedScreens: Screens[] = ['xs', 'sm', 'md', 'lg', 'xl', '2xl']

/**
 * Gets the max width values from our Tailwind config file. If we add additional
 * screens, we'll need to update this, but we won't have to worry about keeping changes
 * to max values of existing screens in sync. Variables are defined in main.css.
 * https://github.com/tailwindlabs/tailwindcss/discussions/8674
 */
const style = getComputedStyle(document.documentElement)
const screenMaxSizes: Record<Screens, string> = {
  xs: style.getPropertyValue('--screens-xs').trim(),
  sm: style.getPropertyValue('--screens-sm').trim(),
  md: style.getPropertyValue('--screens-md').trim(),
  lg: style.getPropertyValue('--screens-lg').trim(),
  xl: style.getPropertyValue('--screens-xl').trim(),
  '2xl': style.getPropertyValue('--screens-2xl').trim(),
}

/**
 * Returns a specified variant based on the current screen size (defaulting to the default variant).
 * The variants should be an object with key, value: screen size, variant. For example, { xs: 'small', md: 'large' }
 * would return "small" on an xs screen size and "large" on a md screen size (as defined in the tailwind.config.ts file).
 *
 * Inspired by https://dev.to/justincy/4-patterns-for-responsive-props-in-react-39ak#object-props
 * and https://usehooks.com/useMedia/.
 */
export const useVariantByScreenSize = <VariantType extends string>(
  defaultVariant: VariantType,
  mediaVariants: MediaVariants<VariantType>,
) => {
  const orderedMedia = getOrderedMedia(mediaVariants)

  const mediaQueryLists = orderedMedia.map((media) => {
    return window.matchMedia(`(max-width: ${screenMaxSizes[media[0]]})`)
  })

  const values: VariantType[] = orderedMedia.map((media) => media[1])

  const [matchedIndex, setMatchedIndex] = useState(() => {
    return mediaQueryLists.findIndex((mql) => mql.matches)
  })

  useEffect(() => {
    function onMediaChange() {
      setMatchedIndex(() => {
        return mediaQueryLists.findIndex((mql) => mql.matches)
      })
    }

    mediaQueryLists.forEach((mql) => {
      // The MediaQueryListEvent API is not supported in Safari < 14.
      // See https://caniuse.com/mdn-api_mediaquerylistevent.
      if (mql.addEventListener) {
        mql.addEventListener('change', onMediaChange)
      }
    })

    return () =>
      mediaQueryLists.forEach((mql) => {
        if (mql.removeEventListener) {
          mql.removeEventListener('change', onMediaChange)
        }
      })
  }, [mediaQueryLists])

  return values[matchedIndex] || defaultVariant
}

function getOrderedMedia<VariantType extends string>(
  mediaVariants: MediaVariants<VariantType>,
): [Screens, VariantType][] {
  return orderedScreens
    .map((screen) => {
      if (mediaVariants[screen]) {
        return [screen, mediaVariants[screen]]
      }
    })
    .filter((media): media is [Screens, VariantType] => !!media)
}
