import { ValidationValueMessage } from 'react-hook-form'

import {
  buildConceptDisplayLogic,
  buildQuestionDisplayLogic,
  DisplayLogic,
  DisplayLogicByResource,
} from './displayLogic'
import { buildPipeConceptFeature, PipeConceptFeature } from './piping'
import { buildQualityMatch } from './quality'
import {
  buildTestLabel,
  getConstraintMinMax,
  getConstraintTextRegExp,
  hasFeature,
  getFeatureRegex,
  QUESTION_TYPES,
} from './questions'
import { Concept, getConcepts } from './concepts'
import { getRequireViewConceptError } from './requireViewing'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'
import { Survey } from './surveys'

export type Question = {
  concepts: Concept[]
  constraints: {
    max: ValidationValueMessage<number> | undefined
    min: ValidationValueMessage<number> | undefined
    requireConceptView: boolean
    textRegExp: ValidationValueMessage<RegExp> | undefined
  }
  directions: string
  features: {
    conceptDisplayLogic: DisplayLogicByResource
    displayLogic: DisplayLogic
    pipeConcept: PipeConceptFeature | undefined
  }
  id: string
  quality: { match: string }
  response: string
  responseConfig:
    | { type: 'email' | 'number' | 'text' }
    | { responseRequired: boolean; projectID: string; type: 'audio-video' }
  testing: { label: string }
  title: string
  type: 'openEnded'
}

export function canContinue({
  curResponse,
  question,
}: {
  curResponse: Question['response']
  question: Question
}) {
  return curResponse !== '' && !getRequireViewConceptError({ question })
}

export function getQuestion({
  id,
  question,
  survey,
}: {
  id: string
  question: QuestionAPI
  survey: Survey
}) {
  const { max, min } = getConstraintMinMax({ question, survey })

  return {
    concepts: getConcepts({ question }),
    constraints: {
      max,
      min,
      requireConceptView: hasFeature({ code: 'VAL02', question }),
      textRegExp: getConstraintTextRegExp(question),
    },
    directions: question.description,
    features: {
      conceptDisplayLogic: buildConceptDisplayLogic({ question }),
      displayLogic: buildQuestionDisplayLogic({ question }),
      pipeConcept: buildPipeConceptFeature({ question }),
    },
    id,
    quality: { match: buildQualityMatch(question) },
    response: '',
    responseConfig: getResponseConfig(question),
    testing: { label: buildTestLabel(question) },
    title: question.title,
    type: 'openEnded' as const,
  } satisfies Question
}

export function getResponseConfig(question: QuestionAPI) {
  if (
    question.constraint &&
    'errorMessage' in question.constraint &&
    // It's not ideal that we're determining the input type based on an error message
    // string, but it's what we currently have to do. See the comment for getConstraintTextRegExp.
    question.constraint.errorMessage === 'Must be a valid email.'
  ) {
    return { type: 'email' as const }
  }

  if (question.questionTypeId === 10) {
    return { type: 'number' as const }
  }

  const voxpopmeProjectID = getFeatureRegex({
    code: 'VoxpopmeFeature01',
    question,
  })
  if (voxpopmeProjectID) {
    return {
      projectID: voxpopmeProjectID,
      responseRequired: hasFeature({ code: 'VoxpopmeFeature02', question }),
      type: 'audio-video' as const,
    }
  }

  return { type: 'text' as const }
}

export function isResponseDisabled({ question }: { question: Question }) {
  if (
    question.constraints.requireConceptView &&
    question.concepts.length > 0 &&
    !question.concepts[0].viewed
  ) {
    return true
  }

  return false
}

export function isOpenEndedQuestion(question: QuestionAPI) {
  return QUESTION_TYPES.OPEN_ENDED.includes(question.questionTypeId)
}
