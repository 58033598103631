import { shuffle } from 'lodash-es'

import { Question as APIQuestion } from 'types/glass-api/domainModels'
import { Question } from './questions'

export interface Concept {
  alt: string
  // Preserved concepts are used in monadic blocks to indicate that certain
  // concepts need to be shown in some iteration of the block. We may want to
  // refactor this feature at a later point if it's only ever relevant for
  // monadic blocks.
  features: { preserve: boolean }
  id: string
  publicID: string
  type: 'image' | 'video'
  viewed: boolean
}

export function getConcepts({ question }: { question: APIQuestion }) {
  return question.conceptTestMedia.map((concept) => {
    const publicID = concept.media.public_id
    const type = concept.media.resource_type

    return {
      alt: concept.description,
      features: { preserve: concept.preserved },
      id: `${concept.id}`,
      publicID,
      type,
      viewed: false,
    } satisfies Concept
  })
}

export function selectRandomConcept({
  nextQuestion,
}: {
  nextQuestion: Question
}) {
  if (nextQuestion.concepts.length === 0) {
    return nextQuestion
  }

  const shuffledConcepts = shuffle(nextQuestion.concepts)

  return {
    ...nextQuestion,
    concepts: [shuffledConcepts[0]],
  } satisfies Question
}
