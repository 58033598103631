import { Decimal } from 'decimal.js-light'
import { omitBy, round } from 'lodash-es'

export function commaSeparate(items: string[]) {
  if (items.length === 0) {
    return ''
  }

  if (items.length === 1) {
    return items[0]
  } else if (items.length === 2) {
    return items.join(' and ')
  }

  const itemsCopy = [...items]
  const lastItem = itemsCopy.pop()

  return `${itemsCopy.join(', ')}, and ${lastItem}`
}

export function minutesToMs(minutes: number) {
  return minutes * 60 * 1000
}

/**
 * Returns a random number between [min, max] that is a multiple of the increment.
 */
export function randomWithIncrement({
  increment,
  max,
  min,
}: {
  increment: Decimal
  max: Decimal
  min: Decimal
}) {
  const possibilities: Decimal[] = []
  for (let i = min; i.lte(max); i = i.plus(increment)) {
    possibilities.push(i)
  }

  // If the randomly generated number is 1, without the Math.min call
  // we would try to access the last index + 1. So we adjust this to
  // be the last index at most.
  const randomIndex = Math.min(
    Math.floor(Math.random() * possibilities.length),
    possibilities.length - 1,
  )

  return possibilities[randomIndex]
}

export function removeQueryParamsEmptyKeys(
  obj: Record<string, boolean | string | null | undefined | ''>,
) {
  return omitBy(obj, (value) => {
    return value === undefined || value === ''
  })
}

export function stringToNumber(value: string | undefined) {
  if (!value) {
    return null
  }

  const num = Number(value)

  return Number.isNaN(num) ? null : num
}

/**
 * Returns an array of titles from the provided resources array where the resource is
 * found in the "ids" set. This removes any resources that don't have a title.
 */
export function titlesFromSet({
  ids,
  resources,
}: {
  ids: Set<string>
  resources: { id: string; title: string }[]
}) {
  return resources.filter(({ id }) => ids.has(id)).map(({ title }) => title)
}

/**
 * Converts a provided value to a number of cents. If no value is provided, or
 * if it can't be represented as a number, it returns null.
 */
export function toCents(value: string | number | null) {
  const valueNum = typeof value === 'string' ? stringToNumber(value) : value

  return valueNum === null ? null : round(valueNum * 100)
}
