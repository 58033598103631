import { AdvancedVideo } from '@cloudinary/react'
import { memo } from 'react'

import { getVideoFromPublicID } from 'utils/media'

/**
 * When the props change for the Cloudinary AdvancedVideo component, it recreates
 * the video. This is somewhat jarring for the user, so we memoize this component
 * so that it only updates when props change.
 */
const ConceptVideo = memo(function ConceptVideo({
  onPlaybackEnded,
  publicID,
}: {
  onPlaybackEnded?(): void
  publicID: string
}) {
  return (
    <div className="flex">
      <AdvancedVideo
        className="max-h-full"
        cldVid={getVideoFromPublicID({ publicID })}
        controls
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- @cloudinary/react types are incorrect
        // @ts-expect-error
        controlsList="nodownload noplaybackrate"
        onEnded={onPlaybackEnded}
      />
    </div>
  )
})

export default ConceptVideo
