import { ReactNode } from 'react'

import { Question } from 'utils/questions'
import { titlesFromSet } from 'utils/general'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented, { NestedListItem } from './ListDiscIndented'

const MultipleResponse = ({ question }: { question: Question }) => {
  if (
    (question.type !== 'matrix' &&
      question.type !== 'multipleChoice' &&
      question.type !== 'ranking') ||
    ('singleChoice' in question.constraints &&
      question.constraints.singleChoice)
  ) {
    return null
  }

  const exclusiveTitles = titlesFromSet({
    ids: question.constraints.exclusiveOptionIDs,
    resources: question.options,
  })

  let minMax: ReactNode = null
  let sum: ReactNode = null

  if (question.type === 'multipleChoice' || question.type === 'ranking') {
    if (question.constraints.max || question.constraints.min) {
      minMax = `Min-Max: [${question.constraints.min ?? ''}, ${
        question.constraints.max ?? ''
      }]`
    }

    if ('sum' in question.constraints && question.constraints.sum) {
      sum = `Required Sum: ${question.constraints.sum}`
    }
  }

  return (
    <FeatureDisplay title="Selections">
      <ListDiscIndented>
        <li>Multiple Response</li>
        {exclusiveTitles.length > 0 && (
          <li>
            <NestedListItem title="Exclusive Options">
              {exclusiveTitles.map((title) => (
                <li key={title}>{title}</li>
              ))}
            </NestedListItem>
          </li>
        )}
        {minMax && <li>{minMax}</li>}
        {sum && <li>{sum}</li>}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default MultipleResponse
