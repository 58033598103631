import { ReactNode } from 'react'

const ListDiscIndented = ({ children }: { children: ReactNode }) => {
  return <ul className="list-disc pl-4">{children}</ul>
}

export default ListDiscIndented

export const NestedListItem = ({
  children,
  title,
}: {
  children: ReactNode
  title: string
}) => {
  return (
    <>
      {title}:<ListDiscIndented>{children}</ListDiscIndented>
    </>
  )
}
