import Icon from 'components/Icon'

const ExceptionPage = ({
  children,
  title,
}: {
  children: string
  title: string
}) => {
  return (
    <div className="flex h-full flex-col items-center justify-center space-y-6 px-32 text-center text-gray-700 md:justify-start md:px-4 md:py-16">
      <div className="w-36">
        <Icon id="survey-error" />
      </div>
      <h1 className="text-4xl font-semibold md:text-4xl">{title}</h1>
      <p>{children}</p>
    </div>
  )
}

export default ExceptionPage
