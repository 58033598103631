import { map, some } from 'lodash-es'
import { useState } from 'react'

import { BlockSetMonadic } from 'utils/questionBlocks'
import { ConditionGroups } from 'utils/conditions'
import {
  DisplayLogicByResource,
  filterQuestionByDisplayLogic,
  filterResourcesByDisplayLogic,
  getQuestionResourcesToFilter,
} from 'utils/displayLogic'
import { Question } from 'utils/questions'

import ConditionsPassFail from './ConditionsPassFail'
import DisplayLogicDialog from './DisplayLogicDialog'
import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const DisplayLogic = ({
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  question: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) => {
  const { collection, feature } = getQuestionResourcesToFilter({
    question,
  })

  const hasQuestionDisplayLogic =
    question.features.displayLogic.groups.length > 0
  const hasQuestionResourcesDisplayLogic =
    feature && some(feature, (displayLogic) => displayLogic.groups.length > 0)
  const hasConceptDisplayLogic = some(
    question.features.conceptDisplayLogic,
    (displayLogic) => displayLogic.groups.length > 0,
  )

  if (
    !hasQuestionDisplayLogic &&
    !hasQuestionResourcesDisplayLogic &&
    !hasConceptDisplayLogic
  ) {
    return null
  }

  return (
    <FeatureDisplay title="Dislay Logic">
      <ListDiscIndented>
        {hasQuestionDisplayLogic && (
          <li>
            <DisplayLogicQuestion
              key="display-logic-question"
              monadicBlocks={monadicBlocks}
              question={question}
              questionsCurrent={questionsCurrent}
              questionsInitial={questionsInitial}
            />
          </li>
        )}
        {hasConceptDisplayLogic && (
          <li>
            <DisplayLogicConcepts
              key="display-logic-concepts"
              monadicBlocks={monadicBlocks}
              question={question}
              questionsCurrent={questionsCurrent}
              questionsInitial={questionsInitial}
            />
          </li>
        )}
        {hasQuestionResourcesDisplayLogic && (
          <li>
            <DisplayLogicQuestionResources
              key="display-logic-question-resources"
              feature={feature}
              monadicBlocks={monadicBlocks}
              question={question}
              questionsCurrent={questionsCurrent}
              questionsInitial={questionsInitial}
              resources={collection}
            />
          </li>
        )}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default DisplayLogic

const DisplayLogicConcepts = ({
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  question: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) => {
  const [displayLogic, setDisplayLogic] = useState<ConditionGroups | null>(null)

  const hasConceptDisplayLogic = some(
    question.features.conceptDisplayLogic,
    (displayLogic) => displayLogic.groups.length > 0,
  )

  if (!hasConceptDisplayLogic) {
    return null
  }

  const conceptsPassingDisplayLogic = new Set(
    filterResourcesByDisplayLogic({
      displayLogicByResourceID: question.features.conceptDisplayLogic,
      monadicBlocks,
      nextQuestionID: question.id,
      questions: questionsCurrent,
      resources: question.concepts,
    }).map((option) => option.id),
  )

  return (
    <div>
      Concepts Display Logic:
      <ListDiscIndented>
        {map(
          question.features.conceptDisplayLogic,
          (displayLogic, conceptID) => {
            const concept = question.concepts.find(
              (concept) => concept.id === conceptID,
            )
            if (displayLogic.groups.length === 0) {
              return null
            }

            return (
              <li key={conceptID} className="space-x-2">
                <ConditionsPassFail
                  onClickDetails={() => {
                    setDisplayLogic(displayLogic)
                  }}
                  pass={conceptsPassingDisplayLogic.has(conceptID)}
                  title={concept?.alt ?? `Concept ${conceptID}`}
                />
              </li>
            )
          },
        )}
      </ListDiscIndented>
      {displayLogic && (
        <DisplayLogicDialog
          conditionGroups={displayLogic}
          isOpen
          monadicBlocks={monadicBlocks}
          onClose={() => {
            setDisplayLogic(null)
          }}
          question={question}
          questionsCurrent={questionsCurrent}
          questionsInitial={questionsInitial}
          title="Concept Display Logic"
        />
      )}
    </div>
  )
}

const DisplayLogicQuestionResources = ({
  feature,
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
  resources,
}: {
  feature: DisplayLogicByResource
  monadicBlocks: Record<string, BlockSetMonadic>
  question: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  resources: { id: string; title: string }[]
}) => {
  const [displayLogic, setDisplayLogic] = useState<ConditionGroups | null>(null)

  const hasDisplayLogic = some(
    feature,
    (displayLogic) => displayLogic.groups.length > 0,
  )

  if (!hasDisplayLogic) {
    return null
  }

  const resourcesPassingDisplayLogic = new Set(
    filterResourcesByDisplayLogic({
      displayLogicByResourceID: feature,
      monadicBlocks,
      nextQuestionID: question.id,
      questions: questionsCurrent,
      resources,
    }).map((option) => option.id),
  )

  return (
    <div>
      Resources Display Logic:
      <ListDiscIndented>
        {map(feature, (displayLogic, resourceID) => {
          const resource = resources.find(
            (resource) => resource.id === resourceID,
          )
          if (displayLogic.groups.length === 0 || !resource) {
            return null
          }

          return (
            <li key={resourceID} className="space-x-2">
              <ConditionsPassFail
                onClickDetails={() => {
                  setDisplayLogic(displayLogic)
                }}
                pass={resourcesPassingDisplayLogic.has(resourceID)}
                title={resource.title}
              />
            </li>
          )
        })}
      </ListDiscIndented>
      {displayLogic && (
        <DisplayLogicDialog
          conditionGroups={displayLogic}
          isOpen
          monadicBlocks={monadicBlocks}
          onClose={() => {
            setDisplayLogic(null)
          }}
          question={question}
          questionsCurrent={questionsCurrent}
          questionsInitial={questionsInitial}
          title="Resources Display Logic"
        />
      )}
    </div>
  )
}

const DisplayLogicQuestion = ({
  monadicBlocks,
  question,
  questionsCurrent,
  questionsInitial,
}: {
  monadicBlocks: Record<string, BlockSetMonadic>
  question: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
}) => {
  const [isOpen, setIsOpen] = useState(false)

  if (question.features.displayLogic.groups.length === 0) {
    return null
  }

  const passedQuestionDisplayLogic = !!filterQuestionByDisplayLogic({
    monadicBlocks,
    nextQuestion: question,
    questions: questionsCurrent,
  })

  return (
    <div className="space-x-2">
      <ConditionsPassFail
        onClickDetails={() => {
          setIsOpen(true)
        }}
        pass={passedQuestionDisplayLogic}
        title="Question"
      />

      <DisplayLogicDialog
        conditionGroups={question.features.displayLogic}
        isOpen={isOpen}
        monadicBlocks={monadicBlocks}
        onClose={() => {
          setIsOpen(false)
        }}
        question={question}
        questionsCurrent={questionsCurrent}
        questionsInitial={questionsInitial}
        title="Question Display Logic"
      />
    </div>
  )
}
