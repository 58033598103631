import { commaSeparate } from './general'
import {
  getFeatureNumberRangeValue,
  getIterationFromID,
  getPreviousQuestionForIteration,
  Question,
} from './questions'
import { Question as QuestionAPI } from 'types/glass-api/domainModels'

export type PipeConceptFeature = {
  questionID: string
}

/**
 * Converts the API pipe concept feature into a format that's more readable and
 * easier to work with.
 */
export function buildPipeConceptFeature({
  question,
}: {
  question: QuestionAPI
}) {
  // Note: The backend uses the number range to represent the question ID to pipe from.
  const questionIDToPipeFrom = getFeatureNumberRangeValue({
    code: 'QPC01',
    question,
  })
  if (!questionIDToPipeFrom) {
    return
  }

  return {
    questionID: `${questionIDToPipeFrom}`,
  } satisfies PipeConceptFeature
}

function getFormattedSelectedTitles({
  response,
  responseFreeText,
  selectedResources,
}: {
  response: Set<string>
  responseFreeText?: Record<string, string>
  selectedResources: { id: string; title: string }[]
}) {
  const selectedText = selectedResources
    .filter(({ id }) => response.has(id))
    .map(({ id, title }) => responseFreeText?.[id] || title)

  return commaSeparate(selectedText)
}

function getPipingMatches(title: string) {
  // Example piping from previous question:
  // Matrix - ::(Q91575/M335460/ANSWER)
  // Multiple Choice - ::(Q91938/ANSWER)
  // Open-ended - ::(Q91938/ANSWER)
  // Ranking - ::(Q91573/R1/ANSWER)
  // Scale - ::(Q91571/S335448/ANSWER)
  return title.matchAll(
    /::\(Q(?<questionID>\d+)(\/(M(?<statementID>\d+))|\/(S(?<scaleID>\d+))|\/(R(?<rank>\d+)))*\/ANSWER\)/gi,
  )
}

export function pipeChoicesIntoTitle({
  nextQuestion,
  questions,
}: {
  nextQuestion: Question
  questions: Record<string, Question>
}) {
  let newTitle = nextQuestion.title
  const pipeMatches = getPipingMatches(newTitle)

  // Note that it's possible to pipe multiple responses from multiple previous
  // questions so we need to loop to handle all of them.
  for (const match of pipeMatches) {
    const questionID = match.groups?.questionID
    if (!questionID) {
      continue
    }

    const previousQuestion = getPreviousQuestionForIteration({
      iteration: getIterationFromID(nextQuestion.id),
      previousQuestionID: questionID,
      questions,
    })

    if (!previousQuestion) {
      continue
    }

    let replacement = ''

    if (previousQuestion.type === 'multipleChoice') {
      replacement = getFormattedSelectedTitles({
        response: previousQuestion.response,
        responseFreeText: previousQuestion.responseFreeText,
        selectedResources: previousQuestion.options,
      })
    } else if (previousQuestion.type === 'openEnded') {
      replacement = previousQuestion.response
    } else if (previousQuestion.type === 'scale' && match.groups?.scaleID) {
      const scaleID = Number(match.groups.scaleID)

      replacement = `${previousQuestion.response[scaleID]}`
    } else if (previousQuestion.type === 'ranking' && match.groups?.rank) {
      // The "rank" in the title is 1-based, but the response is 0-based.
      const rankIndex = Number(match.groups.rank) - 1

      replacement =
        previousQuestion.options.find(({ id }) => {
          return previousQuestion.response[rankIndex] === id
        })?.title ?? ''
    } else if (
      previousQuestion.type === 'matrix' &&
      match.groups?.statementID
    ) {
      const statementID = Number(match.groups.statementID)
      const optionsForStatement =
        previousQuestion.response[statementID] ?? new Set()

      replacement = getFormattedSelectedTitles({
        response: optionsForStatement,
        responseFreeText: previousQuestion.responseFreeText[statementID],
        selectedResources: previousQuestion.options,
      })
    }

    if (replacement) {
      newTitle = newTitle.replace(match[0], replacement)
    }
  }

  return {
    ...nextQuestion,
    title: newTitle,
  }
}

export function pipeConcept({
  nextQuestion,
  questions,
}: {
  nextQuestion: Question
  questions: Record<string, Question>
}) {
  if (
    !('pipeConcept' in nextQuestion.features) ||
    !nextQuestion.features.pipeConcept
  ) {
    return nextQuestion
  }

  const previousQuestion = getPreviousQuestionForIteration({
    iteration: getIterationFromID(nextQuestion.id),
    previousQuestionID: nextQuestion.features.pipeConcept.questionID,
    questions,
  })
  if (!previousQuestion || previousQuestion.concepts.length === 0) {
    return nextQuestion
  }

  return {
    ...nextQuestion,
    concepts: [previousQuestion.concepts[0]],
  }
}

export function titleHasPipes(title: string) {
  return Array.from(getPipingMatches(title)).length > 0
}
