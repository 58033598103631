import { Cloudinary } from '@cloudinary/url-gen'

import { getEnvVar } from 'utils/env'

export type Image = { alt: string; publicID: string }
export type Video = { publicID: string }

const cld = new Cloudinary({
  cloud: {
    cloudName: getEnvVar('CLOUDINARY_CLOUD_NAME'),
  },
})

export function getImageFromPublicID({ publicID }: { publicID: string }) {
  return cld.image(publicID).format('auto').quality('auto')
}

export function getVideoFromPublicID({ publicID }: { publicID: string }) {
  // Note: There's no "format" here because Cloudinary's AdvancedVideo component takes
  // care of figuring out the modern format.
  return cld.video(publicID).quality('auto')
}
