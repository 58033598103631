import { Language } from 'types/glass-api/domainModels'

export type Translations = {
  ACCESSIBILITY: {
    CLOSE_DIALOG: string
    CLOSE_OVERLAY: string
    FREE_TEXT_PLACEHOLDER: string
    MATRIX_FREE_TEXT_LABEL: (opts: {
      option: string
      statement: string
    }) => string
    MC_FREE_TEXT_LABEL: (option: string) => string
    RESPONSE_FOR_LINE: (line: number) => string
    ZOOM_OPTION: (option: string) => string
  }
  ERRORS: {
    FREE_TEXT_MAX: (max: number) => string
    FREE_TEXT_MIN: (min: number) => string
    MATRIX_FREE_TEXT_MISSING: (option: string) => string
    MATRIX_FREE_TEXT_MISSING_HEADER: string
    MC_FREE_TEXT_MISSING: (option: string) => string
  }
  UI_ELEMENTS: {
    BUTTON_DONE: string
    BUTTON_NEXT: string
    GABOR_GRANGER_BUTTON_NO: string
    GABOR_GRANGER_BUTTON_YES: string
    GABOR_GRANGER_HOW_ABOUT: string
    GABOR_GRANGER_THANKS: string
    IMAGE_OVERLAY_CLICK: string
    IMAGE_OVERLAY_CLOSE: string
    IMAGE_OVERLAY_SCROLL: string
    OPEN_ENDED_VIDEO_STOP_RECORDING: string
    REQUIRED_SUM_TOTAL: string
    SAVING_RESPONSES: string
  }
}

export const TRANSLATIONS = {
  DE: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Dialog schließen',
      CLOSE_OVERLAY: 'Overlay schließen',
      FREE_TEXT_PLACEHOLDER: 'Beginnen Sie hier mit der Eingabe ...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Antwort für ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Antwort für ${option}`,
      RESPONSE_FOR_LINE: (line) => `Antwort für Zeile ${line}`,
      ZOOM_OPTION: (option) => `Zoom ${option}`,
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Muss höchstens sein ${max}.`,
      FREE_TEXT_MIN: (min) => `Muss mindestens sein ${min}.`,
      MATRIX_FREE_TEXT_MISSING: (statement) =>
        `Bitte geben Sie für ${statement} eine Antwort an.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Bitte bearbeiten Sie Ihre Antworten zu den folgenden Aussagen:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Bitte geben Sie für ${option} eine Antwort an.`,
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Erledigt',
      BUTTON_NEXT: 'Nächste',
      GABOR_GRANGER_BUTTON_NO: 'NEIN',
      GABOR_GRANGER_BUTTON_YES: 'Ja',
      GABOR_GRANGER_HOW_ABOUT: 'Wie wäre es mit...',
      GABOR_GRANGER_THANKS: 'Vielen Dank für Ihre Antwort!',
      IMAGE_OVERLAY_CLICK: 'Klicken/tippen Sie zum Anzeigen',
      IMAGE_OVERLAY_CLOSE: 'Tippen Sie zum Schließen außerhalb des Bildes.',
      IMAGE_OVERLAY_SCROLL:
        'Zum Vergrößern scrollen/pinzetten oder doppelklicken Sie auf das Bild.',
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        'Ich habe technische Probleme oder möchte nicht mehr aufnehmen',
      REQUIRED_SUM_TOTAL: 'Summe:',
      SAVING_RESPONSES: 'Speichern...',
    },
  },
  EN: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Close Dialog',
      CLOSE_OVERLAY: 'Close Overlay',
      FREE_TEXT_PLACEHOLDER: 'Begin typing here...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Response for ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Response for ${option}`,
      RESPONSE_FOR_LINE: (line) => `Response for line ${line}`,
      ZOOM_OPTION: (option) => `Zoom ${option}`,
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Must be at most ${max}.`,
      FREE_TEXT_MIN: (min) => `Must be at least ${min}.`,
      MATRIX_FREE_TEXT_MISSING: (statement) =>
        `Please provide a response for ${statement}.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Please fix your responses for the following statements:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Please provide a response for ${option}.`,
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Done',
      BUTTON_NEXT: 'Next',
      GABOR_GRANGER_BUTTON_NO: 'No',
      GABOR_GRANGER_BUTTON_YES: 'Yes',
      GABOR_GRANGER_HOW_ABOUT: 'How about...',
      GABOR_GRANGER_THANKS: 'Thank you for your answer!',
      IMAGE_OVERLAY_CLICK: 'Click/tap to view',
      IMAGE_OVERLAY_CLOSE: 'Tap outside image to close.',
      IMAGE_OVERLAY_SCROLL: 'Scroll/pinch or double-click image to zoom.',
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        "I'm having technical difficulties or would no longer like to record",
      REQUIRED_SUM_TOTAL: 'Total:',
      SAVING_RESPONSES: 'Saving...',
    },
  },
  ES: {
    ACCESSIBILITY: {
      CLOSE_DIALOG: 'Cerrar diálogo',
      CLOSE_OVERLAY: 'Cerrar superposición',
      FREE_TEXT_PLACEHOLDER: 'Comience a escribir aquí...',
      MATRIX_FREE_TEXT_LABEL: (opts) =>
        `Respuesta para ${opts.statement} - ${opts.option}`,
      MC_FREE_TEXT_LABEL: (option) => `Respuesta para ${option}`,
      RESPONSE_FOR_LINE: (line) => `Respuesta para la línea ${line}`,
      ZOOM_OPTION: (option) => `Aumentar ${option}`,
    },
    ERRORS: {
      FREE_TEXT_MAX: (max) => `Debe ser como máximo ${max}.`,
      FREE_TEXT_MIN: (min) => `Debe ser al menos ${min}.`,
      MATRIX_FREE_TEXT_MISSING: (option) =>
        `Por favor, proporcione una respuesta para ${option}.`,
      MATRIX_FREE_TEXT_MISSING_HEADER:
        'Por favor, corrija sus respuestas para las siguientes declaraciones:',
      MC_FREE_TEXT_MISSING: (option) =>
        `Por favor, proporcione una respuesta para ${option}.`,
    },
    UI_ELEMENTS: {
      BUTTON_DONE: 'Finalizar',
      BUTTON_NEXT: 'Próximo',
      GABOR_GRANGER_BUTTON_NO: 'No',
      GABOR_GRANGER_BUTTON_YES: 'Sí',
      GABOR_GRANGER_HOW_ABOUT: '¿Qué tal...?',
      GABOR_GRANGER_THANKS: '¡Gracias por tu respuesta!',
      IMAGE_OVERLAY_CLICK: 'Haga clic/toque para ver',
      IMAGE_OVERLAY_CLOSE: 'Toque la imagen exterior para cerrarla.',
      IMAGE_OVERLAY_SCROLL:
        'Desplácese, pellizque o haga doble clic en la imagen para ampliar.',
      OPEN_ENDED_VIDEO_STOP_RECORDING:
        'Tengo dificultades técnicas o ya no quiero grabar',
      REQUIRED_SUM_TOTAL: 'Total:',
      SAVING_RESPONSES: 'Ahorro...',
    },
  },
} as const satisfies Record<Language, Translations>
