import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react'
import { clsx } from 'clsx'
import { ComponentProps, ElementType, ReactNode } from 'react'
import { Menu } from '@headlessui/react'

const Dropdown = ({
  button,
  children,
  onOpenChanged,
  placement = 'bottom-end',
}: {
  button: ReactNode
  children: ReactNode
  onOpenChanged?(isOpen: boolean): void
  placement?: Placement
}) => {
  const { refs, strategy, x, y } = useFloating({
    middleware: [offset({ mainAxis: 8 }), flip(), shift({ padding: 8 })],
    placement,
    whileElementsMounted: autoUpdate,
  })

  return (
    <Menu as="div" className="relative flex">
      {({ open }) => {
        onOpenChanged?.(open)

        return (
          <>
            <div ref={refs.setReference}>{button}</div>

            <FloatingPortal>
              <Menu.Items
                ref={refs.setFloating}
                className="z-30 min-w-[240px] rounded-lg border border-gray-200 bg-white px-1.5 py-1 shadow-lg"
                style={{
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
              >
                {children}
              </Menu.Items>
            </FloatingPortal>
          </>
        )
      }}
    </Menu>
  )
}

export default Dropdown

export const DropdownButton = (
  props: Omit<ComponentProps<typeof Menu.Button>, 'className'>,
) => {
  return <Menu.Button {...props} className="flex" />
}

export function DropdownItem<TTag extends ElementType>({
  as = 'button',
  ...rest
}: Omit<ComponentProps<typeof Menu.Item<TTag>>, 'className'>) {
  return (
    <Menu.Item
      {...rest}
      as={as}
      className={clsx(
        'block w-full select-none whitespace-nowrap rounded-md px-2.5 py-3 text-sm text-gray-700 ui-active:bg-gray-50',
        {
          'text-left': as === 'button',
        },
      )}
    />
  )
}
