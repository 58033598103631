import { Question } from 'utils/questions'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const OpenEndedConstraints = ({ question }: { question: Question }) => {
  if (question.type !== 'openEnded') {
    return null
  }

  const { max, min, textRegExp } = question.constraints
  const constraints = [`Type: ${question.responseConfig.type}`]

  if (max || min) {
    constraints.push(`Min-Max: [${min?.value ?? ''}, ${max?.value ?? ''}]`)
  }

  if (textRegExp) {
    constraints.push(`Requirement: ${textRegExp.message}`)
  }

  return (
    <FeatureDisplay title="Constraints">
      <ListDiscIndented>
        {constraints.map((constraint, i) => (
          <li key={i}>{constraint}</li>
        ))}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default OpenEndedConstraints
