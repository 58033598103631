import ExceptionPage from 'components/ExceptionPage'

const UnexpectedErrorPage = () => {
  return (
    <ExceptionPage title="Something Went Wrong">
      We apologize for the inconvenience. There was an issue loading your
      survey. Rest assured, we're looking into it.
    </ExceptionPage>
  )
}

export default UnexpectedErrorPage
