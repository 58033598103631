import { Decimal } from 'decimal.js-light'

export function formatDollars(
  value: number,
  { decimals }: { decimals?: number } = {},
) {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    style: 'currency',
  }).format(value)
}

export function formatPercentage(
  value: Decimal,
  { decimals }: { decimals?: number } = {},
) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
    style: 'percent',
  }).format(value.dividedBy(100).toNumber())
}
