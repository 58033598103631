import { useCallback } from 'react'

import { Question } from 'utils/questions'

import ConceptMedia from 'components/ConceptMedia'

/**
 * A custom hook that returns a JSX element for the concept media for the question.
 * We need the onViewed prop to the ConceptMedia component to be stable (see the comment
 * for the ConceptVideo component for more details), and this hook takes care of that.
 */
export function useConcept({
  onViewedConcept,
  question,
}: {
  onViewedConcept(questionID: string, conceptID: string): void
  question: Question
}) {
  const concept = question.concepts[0]
  const conceptID = concept?.id
  const questionID = question.id

  const onViewed = useCallback(() => {
    onViewedConcept(questionID, conceptID)
  }, [conceptID, onViewedConcept, questionID])

  return {
    concept: concept ? (
      <ConceptMedia
        concept={concept}
        onViewed={onViewed}
        viewRequired={question.constraints.requireConceptView}
      />
    ) : undefined,
  }
}
