import { Question } from 'utils/questions'
import { titlesFromSet } from 'utils/general'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented, { NestedListItem } from './ListDiscIndented'

const Randomize = ({ question }: { question: Question }) => {
  const resources: string[] = []
  let anchored: string[] = []

  if (
    'randomizeOptions' in question.features &&
    'options' in question &&
    question.features.randomizeOptions
  ) {
    resources.push('Options')
    anchored = [
      ...anchored,
      ...titlesFromSet({
        ids: question.features.randomizeOptions.anchoredResourceIDs,
        resources: question.options,
      }),
    ]
  }

  if (
    'randomizeStatements' in question.features &&
    'statements' in question &&
    question.features.randomizeStatements
  ) {
    resources.push('Statements')
    anchored = [
      ...anchored,
      ...titlesFromSet({
        ids: question.features.randomizeStatements.anchoredResourceIDs,
        resources: question.statements,
      }),
    ]
  }

  if (
    'randomizeScales' in question.features &&
    'scales' in question &&
    question.features.randomizeScales
  ) {
    resources.push('Scales')
    anchored = [
      ...anchored,
      ...titlesFromSet({
        ids: question.features.randomizeScales.anchoredResourceIDs,
        resources: question.scales,
      }),
    ]
  }

  if (resources.length === 0) {
    return null
  }

  return (
    <FeatureDisplay title="Ordering">
      <ListDiscIndented>
        <li>
          {resources.length > 1
            ? `Randomize: ${resources.join(', ')}`
            : `Randomize ${resources[0]}`}
        </li>
        {anchored.length > 0 && (
          <li>
            <NestedListItem title="Anchored">
              {anchored.map((resource) => (
                <li key={resource}>{resource}</li>
              ))}
            </NestedListItem>
          </li>
        )}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default Randomize
