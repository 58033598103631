import axios from 'axios'

import { removeQueryParamsEmptyKeys } from 'utils/general'

axios.interceptors.request.use((config) => {
  // If we have request parameters, we want to strip out any empty ones.
  if (config.params) {
    config.params = removeQueryParamsEmptyKeys(config.params)
  }

  // "randomUUID" is not supported in all older browsers.
  // See https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID#browser_compatibility
  config.headers['Request-Id'] = window.crypto.randomUUID?.()

  return config
})
