import { AdvancedImage } from '@cloudinary/react'

import { getImageFromPublicID } from 'utils/media'

import ImageZoomControls from './ImageZoomControls'

const ConceptImage = ({
  alt,
  onClickZoom,
  publicID,
  zoomRequired = false,
}: {
  alt: string
  onClickZoom?(): void
  publicID: string
  zoomRequired?: boolean
}) => {
  const image = getImageFromPublicID({ publicID })

  return (
    <div className="flex justify-center">
      <div className="relative">
        <AdvancedImage alt={alt} className="max-h-60" cldImg={image} />

        <ImageZoomControls
          alt={alt}
          image={image}
          onClickZoom={onClickZoom}
          zoomRequired={zoomRequired}
        />
      </div>
    </div>
  )
}

export default ConceptImage
