import { getResourcesToChooseFrom } from 'utils/displayXOfY'
import { Question } from 'utils/questions'
import { titlesFromSet } from 'utils/general'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented, { NestedListItem } from './ListDiscIndented'

const DisplayXOfY = ({ question }: { question: Question }) => {
  if (!('displayXOfY' in question.features) || !question.features.displayXOfY) {
    return null
  }

  const { displayXOfY } = question.features
  const { collection } = getResourcesToChooseFrom({ question })
  const preservedResourceTitles = titlesFromSet({
    ids: displayXOfY.preservedResourceIDs,
    resources: collection,
  })

  return (
    <FeatureDisplay title="Display X of Y">
      <ListDiscIndented>
        <li>Display {displayXOfY.numToDisplay}</li>
        {preservedResourceTitles.length > 0 && (
          <li>
            <NestedListItem title="Preserved">
              {preservedResourceTitles.map((title) => (
                <li key={title}>{title}</li>
              ))}
            </NestedListItem>
          </li>
        )}
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default DisplayXOfY
