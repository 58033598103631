import { compact, groupBy, map } from 'lodash-es'

import { BlockSetMonadic } from './questionBlocks'
import {
  buildExpectedResponseFromSegmentQuestionConstraints,
  Condition,
  didSatisfyConditionGroups,
} from './conditions'
import { DisqualificationReason } from './disqualifications'
import { filterSurveyByDisplayLogic } from './displayLogic'
import { Question } from './questions'
import {
  Question as QuestionAPI,
  Survey as SurveyAPI,
} from 'types/glass-api/domainModels'
import { Survey, VariableQuota } from './surveys'

export type OptionQuotasConstraint = {
  disqualificationTactic: 'CUSTOM_QUALITY_SCREEN' | 'QUESTION_QUOTA'
  cantPickOptionIDs?: Set<string>
  mustPickOptionIDs?: Set<string>
}[]

/**
 * Converts the API question quotas representation into objects of which options
 * must or can't be selected.
 * See hasSatisfiedQuestionQuotas.
 */
export function buildOptionQuotas({
  question,
  survey,
}: {
  question: QuestionAPI
  survey: Survey
}) {
  return compact(
    question.questionQuotas.map((quota) => {
      const disqualificationTactic: OptionQuotasConstraint[number]['disqualificationTactic'] =
        quota.disqualificationType === 'QUALITY'
          ? 'CUSTOM_QUALITY_SCREEN'
          : 'QUESTION_QUOTA'

      // Create arrays with the options in the quota and the remaining question
      // options that aren't in the quota.
      const quotaOptionIDs =
        quota.questionOptions?.map(({ id }) => `${id}`) ?? []
      const nonQuotaOptionIDs: string[] = []
      question.options.forEach((option) => {
        if (!quotaOptionIDs.includes(`${option.id}`)) {
          nonQuotaOptionIDs.push(`${option.id}`)
        }
      })

      if (quota.logicalModifier === 'all') {
        return {
          disqualificationTactic,
          mustPickOptionIDs: new Set(quotaOptionIDs),
        }
      } else if (quota.logicalModifier === 'at_least') {
        const remainingCompletes =
          survey.respondents.numNeeded - survey.respondents.numCompleted
        const remainingCompletesNeeded =
          quota.numberNeeded - quota.numberCompleted

        if (remainingCompletesNeeded >= remainingCompletes) {
          return {
            disqualificationTactic,
            mustPickOptionIDs: new Set(quotaOptionIDs),
          }
        }
      } else if (
        quota.logicalModifier === 'none' ||
        (quota.logicalModifier === 'at_most' &&
          quota.numberCompleted >= quota.numberNeeded)
      ) {
        return {
          disqualificationTactic,
          cantPickOptionIDs: new Set(quotaOptionIDs),
        }
      }
    }),
  )
}

export function buildVariableQuotas({ survey }: { survey: SurveyAPI }) {
  // A variable is made up of a group of segments, which are conditions set on
  // questions in the survey. A user can then choose to apply quotas to those
  // segments. We flatten this structure into a list of quotas, each with an
  // associated list of segments.
  const variableQuotas = compact(
    survey.surveyVariables.flatMap(({ quotas, segments, title }) => {
      return quotas.map((quota) => {
        // An "at_most" quota that isn't yet completed is not a quota we
        // need to enforce yet.
        if (
          quota.type === 'at_most' &&
          quota.numberCompleted < quota.numberNeeded
        ) {
          return
        }

        const segmentsForQuota = segments.filter(
          ({ quotaId }) => quotaId === quota.id,
        )
        if (segmentsForQuota.length === 0) {
          return
        }

        return { quota, segments: segmentsForQuota, title }
      })
    }),
  )

  return variableQuotas.map(({ quota, segments, title }) => {
    return {
      segments: segments.map((segment) => {
        const andGroupings = groupBy(segment.questions, 'andGrouping')

        return {
          conditionGroups: {
            groupLogic: 'or',
            groups: map(andGroupings, (grouping) => {
              return grouping.map(
                ({ constraints, logicalModifier, questionId }) => {
                  return {
                    expectedResponse:
                      buildExpectedResponseFromSegmentQuestionConstraints(
                        constraints,
                      ),
                    item: {
                      conceptId: null,
                      id: `${questionId}`,
                      type: 'question',
                    },
                    logic: logicalModifier,
                  } satisfies Condition
                },
              )
            }),
          },
          quotaType: quota.type,
          title: segment.title,
        }
      }),
      title,
    }
  }) satisfies VariableQuota[]
}

export function getQuotaDisqualification({
  curQuestion,
  monadicBlocks,
  questions,
  survey,
}: {
  curQuestion: Question
  monadicBlocks: Record<string, BlockSetMonadic>
  questions: Record<string, Question>
  survey: Survey
}) {
  // The demographic quotas are represented as an audience, which is essentially display logic,
  // on the survey itself. I like to think of it like: If the conditions don't pass, the user
  // shouldn't be shown the survey anymore and should be disqualified.
  const surveyPassesDemographicQuotas = filterSurveyByDisplayLogic({
    monadicBlocks,
    questions,
    survey,
  })
  if (!surveyPassesDemographicQuotas) {
    return {
      source: 'GLASS',
      tactic: 'AUDIENCE_QUOTA',
    } satisfies DisqualificationReason
  }

  const satisfiesAllVariableQuotas = survey.quotas.variables.every(
    ({ segments }) => {
      return segments.some(({ conditionGroups, quotaType }) => {
        const fitsIntoSegment = didSatisfyConditionGroups({
          conditionGroups,
          monadicBlocks,
          questions,
          // If a respondent hasn't yet seen a question, we only want to put them into the segment
          // for "all" quota types. Otherwise, if a respondent is placed into a segment for a "none"
          // quota, they'll be disqualified before they have a chance to answer.
          unknownSatisfies: quotaType === 'all',
        })

        if (quotaType === 'at_most' || quotaType === 'none') {
          return !fitsIntoSegment
        }

        return fitsIntoSegment
      })
    },
  )
  if (!satisfiesAllVariableQuotas) {
    return {
      source: 'GLASS',
      tactic: 'VARIABLE_QUOTA',
    } satisfies DisqualificationReason
  }

  const unsatisfiedQuotaTactic = getUnsatisfiedQuestionQuotaTactic({
    curQuestion,
  })
  if (unsatisfiedQuotaTactic) {
    return {
      source: 'GLASS',
      tactic: unsatisfiedQuotaTactic,
    } satisfies DisqualificationReason
  }
}

/**
 * Returns a question quota that has not been satisfied based on the respondent's selections.
 * If multiple quotas are unsatisfied and one is a CUSTOM_QUALITY_SCREEN disqualification,
 * that one is returned.
 */
export function getUnsatisfiedQuestionQuotaTactic({
  curQuestion,
}: {
  curQuestion: Question
}) {
  if (
    (curQuestion.type !== 'multipleChoice' && curQuestion.type !== 'ranking') ||
    !curQuestion.constraints.optionQuotas
  ) {
    return null
  }

  const quotas = curQuestion.constraints.optionQuotas

  let unsatisfiedQuotaTactic:
    | OptionQuotasConstraint[number]['disqualificationTactic']
    | null = null

  for (const quota of quotas) {
    if (quota.mustPickOptionIDs?.size) {
      const selectedNeededOption = Array.from(quota.mustPickOptionIDs).some(
        (optionID) => {
          return new Set(curQuestion.response).has(`${optionID}`)
        },
      )

      if (!selectedNeededOption) {
        if (quota.disqualificationTactic === 'CUSTOM_QUALITY_SCREEN') {
          return 'CUSTOM_QUALITY_SCREEN'
        }

        unsatisfiedQuotaTactic = quota.disqualificationTactic
      }
    }

    if (quota.cantPickOptionIDs?.size) {
      const selectedDisallowedOption = Array.from(quota.cantPickOptionIDs).some(
        (optionID) => {
          return new Set(curQuestion.response).has(`${optionID}`)
        },
      )

      if (selectedDisallowedOption) {
        if (quota.disqualificationTactic === 'CUSTOM_QUALITY_SCREEN') {
          return 'CUSTOM_QUALITY_SCREEN'
        }

        unsatisfiedQuotaTactic = quota.disqualificationTactic
      }
    }
  }
  return unsatisfiedQuotaTactic
}
