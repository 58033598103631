import { Survey } from 'utils/surveys'

import FeatureDisplay from './FeatureDisplay'
import ListDiscIndented from './ListDiscIndented'

const SurveyInfo = ({ survey }: { survey: Survey }) => {
  return (
    <FeatureDisplay title="Information">
      <ListDiscIndented>
        <li>Title: {survey.title}</li>
        <li>ID: {survey.id}</li>
        <li>
          Respondents: {survey.respondents.numCompleted} /{' '}
          {survey.respondents.numNeeded}
        </li>
      </ListDiscIndented>
    </FeatureDisplay>
  )
}

export default SurveyInfo
