import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  Outlet,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from '@sentry/react'
import { StrictMode, useEffect } from 'react'

import { getEnvVar } from 'utils/env'
import { isAxiosResponseError } from './utils/api'

import './main.css'
import 'apiRequests'
import App from './App'
import NotFoundPage from 'routes/NotFoundPage'
import UnexpectedErrorPage from 'routes/UnexpectedErrorPage'
import SurveyPage from 'routes/SurveyPage'

Sentry.init({
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  dsn: getEnvVar('SENTRY_DSN'),
  environment: getEnvVar('APP_ENV'),
  integrations: [
    Sentry.browserTracingIntegration(),
    // See the Sentry documentation on integrating with react-router:
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router
    Sentry.reactRouterV6BrowserTracingIntegration({
      createRoutesFromChildren,
      matchRoutes,
      useEffect,
      useLocation,
      useNavigationType,
    }),
    Sentry.replayIntegration({ maskAllText: false }),
  ],

  // Capture Replay for 30% of sessions in production. This is an arbitrary amount that we'll
  // adjust over time as we have more of a use-case for watching replays.
  // See the Sentry docs: https://docs.sentry.io/platforms/javascript/guides/react
  replaysSessionSampleRate: getEnvVar('APP_ENV') === 'production' ? 0.3 : 0,
  replaysOnErrorSampleRate: 1.0,

  tracePropagationTargets: [getEnvVar('GLASS_API_HOST')],
  // This is somewhat arbitrary and can be adjusted when we get data to get more or less.
  tracesSampleRate: 0.3,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const queryError = error instanceof Error ? error : null
        const statusCode = isAxiosResponseError(queryError)
          ? queryError.response?.status
          : undefined

        // We don't want to retry an API request on 4xx responses since they'll just keep failing.
        return (
          failureCount < 3 &&
          (!statusCode || statusCode < 400 || statusCode >= 500)
        )
      },
    },
  },
})

// See the Sentry documentation on integrating with react-router:
// https://docs.sentry.io/platforms/javascript/guides/react/features/react-router
const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <App />,
    // This would be called in the case of an error in the App component.
    // We wrap the children of App in a Sentry error bounday to get error
    // reporting there. Unfortunately, it doesn't seem like this error element
    // and Sentry's error boundary play well together.
    errorElement: <UnexpectedErrorPage />,
    children: [
      {
        path: 'surveys',
        element: <Outlet />,
        children: [
          {
            path: ':surveyHash',
            element: <SurveyPage />,
          },
          {
            index: true,
            element: <NotFoundPage />,
          },
        ],
      },
      {
        index: true,
        element: <NotFoundPage />,
      },
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
])

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    {/*
     * This boundary catches any errors in third-parties since we have an error boundary
     * in the router that catches application errors.
     */}
    <Sentry.ErrorBoundary fallback={<UnexpectedErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />

        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
)
