import { forwardRef, InputHTMLAttributes } from 'react'

const InputRadio = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'type'>
>(function InputRadio(props, ref) {
  return (
    <input
      {...props}
      ref={ref}
      // Custom styling of radio button inspired by
      // https://moderncss.dev/pure-css-custom-styled-radio-buttons
      className="m-0 grid h-6 w-6 appearance-none place-content-center rounded-full border border-gray-4 bg-white before:h-3 before:w-3 before:scale-0 before:rounded-full before:bg-primary-600 before:content-[''] checked:border-primary-600 checked:bg-primary-50 before:checked:scale-100 disabled:cursor-not-allowed disabled:bg-gray-100 checked:disabled:border-gray-4 checked:disabled:bg-white before:checked:disabled:bg-gray-100"
      type="radio"
    />
  )
})

export default InputRadio
