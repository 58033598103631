import { createContext, ReactNode, useContext } from 'react'
import { z } from 'zod'

import { Language } from 'types/glass-api/domainModels'
import { Translations, TRANSLATIONS } from 'utils/translations'

interface I18N {
  translations: Translations
}

const I18NContext = createContext<I18N | undefined>(undefined)

const I18NProvider = ({
  children,
  language,
}: {
  children: ReactNode
  language: Language
}) => {
  return (
    <I18NContext.Provider
      value={{
        translations: TRANSLATIONS[language],
      }}
    >
      {children}
    </I18NContext.Provider>
  )
}

function useTranslations() {
  const context = useContext(I18NContext)
  if (context === undefined) {
    throw new Error('useI18N must be used in an I18NContext')
  }

  return context.translations
}

export type MatrixFreeTextStatementError = {
  option: string
  type: 'matrixFreeTextStatementError'
}

function useZodErrorMap() {
  const context = useContext(I18NContext)
  if (context === undefined) {
    throw new Error('useI18N must be used in an I18NContext')
  }

  const zodErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (
      issue.code === z.ZodIssueCode.custom &&
      isMatrixFreeTextStatementError(issue.params)
    ) {
      return {
        message: context.translations.ERRORS.MATRIX_FREE_TEXT_MISSING(
          issue.params.option,
        ),
      }
    }

    return { message: ctx.defaultError }
  }

  return { zodErrorMap }
}

export { I18NProvider, useTranslations, useZodErrorMap }

function isMatrixFreeTextStatementError(
  params?: Record<string, string>,
): params is MatrixFreeTextStatementError {
  return params?.type === 'matrixFreeTextStatementError'
}
