import { getEnvVar } from 'utils/env'

export type APIVersion = 'v3'

export function getAPIUrl({
  path,
  version,
}: {
  path: string
  version: APIVersion
}) {
  return `${getEnvVar('RESEARCH_DEFENDER_HOST')}/${version}${path}`
}
