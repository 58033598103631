import { forwardRef, InputHTMLAttributes } from 'react'

import { useTranslations } from 'contexts/i18n'

const Input = forwardRef<
  HTMLInputElement,
  Omit<InputHTMLAttributes<HTMLInputElement>, 'className' | 'placeholder'>
>(function Input(props, ref) {
  const translations = useTranslations()

  return (
    <input
      {...props}
      ref={ref}
      className="text-gray-2 w-full rounded border border-gray-4 p-3 placeholder:text-gray-3"
      placeholder={translations.ACCESSIBILITY.FREE_TEXT_PLACEHOLDER}
    />
  )
})

export default Input
