import { BlockSetMonadic } from 'utils/questionBlocks'
import { ConditionGroups } from 'utils/conditions'
import { Question } from 'utils/questions'

import ConditionGroupsTable from './ConditionGroupsTable'
import Dialog from 'components/Dialog'

const DisplayLogicDialog = ({
  conditionGroups,
  isOpen,
  monadicBlocks,
  onClose,
  question,
  questionsCurrent,
  questionsInitial,
  title,
}: {
  conditionGroups: ConditionGroups
  isOpen: boolean
  monadicBlocks: Record<string, BlockSetMonadic>
  onClose(): void
  question?: Question
  questionsCurrent: Record<string, Question>
  questionsInitial: Record<string, Question>
  title: string
}) => {
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={title}>
      <ConditionGroupsTable
        conditionGroups={conditionGroups}
        monadicBlocks={monadicBlocks}
        question={question}
        questionsCurrent={questionsCurrent}
        questionsInitial={questionsInitial}
      />
    </Dialog>
  )
}

export default DisplayLogicDialog
